import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { MdEdit } from 'react-icons/md';
import { Formik, Form } from 'formik';
import { notify } from 'react-notify-toast';
import { VALIDATION_SCHEMA } from '@/utils';
import { Button, InputText, Container } from '@/components/atoms';
import { ACTION_PROFILE } from '@/redux/actions';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import ReactGA from 'react-ga4';
// import { ProfileContainer } from '../components/ProfileContainer';
// survey
import 'survey-core/defaultV2.css';
import { StylesManager, Model } from 'survey-core';
import { Survey } from 'survey-react-ui';

const ProfileSetting = ({ documentTitle }) => {
  const dispatch = useDispatch();

  const profileSubmitting = useSelector((state) => state.profile.profileSubmitting);
  const profileLoading = useSelector((state) => state.profile.profileLoading);
  const profileData = useSelector((state) => state.profile.profile);
  const SurveyData = useSelector((state) => state.profile.profileSurvey);

  const [initialValues, setInitialValues] = useState({
    name: '',
    email: '',
    phone: '',
    old_password: '',
    new_password: '',
    new_password_confirmation: '',
  });
  const [isWa, setIsWa] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [survey, setSurvey] = useState(null);
  const [profileAnswers, setProfileAnswers] = useState({});

  const handleSaveProfile = (values) => {
    dispatch(
      ACTION_PROFILE.updateProfile(values, isWa, profileData.has_password, (message) => {
        notify.show(message, 'success');
        dispatch(ACTION_PROFILE.getProfile());
      })
    );
  };

  const handleSaveProfileSurvey = (surveyId, surveyAnswers) => {
    dispatch(
      ACTION_PROFILE.updateProfileSurvey(surveyId, surveyAnswers, (message) => {
        notify.show(message, 'success');
        dispatch(ACTION_PROFILE.getProfile());
      })
    );
  };

  const onCompleted = (sender) => {
    const results = JSON.stringify(sender.data);
    setProfileAnswers(sender.data);
    // sender.clear(false, true);
    // sender.render();
    handleSaveProfileSurvey(SurveyData.id, results);
  };

  useEffect(() => {
    document.title = documentTitle;
    dispatch(ACTION_PROFILE.getProfile({ survey: 'active' }));
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send('pageview');
  }, []);

  useEffect(() => {
    if (profileData) {
      setInitialValues({
        name: profileData.name,
        email: profileData.email,
        phone: profileData.phone,
      });
      if (profileData.phone) {
        setIsWa(profileData.phone.toString().slice(0, 2) === '62');
      }
      if (profileData.survey_id) {
        dispatch(ACTION_PROFILE.getProfileSurvey(profileData.survey_id));
        setProfileAnswers(JSON.parse(profileData.survey_answers));
      } else {
        dispatch(ACTION_PROFILE.getProfileSurvey(0));
      }
    }
  }, [profileData]);

  useEffect(() => {
    // survey
    const surveyModel = new Model(SurveyData ? SurveyData.survey_json : null);
    if (surveyModel) {
      surveyModel.locale = 'id';
      surveyModel.data = profileAnswers;
      surveyModel.onComplete.add(onCompleted);
      StylesManager.applyTheme('defaultV2');
      setSurvey(surveyModel);
    }
  }, [SurveyData, profileAnswers]);

  return (
    // <ProfileContainer>
    <div className="bg-gray-100">
      <Container className="py-8 space-y-8 lg:space-y-0 lg:space-x-8">
        <div className="grid lg:grid-cols-2 gap-8 mx-auto">
          <div className="bg-white rounded-md h-fit">
            <div className="flex w-full justify-between items-center p-5 border-b">
              <div className="font-bold text-gray-700">Profil Saya</div>
              {/* <button className="text-secondary" type="button">
                <MdEdit size={20} />
              </button> */}
            </div>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={VALIDATION_SCHEMA.profileSettingSchema}
              onSubmit={handleSaveProfile}
            >
              {({ values, handleChange, handleSubmit, setFieldValue, getFieldMeta }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="p-5 space-y-6">
                    <InputText
                      label="Nama Lengkap"
                      required
                      name="name"
                      placeholder="Masukkan Nama Lengkap"
                      onChange={(event) => handleChange(event)}
                      value={values.name || ''}
                      meta={getFieldMeta('name')}
                      disabled={profileSubmitting}
                      isLoading={profileLoading}
                    />

                    <InputText
                      label="Email"
                      required
                      name="email"
                      disabled={!values.phone || (values.phone && SurveyData && values.phone.slice(0, 2) !== '62')}
                      placeholder="Masukkan Email"
                      onChange={(event) => handleChange(event)}
                      value={values.email || ''}
                      meta={getFieldMeta('email')}
                      isLoading={profileLoading}
                    />

                    <InputText
                      label="Nomor Handphone"
                      required
                      className="mb-3"
                      name="phone"
                      icon={
                        values.phone && values.phone.slice(0, 2) === '62' ? null : (
                          <span className="text-sm text-gray-400">+62</span>
                        )
                      }
                      placeholder="Masukkan Nomor Handphone"
                      onChange={(event) => {
                        const { value } = event.target;
                        const phone = value[0] === '0' ? value.substr(1, value.length) : value;

                        setFieldValue('phone', phone, true);
                      }}
                      value={values.phone || ''}
                      meta={getFieldMeta('phone')}
                      disabled={
                        profileSubmitting || (values.phone && values.phone.toString().slice(0, 2) === '62')
                      }
                      isLoading={profileLoading}
                    />

                    {profileData && profileData.has_password && (
                      <>
                        <InputText
                          label="Kata sandi lama"
                          required
                          iconSuffix={
                            <button
                              className="hover:text-gray-400"
                              type="button"
                              onClick={() => setIsShowPassword(!isShowPassword)}
                            >
                              {isShowPassword ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
                            </button>
                          }
                          type={isShowPassword ? 'text' : 'password'}
                          value={values.old_password}
                          onChange={(event) => handleChange(event)}
                          name="old_password"
                          meta={getFieldMeta('old_password')}
                          placeholder="Masukkan kata sandi lama"
                        />

                        <InputText
                          label="Kata sandi baru"
                          required
                          iconSuffix={
                            <button
                              className="hover:text-gray-400"
                              type="button"
                              onClick={() => setIsShowPassword(!isShowPassword)}
                            >
                              {isShowPassword ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
                            </button>
                          }
                          type={isShowPassword ? 'text' : 'password'}
                          value={values.new_password}
                          onChange={(event) => handleChange(event)}
                          name="new_password"
                          meta={getFieldMeta('new_password')}
                          placeholder="Masukkan kata sandi minimal 8 karakter"
                        />

                        <InputText
                          label="Konfirmasi kata sandi baru"
                          required
                          iconSuffix={
                            <button
                              className="hover:text-gray-400"
                              type="button"
                              onClick={() => setIsShowPassword(!isShowPassword)}
                            >
                              {isShowPassword ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
                            </button>
                          }
                          type={isShowPassword ? 'text' : 'password'}
                          value={values.new_password_confirmation}
                          onChange={(event) => handleChange(event)}
                          name="new_password_confirmation"
                          meta={getFieldMeta('new_password_confirmation')}
                          placeholder="Ulangi kata sandi baru"
                        />
                      </>
                    )}
                  </div>
                  <div className="flex justify-end p-5 space-x-3">
                    <Button
                      className="px-10"
                      size={Button.SIZES.SMALL}
                      variant={Button.VARIANTS.PRIMARY_OUTLINE}
                      disabled={profileLoading || profileSubmitting}
                    >
                      Batal
                    </Button>
                    <Button
                      className="px-10"
                      size={Button.SIZES.SMALL}
                      onClick={handleSubmit}
                      loading={profileLoading || profileSubmitting}
                    >
                      Simpan
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="bg-white rounded-md h-fit">
            <div className="flex w-full justify-between items-center p-5 border-b">
              <div className="font-bold text-gray-700">Data Tambahan</div>
            </div>
            {profileLoading && <Skeleton height={200} />}
            {!profileLoading && SurveyData && survey && <Survey model={survey} />}
          </div>
        </div>
      </Container>
    </div>
    // </ProfileContainer>
  );
};

export default ProfileSetting;
