import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MdSort } from 'react-icons/md';
import { BiLoaderAlt } from 'react-icons/bi';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import ReactGA from 'react-ga4';
import { Button } from '@/components/atoms';
import { ModalBookingCoaching } from '@/components/molecules';
import { ACTION_GAMIFICATION } from '@/redux/actions';
import { ProfileContainer } from '../components/ProfileContainer';
import { ProfilePagination } from '../components/ProfilePagination';

export const PoinSaya = ({ documentTitle }) => {
  const dispatch = useDispatch();

  const PER_PAGE_OPTIONS = [
    { label: '3', value: 3 },
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '15', value: 15 },
  ];

  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerPage] = useState(PER_PAGE_OPTIONS[1].value);
  const [isOpenModalBookingCoaching, setIsOpenModalBookingCoaching] = useState(false);

  const pointData = useSelector((state) => state.gamification.pointData);
  const pointHistoryData = useSelector((state) => state.gamification.pointHistoryData);
  const pointHistoryTotal = useSelector((state) => state.gamification.pointHistoryTotal);
  const isLoadingPointHistory = useSelector((state) => state.gamification.pointHistoryDataLoading);

  const handleChangePerPage = (event) => {
    const { value } = event.target;
    setPerPage(Number(value));
  };

  useEffect(() => {
    document.title = documentTitle;
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send('pageview');
    dispatch(ACTION_GAMIFICATION.getPoint());
  }, []);
  useEffect(() => dispatch(ACTION_GAMIFICATION.getPointHistory(activePage, perPage)), [activePage, perPage]);

  return (
    <div>
      <ModalBookingCoaching
        open={isOpenModalBookingCoaching}
        onClose={() => setIsOpenModalBookingCoaching(false)}
        onRequestCoachingSuccess={() => dispatch(ACTION_GAMIFICATION.getPoint())}
        point={pointData?.point}
      />
      <ProfileContainer>
        <div className="grid gap-8">
          <div className="flex flex-col md:flex-row bg-white rounded-md">
            <div className="flex w-full md:w-1/3 space-y-3 flex-col items-center text-center p-5 border-b md:border-b-0 md:border-r">
              <div className="font-bold text-gray-700">Total Poin Saya</div>
              <img src={require('@/images/icons/coin.png').default} alt="" />
              {pointData === null && <Skeleton width={120} height={35} />}
              {pointData !== null && <div className="font-semibold text-5xl text-primary">{pointData.point}</div>}
            </div>
            <div className="flex flex-col md:flex-row items-center w-full p-5 space-y-6 md:space-y-0 md:space-x-7">
              <div className="relative w-48 md:w-full lg:w-1/2 h-36 rounded-md">
                <img
                  className="h-full w-full object-contain"
                  src={require('@/images/icons/poin-saya-illustration.png').default}
                  alt=""
                />
                <div className="absolute flex items-center space-x-2 bg-white bottom-2 -right-2 p-2 rounded-full shadow-md">
                  <img className="w-6" src={require('@/images/icons/coin.png').default} alt="" />
                  <div className="font-semibold flex items-center">
                    {pointData === null && <Skeleton width={30} height={20} />}
                    {pointData !== null && pointData.point_for_coaching}
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-between items-center md:items-start space-y-5">
                {pointData === null && <Skeleton count={2} height={20} />}
                {pointData !== null && (
                  <div className="text-center md:text-left font-semibold">
                    {pointData.point >= pointData.point_for_coaching && (
                      <span>
                        Selamat! Anda mendapatkan kesempatan{' '}
                        <span className="text-primary font-bold">ONLINE COACHING BISNIS</span> bersama praktisi!
                      </span>
                    )}
                    {pointData.point < pointData.point_for_coaching && (
                      <span>
                        Kumpulkan{' '}
                        <span className="text-secondary">{pointData.point_for_coaching - pointData.point}</span>{' '}
                        poin lagi agar Anda bisa coaching langsung dengan praktisi!
                      </span>
                    )}
                  </div>
                )}
                <Button variant={Button.VARIANTS.SECONDARY} onClick={() => setIsOpenModalBookingCoaching(true)}>
                  Tukar Poin Sekarang
                </Button>
              </div>
            </div>
          </div>
          <div className="w-full bg-white rounded-md p-5 space-y-5 overflow-hidden">
            <div className="flex w-full justify-between items-center">
              <div className="font-bold text-gray-700">Riwayat Perolehan Poin</div>
              <button type="button">
                <MdSort size={20} />
              </button>
            </div>
            <div className="overflow-x-scroll max-w-full">
              <table className="w-full text-left">
                <thead className="border-t border-b">
                  <tr>
                    <th style={{ minWidth: 180 }} className="w-3/6 text-sm p-3">
                      Detail
                    </th>
                    <th style={{ minWidth: 120 }} className="w-2/6 text-sm p-3">
                      Tanggal
                    </th>
                    <th style={{ minWidth: 120 }} className="w-1/6 text-sm p-3">
                      Poin
                    </th>
                  </tr>
                </thead>
                {!isLoadingPointHistory && pointHistoryData && pointHistoryData.length > 0 && (
                  <tbody>
                    {pointHistoryData.map((pointHistory) => (
                      <tr key={pointHistory.updated_at}>
                        <td className="text-sm px-3 py-4 font-semibold text-gray-700">
                          {pointHistory.description}
                        </td>
                        <td className="text-sm px-3 py-4 text-gray-500">
                          {moment(pointHistory.updated_at).format('DD MMMM YYYY')}
                        </td>
                        <td
                          className={`text-sm px-3 py-4 font-semibold ${
                            pointHistory.point >= 0 ? 'text-green-500' : 'text-red-500'
                          }`}
                        >
                          {pointHistory.point >= 0 ? `+${pointHistory.point}` : pointHistory.point}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>

            {!isLoadingPointHistory && pointHistoryData && pointHistoryData.length === 0 && (
              <div className="w-full text-sm text-gray-500 text-center py-3">
                Tidak ada data riwayat perolehan poin
              </div>
            )}

            {isLoadingPointHistory && (
              <div className="w-full text-center text-lg text-primary flex justify-center py-3">
                <BiLoaderAlt className="animate-spin" />
              </div>
            )}

            {pointHistoryData && pointHistoryTotal && (
              <div className="flex flex-col items-center md:flex-row justify-between space-y-6 md:space-y-0">
                <div className="flex items-center space-x-3 text-xs">
                  <div>Show</div>
                  <select onChange={handleChangePerPage} className="text-xs rounded-sm">
                    {PER_PAGE_OPTIONS.map((option) => (
                      <option key={option.value} selected={option.value === perPage} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <div>per page of {pointHistoryTotal} results</div>
                </div>
                {pointHistoryTotal > perPage && (
                  <ProfilePagination
                    itemLength={pointHistoryTotal}
                    activePage={activePage}
                    setActivePage={setActivePage}
                    perPage={perPage}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </ProfileContainer>
    </div>
  );
};
