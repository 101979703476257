import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import Logo from '../images/logo/logo-tumbu-new.png';
import LogoWhite from '../images/logo/logo-tumbu-new-white.png';
import LogoSecondary from '../images/logo/logo-ukm-indonesia.png';

const NAME = 'Tumbu';
const NAME_SECONDARY = 'PT Inovasi Tumbuh Inklusif';
const DESCRIPTION =
  'Belajar Tumbu adalah situs daring pembelajaran online yang diinisiasi oleh Tim ukmindonesia.id - diisi serta dikelola oleh TUMBU Accelerator selaku mitra edukasi kewirausahaan dari Tumbu.';
const ADDRESS =
  'TUMBU Accelerator PT Indonesia Tumbuh Inklusif Jalan TB Simatupang , 18 Office Park GF Unit 6 Jakarta 12520';
const EMAIL = 'info@ukmindonesia.org';
const PHONE = '+62 251 838 2223/ 837 2400';
const LOGO = Logo;
const LOGO_WHITE = LogoWhite;
const LOGO_SECONDARY = LogoSecondary;
const BANNER = null;
const CAMPAIGN_VIDEO = null;
const MAIN_SITE = 'Ukmindonesia.id';

const ADS_BANNER = {
  image: null,
  url: null,
};

const SOCIAL_MEDIA = {
  FACEBOOK: {
    title: 'Facebook',
    icon: <FaFacebook />,
    url: 'https://facebook.com/',
  },
  TWITTER: {
    title: 'Twitter',
    icon: <FaTwitter />,
    url: 'https://twitter.com/',
  },
  INSTAGRAM: {
    title: 'Instagram',
    icon: <FaInstagram />,
    url: 'https://facebook.com/',
  },
};

const ABOUT_US = [
  {
    title: 'About Item 1',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe id error consectetur totam ullam aut dolores. Exercitationem, ab, perferendis, doloribus ut iste nulla ea dolor fugit corrupti illo natus atque?',
    image: 'https://cdni.iconscout.com/illustration/free/thumb/about-us-2061897-1740019.png',
  },
  {
    title: 'About Item 2',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe id error consectetur totam ullam aut dolores. Exercitationem, ab, perferendis, doloribus ut iste nulla ea dolor fugit corrupti illo natus atque?',
    image: 'https://cdni.iconscout.com/illustration/free/thumb/about-us-2061897-1740019.png',
  },
];

const FEATURES = [
  {
    title: 'Fitur Satu',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum neque quod dolore omnis, quas animi ipsa quia delectus?',
    image: 'https://image.flaticon.com/icons/png/512/1998/1998575.png',
  },
  {
    title: 'Fitur Dua',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum neque quod dolore omnis, quas animi ipsa quia delectus?',
    image: 'https://image.flaticon.com/icons/png/512/1998/1998575.png',
  },
  {
    title: 'Fitur Tiga',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum neque quod dolore omnis, quas animi ipsa quia delectus?',
    image: 'https://image.flaticon.com/icons/png/512/1998/1998575.png',
  },
];

const TESTIMONIALS = {
  sliders: [
    {
      name: 'Ahmed Rayhan Primadedas',
      role: 'Frontend Developer',
      institution: 'Founderplus.id',
      avatar: 'https://miro.medium.com/max/3360/1*gBQxShAkxBp_YPb14CN0Nw.jpeg',
      testimonial:
        'Ut dicta et. Dignissimos in ad dolorem. Nobis architecto accusantium commodi aut quaerat provident iusto. Praesentium autem recusandae adipisci dolores ea deserunt eos. Asperiores voluptatem suscipit at est.',
    },
    {
      name: 'Budi Prambudi',
      role: 'Backend Developer',
      institution: 'Institution.id',
      avatar:
        'https://images.unsplash.com/photo-1552058544-f2b08422138a?ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8cGVyc29ufGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80',
      testimonial:
        'Voluptatum necessitatibus recusandae quia culpa quo quia aspernatur eum asperiores. Aut est sed ullam facere voluptatum est cumque qui ut. Sunt quos qui qui qui id quo sunt. Dolores rerum voluptatum et. Culpa non aut eos distinctio et et.',
    },
    {
      name: 'John Doe',
      role: 'DevOps Engineer',
      institution: 'Institution.id',
      avatar: 'https://qodebrisbane.com/wp-content/uploads/2019/07/This-is-not-a-person-2-1.jpeg',
      testimonial:
        'Voluptatum necessitatibus recusandae quia culpa quo quia aspernatur eum asperiores. Aut est sed ullam facere voluptatum est cumque qui ut. Sunt quos qui qui qui id quo sunt. Dolores rerum voluptatum et. Culpa non aut eos distinctio et et.',
    },
  ],
  grid: [
    {
      author: 'Ahmed Rayhan P',
      role: 'Frontend Developer',
      institution: 'Founderplus.id',
      testimonial:
        'Nihil est fugiat dolorem aut tempora reiciendis ipsum. Cum ratione iusto modi magni ut. Perspiciatis est facere labore ut magnam non rerum rem. Deleniti vel nihil id ut. Et et qui quas possimus illum aut eos. Enim tempore et deserunt.',
      backgroundSrc:
        'https://image.freepik.com/free-vector/beautiful-mountain-blue-sky-with-sea-view-background_105940-540.jpg',
    },
    {
      author: 'John Doe',
      role: 'Backend Developer',
      institution: 'Institution.id',
      testimonial:
        'Porro error alias aut consequatur ut. Occaecati provident nihil voluptatem quia quibusdam et ut. Perspiciatis cumque sed cumque. Dolor provident corrupti.',
      backgroundSrc:
        'https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dmlld3xlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80',
    },
  ],
};

export const CLIENT = {
  NAME,
  NAME_SECONDARY,
  DESCRIPTION,
  ADDRESS,
  BANNER,
  EMAIL,
  PHONE,
  ADS_BANNER,
  LOGO,
  LOGO_WHITE,
  LOGO_SECONDARY,
  SOCIAL_MEDIA,
  CAMPAIGN_VIDEO,
  MAIN_SITE,
  ABOUT_US,
  FEATURES,
  TESTIMONIALS,
};
