import { MdSort } from 'react-icons/md';
import { Button } from '@/components/atoms';
import { CONSTANTS } from '@/utils';

export const RiwayatBelajarProductListHeader = ({ title, activeProduct, onChangeProduct }) => {
  const TABS = [
    { label: 'Video', value: CONSTANTS.PRODUCT_TYPE.VIDEO },
    { label: 'Kursus', value: CONSTANTS.PRODUCT_TYPE.COURSE },
  ];

  const handleChangeTab = (tab) => {
    if (onChangeProduct) onChangeProduct(tab.value);
  };

  return (
    <div className="p-5 border-b space-y-5">
      <div className="font-semibold text-primary">{title}</div>
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-3">
          {TABS.map((tab) => (
            <Button
              className={`${
                activeProduct === tab.value
                  ? 'bg-gray-500 text-white'
                  : 'bg-gray-300 text-gray-800 hover:bg-gray-400'
              } hover:text-white font-normal whitespace-nowrap`}
              variant={null}
              size={Button.SIZES.SMALL}
              onClick={() => handleChangeTab(tab)}
            >
              {tab.label}
            </Button>
          ))}
        </div>
        <div>
          <button type="button">
            <MdSort size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};
