import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import jwt from 'jsonwebtoken';
import { notify } from 'react-notify-toast';
import ReactGA from 'react-ga4';
// import Skeleton from 'react-loading-skeleton';
import { HELPERS } from '@/utils';
// import { Button, Container } from '@/components/atoms';
import { Button } from '@/components/atoms';
import { ACTION_AUTH } from '@/redux/actions';
import { CLIENT, KEY } from '@/config';
import PATH from '@/routes/path';
import AuthContainer from './components/AuthContainer';

const LoginWA = ({ documentTitle }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const loading = useSelector((state) => state.activity.showTopLoadingBar);
  const search = location.search ? HELPERS.queryStringToObject(location.search) : null;
  const { loggedIn } = useSelector((state) => state.auth);

  const [name, setName] = useState('');
  const [decoded, setDecoded] = useState('');

  const handleRegisterSuccess = (phoneNumber) => {
    const data = { phone: phoneNumber || decoded.phone_number };
    const text = phoneNumber ? 'Selamat datang kembali' : 'Terima kasih sudah mendaftar';
    dispatch(
      ACTION_AUTH.loginByWa(data, (action) => {
        if (phoneNumber) {
          ReactGA.event('sign_up', { method: 'WhatsApp' });
        } else {
          ReactGA.event('login', { method: 'WhatsApp' });
        }
        if (action.profile && action.profile.has_filled_survey) {
          notify.show(`${text} di ${CLIENT.NAME}.`, 'success');
        } else {
          history.push(PATH.PROFILE);
          notify.show(`${text} di ${CLIENT.NAME}. Silakan lengkapi data tambahan untuk melanjutkan.`, 'success');
        }
      })
    );
  };

  const handleDaftar = () => {
    const data = { name, phone: decoded.phone_number };
    dispatch(ACTION_AUTH.registerByWa(data, () => handleRegisterSuccess()));
  };

  useEffect(() => {
    document.title = documentTitle;
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    if (search) {
      const verify = jwt.verify(search && search.token ? search.token : '', KEY.MARKETA_KEY);
      setDecoded(verify);
      const data = { phone: verify.phone_number };
      dispatch(ACTION_AUTH.checkWa(data, () => handleRegisterSuccess(verify.phone_number)));
    }
  }, []);

  return (
    <AuthContainer
      title="Daftar Akun Baru"
      description="Lengkapi form di bawah dengan menggunakan data Anda yang valid"
    >
      {/* <div className="bg-gray-100 py-5"><Container> */}
      {loading && <span className="font-bold mr-3">Mengecek Keanggotaan...</span>}
      {!loading && !loggedIn && search && search.token && (
        <div className="bg-white shadow-md">
          {/* <div className="p-6 border-b">
            <h1 className="text-xl font-bold">WhatsApp Registration</h1>
          </div> */}
          <div className="py-3 flex items-center">
            <span className="font-bold mr-3">Nama* : </span>
            <input
              value={name}
              className="border-gray-300"
              placeholder="Nama"
              type="text"
              onChange={(e) => {
                setName(e.target.value);
                console.log(e.target.value);
              }}
            />
          </div>
          <div className="py-3">
            <Button
              onClick={handleDaftar}
              className="w-full"
              size={Button.SIZES.LARGE}
              variant={Button.VARIANTS.PRIMARY_OUTLINE}
            >
              Daftar
            </Button>
          </div>
        </div>
      )}
      {!loading && !loggedIn && search && search.token && (
        <div className="bg-white shadow-md">
          <div className="py-6 border-b">
            <h1 className="text-xl font-bold">Forbidden</h1>
          </div>
        </div>
      )}
      {/* </Container></div> */}
    </AuthContainer>
  );
};

export default LoginWA;
