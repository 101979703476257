import { Link } from 'react-router-dom';

const CatalogItem = () => {
  return (
    <Link
      to="/"
      className="bg-white shadow-sm hover:shadow-md transition-all sm:space-x-3 sm:space-y-0 rounded-md flex flex-col sm:flex-row items-stretch overflow-hidden"
    >
      <div className="w-1/4" style={{ minWidth: 180, minHeight: 32 }}>
        <img
          className="w-full h-full object-cover"
          src="https://comp-eng.binus.ac.id/files/2017/09/Lab.jpg"
          alt=""
        />
      </div>
      <div className="flex flex-col justify-between space-y-3 p-3">
        <div>
          <h1 className="sm:text-lg font-semibold text-gray-800">40 Hari Jadi Kaya [Bebas Hutang]</h1>
        </div>
        <div>
          <div className="text-sm text-gray-500">Ahmed Rayhan Primadedas</div>
          <div className="text-sm sm:text-base text-primary font-semibold">Rp 60.000</div>
        </div>
      </div>
    </Link>
  );
};

export default CatalogItem;
