import moment from 'moment';
import { useState, useEffect } from 'react';
import { BiCheckCircle, BiDetail, BiLoaderAlt } from 'react-icons/bi';
import { MdHistory, MdSort } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';
import { ACTION_GAMIFICATION } from '@/redux/actions';
import { Modal } from '@/components/atoms';
import { CONSTANTS } from '@/utils';
import { ProfileContainer } from '../components/ProfileContainer';
import { ProfilePagination } from '../components/ProfilePagination';

const JadwalCoaching = ({ documentTitle }) => {
  const dispatch = useDispatch();

  const PER_PAGE_OPTIONS = [
    { label: '3', value: 3 },
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '15', value: 15 },
  ];

  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerPage] = useState(PER_PAGE_OPTIONS[1].value);

  const [activeDetailCoachingData, setActiveDetailCoachingData] = useState(null);
  const [isOpenModalDetailCoaching, setIsOpenModalDetailCoaching] = useState(false);

  const coachingHistoryData = useSelector((state) => state.gamification.coachingHistoryData);
  const coachingHistoryTotal = useSelector((state) => state.gamification.coachingHistoryTotal);
  const isLoadingCoachingHistory = useSelector((state) => state.gamification.coachingHistoryDataLoading);

  const handleChangePerPage = (event) => {
    const { value } = event.target;
    setPerPage(Number(value));
  };

  const handleCloseModalDetailCoaching = () => {
    setIsOpenModalDetailCoaching(false);
    setActiveDetailCoachingData(null);
  };

  const handleOpenModalDetailCoaching = (coachingData) => {
    setIsOpenModalDetailCoaching(true);
    setActiveDetailCoachingData(coachingData);
  };

  useEffect(() => {
    document.title = documentTitle;
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send('pageview');
  }, []);

  useEffect(() => dispatch(ACTION_GAMIFICATION.getCoachingHistory(activePage, perPage)), [activePage, perPage]);

  return (
    <div>
      <Modal open={isOpenModalDetailCoaching} onClose={handleCloseModalDetailCoaching} title="Detail Coaching">
        {activeDetailCoachingData && (
          <div className="space-y-6">
            <div className="space-y-2">
              <div className="font-semibold">Tema</div>
              <div className="text-gray-600">{activeDetailCoachingData.category.name}</div>
            </div>
            <div className="space-y-2">
              <div className="font-semibold">Tanggal</div>
              <div className="text-gray-600">{moment(activeDetailCoachingData.date).format('DD MMMM yyyy')}</div>
            </div>
            <div className="space-y-2">
              <div className="font-semibold">Pertanyaan</div>
              <div className="text-gray-600">{activeDetailCoachingData.questions}</div>
            </div>
            <div className="space-y-2">
              <div className="font-semibold">Link Media Sosial</div>
              <div className="text-gray-600">{activeDetailCoachingData.media_socials}</div>
            </div>
          </div>
        )}
      </Modal>

      <ProfileContainer>
        <div className="w-full bg-white rounded-md p-5 space-y-5 overflow-hidden">
          <div className="flex w-full justify-between items-center">
            <div className="font-bold text-gray-700">Riwayat Coaching</div>
            <button type="button">
              <MdSort size={20} />
            </button>
          </div>
          <div className="overflow-x-scroll max-w-full">
            <table className="w-full text-left">
              <thead className="border-t border-b">
                <tr>
                  <th style={{ minWidth: 120 }} className="w-1/3 text-sm p-3">
                    Tema
                  </th>
                  <th style={{ minWidth: 120 }} className="w-1/3 text-sm p-3">
                    Jadwal
                  </th>
                  <th style={{ minWidth: 120 }} className="w-1/3 text-sm p-3">
                    Status
                  </th>
                  <th style={{ minWidth: 120 }} className="w-1/3 text-sm p-3">
                    Detail
                  </th>
                </tr>
              </thead>
              {!isLoadingCoachingHistory && coachingHistoryData && coachingHistoryData.length > 0 && (
                <tbody>
                  {coachingHistoryData.map((coaching) => (
                    <tr>
                      <td className="text-sm px-3 py-5 font-semibold text-gray-700">{coaching.category.name}</td>
                      <td className="text-sm px-3 py-5 text-gray-500">
                        {moment(coaching.date).format('DD MMMM yyyy')}
                      </td>
                      <td className="text-sm px-3 py-5">
                        {coaching.status === CONSTANTS.COACHING_SCHEDULE_STATUS.STATUS_SUBMITED && (
                          <div className="inline-flex items-center space-x-2 px-3 py-2 rounded-md bg-gray-400 text-white">
                            <MdHistory size={18} />
                            <div className="font-semibold">Akan Datang</div>
                          </div>
                        )}
                        {coaching.status === CONSTANTS.COACHING_SCHEDULE_STATUS.STATUS_FINISHED && (
                          <div className="inline-flex items-center space-x-2 px-3 py-2 rounded-md bg-green-500 text-white">
                            <BiCheckCircle size={18} />
                            <div className="font-semibold">Selesai</div>
                          </div>
                        )}
                      </td>
                      <td>
                        <button
                          type="button"
                          className="inline-flex items-center space-x-2 px-3 py-2 rounded-md bg-primary hover:bg-primary-400 transition text-white"
                          onClick={() => handleOpenModalDetailCoaching(coaching)}
                        >
                          <BiDetail size={18} />
                          <div className="font-semibold text-sm">Detail</div>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>

          {!isLoadingCoachingHistory && coachingHistoryData && coachingHistoryData.length === 0 && (
            <div className="w-full text-sm text-gray-500 text-center py-3">
              Tidak ada data riwayat jadwal coaching
            </div>
          )}

          {isLoadingCoachingHistory && (
            <div className="w-full text-center text-lg text-primary flex justify-center py-3">
              <BiLoaderAlt className="animate-spin" />
            </div>
          )}

          {coachingHistoryData && coachingHistoryTotal > 0 && (
            <div className="flex flex-col items-center md:flex-row justify-between space-y-6 md:space-y-0">
              <div className="flex items-center space-x-3 text-xs">
                <div>Show</div>
                <select onChange={handleChangePerPage} className="text-xs rounded-sm">
                  {PER_PAGE_OPTIONS.map((option) => (
                    <option selected={option.value === perPage} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <div>per page of {coachingHistoryTotal} results</div>
              </div>
              {coachingHistoryTotal > perPage && (
                <ProfilePagination
                  itemLength={coachingHistoryTotal}
                  activePage={activePage}
                  setActivePage={setActivePage}
                  perPage={perPage}
                />
              )}
            </div>
          )}
        </div>
      </ProfileContainer>
    </div>
  );
};

export default JadwalCoaching;
