import { MdErrorOutline, MdWarning, MdCheck } from 'react-icons/md';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const InputTextArea = ({
  label,
  icon,
  disabled,
  size,
  variant,
  iconSuffix,
  className,
  containerClassName,
  feedback,
  feedbackType,
  meta,
  required,
  ...props
}) => {
  const variantClasses = classNames({
    'border bg-white rounded-md': variant === InputTextArea.VARIANTS.DEFAULT,
    'border-b-2 focus-within:border-secondary': variant === InputTextArea.VARIANTS.MATERIAL,
  });

  const labelClasses = classNames({
    'border-red-500':
      ((meta && meta.touched && meta.error) || feedback) && feedbackType === InputTextArea.FEEDBACK_TYPE.ERROR,
    'border-green-500':
      ((meta && meta.touched && meta.error) || feedback) && feedbackType === InputTextArea.FEEDBACK_TYPE.SUCCESS,
    'border-yellow-500':
      ((meta && meta.touched && meta.error) || feedback) && feedbackType === InputTextArea.FEEDBACK_TYPE.WARNING,
  });

  const sizeClasses = classNames({
    'px-5 py-3': size === InputTextArea.SIZES.MEDIUM,
    'p-2': size === InputTextArea.SIZES.SMALL,
  });

  const feedbackClasses = classNames({
    'text-red-500': feedbackType === InputTextArea.FEEDBACK_TYPE.ERROR,
    'text-green-500': feedbackType === InputTextArea.FEEDBACK_TYPE.SUCCESS,
    'text-yellow-500': feedbackType === InputTextArea.FEEDBACK_TYPE.WARNING,
  });

  const disabledClasses = classNames({
    'text-gray-400 placeholder-gray-400': disabled,
    'text-black placeholder-gray-500': !disabled,
  });

  return (
    <div className={`${containerClassName}`}>
      {label && (
        <div className="mb-2 text-sm text-primary">
          {label} {required && <span className="text-red-500 font-semibold">*</span>}
        </div>
      )}
      <label
        className={`flex items-center justify-center transition-all space-x-3 text-gray-500 ${labelClasses} ${sizeClasses} ${variantClasses} ${className}`}
        htmlFor={props.name}
      >
        {icon}
        <textarea
          className={`border-0 bg-transparent p-0 outline-none focus:ring-0 text-sm w-full ${disabledClasses}`}
          name={props.name}
          id={props.name}
          disabled={disabled}
          {...props}
        >
          {props.value}
        </textarea>
        {iconSuffix}
      </label>
      {((meta && meta.touched && meta.error) || feedback) && (
        <div className={`${feedbackClasses} text-xs mt-2 flex items-center space-x-1`}>
          {feedbackType === InputTextArea.FEEDBACK_TYPE.SUCCESS && <MdCheck size={16} />}
          {feedbackType === InputTextArea.FEEDBACK_TYPE.ERROR && <MdErrorOutline size={16} />}
          {feedbackType === InputTextArea.FEEDBACK_TYPE.WARNING && <MdWarning size={16} />}
          <span>{feedback || meta.error}</span>
        </div>
      )}
    </div>
  );
};
InputTextArea.VARIANTS = {
  DEFAULT: 'DEFAULT',
  MATERIAL: 'MATERIAL',
};

InputTextArea.SIZES = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
};

InputTextArea.FEEDBACK_TYPE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
};

InputTextArea.propTypes = {
  variant: PropTypes.oneOf(Object.values(InputTextArea.VARIANTS)),
  size: PropTypes.oneOf(Object.values(InputTextArea.SIZES)),
  icon: PropTypes.node,
  disabled: PropTypes.bool,
  iconSuffix: PropTypes.node,
  label: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  feedback: PropTypes.string,
  required: PropTypes.bool,
  feedbackType: PropTypes.oneOf(Object.values(InputTextArea.FEEDBACK_TYPE)),
  meta: PropTypes.shape({
    error: PropTypes.string,
    initialError: PropTypes.string,
    initialTouched: PropTypes.bool,
    initialValue: PropTypes.string,
    touched: PropTypes.bool,
  }),
};

InputTextArea.defaultProps = {
  label: '',
  variant: InputTextArea.VARIANTS.DEFAULT,
  size: InputTextArea.SIZES.MEDIUM,
  icon: null,
  disabled: false,
  iconSuffix: null,
  className: '',
  required: false,
  containerClassName: '',
  feedback: '',
  feedbackType: InputTextArea.FEEDBACK_TYPE.ERROR,
  meta: null,
};
