import store from 'store';
import { CONSTANTS } from '@/utils';
import { http } from './http';

const BASE_URL = '/profile';

export const SERVICE_PROFILE = {
  getLearningDuration: (callback) => {
    const config = {
      headers: {
        Authorization: `Bearer ${store.get(CONSTANTS.PROFILE_TOKEN)}`,
      },
    };

    http
      .get(`${BASE_URL}/learning-duration`, config)
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },
  getOnGoingVideos: (callback) => {
    const config = {
      headers: {
        Authorization: `Bearer ${store.get(CONSTANTS.PROFILE_TOKEN)}`,
      },
    };

    http
      .get(`${BASE_URL}/videos/ongoing`, config)
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },
  getCompletedVideos: (callback) => {
    const config = {
      headers: {
        Authorization: `Bearer ${store.get(CONSTANTS.PROFILE_TOKEN)}`,
      },
    };

    http
      .get(`${BASE_URL}/videos/completed`, config)
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },
  getOnGoingCourses: (callback) => {
    const config = {
      headers: {
        Authorization: `Bearer ${store.get(CONSTANTS.PROFILE_TOKEN)}`,
      },
    };

    http
      .get(`${BASE_URL}/courses/ongoing`, config)
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },
  getCompletedCourses: (callback) => {
    const config = {
      headers: {
        Authorization: `Bearer ${store.get(CONSTANTS.PROFILE_TOKEN)}`,
      },
    };

    http
      .get(`${BASE_URL}/courses/completed`, config)
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },
  getProfile: (data, callback) => {
    const config = {
      headers: {
        Authorization: `Bearer ${store.get(CONSTANTS.PROFILE_TOKEN)}`,
      },
    };

    const query = data && data.survey ? `survey=${data.survey}` : '';

    http
      .get(`${BASE_URL}?${query}`, config)
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },
  getProfileSurvey: (surveyId, callback) => {
    const config = {
      headers: {
        Authorization: `Bearer ${store.get(CONSTANTS.PROFILE_TOKEN)}`,
      },
    };

    const query = surveyId && surveyId > 0 ? `survey_id=${surveyId}` : '';

    http
      .get(`${BASE_URL}/survey?${query}`, config)
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },
  updateProfile: (input, isWa, isEmail, callback) => {
    let data = {};
    if (isWa) {
      data = { username: input.email, name: input.name, email: input.email };
    } else if (isEmail) {
      data = {
        username: input.email,
        name: input.name,
        phone: input.phone,
        old_password: input.old_password,
        new_password: input.new_password,
        new_password_confirmation: input.new_password_confirmation,
      };
    } else {
      data = { username: input.email, name: input.name, phone: input.phone };
    }
    const config = {
      headers: {
        Authorization: `Bearer ${store.get(CONSTANTS.PROFILE_TOKEN)}`,
      },
    };

    http
      .post(`${BASE_URL}`, data, config)
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },
  updateProfileSurvey: (surveyId, surveyAnswers, callback) => {
    const data = {
      survey_id: surveyId,
      survey_answers: surveyAnswers,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${store.get(CONSTANTS.PROFILE_TOKEN)}`,
      },
    };

    http
      .post(`${BASE_URL}/survey`, data, config)
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },
};
