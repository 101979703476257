import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ACTION_VIDEOS, ACTION_COURSES } from '@/redux/actions';
import { Button } from '@/components/atoms';
import { CONSTANTS, HELPERS } from '@/utils';
import { ProductTopicList } from '../ProductResource/ProductTopicList';
import { SideCard } from './SideCard';
import { ProductChapterAccordion } from '../ProductResource/ProductChapterAccordion';

export const SideMenu = ({
  type,
  topicListData,
  isLoading,
  courseName,
  chapters,
  activeChapter,
  activeTopic,
  isCourseLocked,
  onChangeActiveChapter,
  onChangeActiveTopic,
  categories,
  categoriesName,
}) => {
  const dispatch = useDispatch();

  const newData = useSelector((state) => state.videos.listData);
  const terkait = useSelector((state) => state.videos.listDataTerkait);
  const courses = useSelector((state) => state.courses.listData);

  const [sideMenu, setSideMenu] = useState(null);
  const [sideData, setSideData] = useState(null);
  const [tabType, setTabType] = useState('');
  const [menu, setMenu] = useState(null);

  useEffect(() => {
    const menuList = [
      {
        name: 'Terkait',
        link: 'terkait',
        type: CONSTANTS.PRODUCT_TYPE.VIDEO,
      },
      {
        name: 'Baru Diupload',
        link: 'new',
        type: CONSTANTS.PRODUCT_TYPE.VIDEO,
      },
      {
        name: 'Course',
        link: 'course',
        type: CONSTANTS.PRODUCT_TYPE.COURSE,
      },
    ];
    if (type === CONSTANTS.PRODUCT_TYPE.VIDEO) {
      const video = {
        name: 'Video',
        link: null,
        type: CONSTANTS.PRODUCT_TYPE.VIDEO,
      };
      setMenu([video, ...menuList]);
    }
    if (type === CONSTANTS.PRODUCT_TYPE.COURSE) {
      const course = {
        name: courseName,
        link: null,
        type: CONSTANTS.PRODUCT_TYPE.COURSE,
      };
      setMenu([course, ...menuList]);
    }
  }, [type, courseName]);

  useEffect(() => {
    if (menu) {
      setTabType(menu.find((el) => el.link === sideMenu).type);
    }
    if (sideMenu === 'terkait') {
      setSideData(terkait);
    } else if (sideMenu === 'new') {
      setSideData(newData);
    } else if (sideMenu === 'course') {
      setSideData(courses);
    } else {
      setSideData(null);
    }
  }, [sideMenu, menu]);

  useEffect(() => {
    dispatch(ACTION_VIDEOS.getVideoListData(1, 7, HELPERS.queryStringToObject('?sort_by=id&order=desc')));
    dispatch(ACTION_COURSES.getCourseListData(1, 7, HELPERS.queryStringToObject('?sort_by=id&order=desc')));
  }, []);

  useEffect(() => {
    dispatch(
      ACTION_VIDEOS.getVideoListData(
        1,
        7,
        HELPERS.queryStringToObject(`?sort_by=id&order=desc&category_id=${categories}`),
        true
      )
    );
  }, [categories]);

  return (
    <div className="lg:w-5/12 md:sticky md:top-28">
      <div className="flex space-x-3 overflow-x-scroll mb-5">
        {menu &&
          menu.map((item) => (
            <Button
              key={item.name + item.link}
              size={Button.SIZES.SMALL2X}
              variant={sideMenu !== item.link ? Button.VARIANTS.GRAY : Button.VARIANTS.PRIMARY}
              onClick={() => setSideMenu(item.link)}
            >
              {item.name}
            </Button>
          ))}
      </div>
      <div className="px-5 xl:px-0">
        {!sideMenu && type === CONSTANTS.PRODUCT_TYPE.VIDEO && (
          <ProductTopicList
            isLoading={isLoading}
            topics={topicListData}
            activeTopic={activeTopic}
            onChangeActiveTopic={onChangeActiveTopic}
          />
        )}
        {!sideMenu && type === CONSTANTS.PRODUCT_TYPE.COURSE && (
          <ProductChapterAccordion
            isLoading={isLoading}
            chapters={chapters}
            activeChapter={activeChapter}
            activeTopic={activeTopic}
            isCourseLocked={isCourseLocked}
            onChangeActiveChapter={onChangeActiveChapter}
            onChangeActiveTopic={onChangeActiveTopic}
          />
        )}
        {sideMenu !== null &&
          sideData !== null &&
          sideData.map((item, idx) => (
            <SideCard
              key={item.uuid}
              uuid={item.uuid}
              index={idx}
              thumbnail={item.thumbnail}
              title={item.title}
              rating={item.rating}
              view={item.total_view}
              type={tabType}
              menu={sideMenu}
              date={item.created_at}
              categoriesName={categoriesName}
            />
          ))}
      </div>
    </div>
  );
};
