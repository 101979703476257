import { http } from './http';

const BASE_URL = '/footer';

export const SERVICE_FOOTER = {
  getDisclaimer: (callback) => {
    http
      .get(`${BASE_URL}/disclaimer`)
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.responce }));
  },
  getPrivacy: (callback) => {
    http
      .get(`${BASE_URL}/privacy`)
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.responce }));
  },
};
