import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { BackgroundImage, Container } from '@/components/atoms';

export const PageBanner = ({ bgImage, title, description, className, onClick, height }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleClick = (event) => {
    if (!isLoading) onClick(event);
  };

  return (
    <div className="relative">
      {isLoading && <PageBanner.Skeleton className="absolute left-0 top-0 z-20 w-full h-full" />}
      <button
        style={{ height }}
        className="flex items-center w-full text-left"
        type="button"
        onClick={handleClick}
      >
        <div>
          <img
            className="absolute z-10 left-0 top-1/2 transform -translate-y-1/2 w-full h-full object-cover"
            src={bgImage}
            alt=""
            onLoad={() => setIsLoading(false)}
          />
          {!isLoading && <BackgroundImage className="z-20" overlayColor="black" overlayOpacity={0.3} />}
        </div>
        {!isLoading && (
          <Container className={`relative z-20 flex items-center ${className}`}>
            <div className="flex w-full">
              <div className="relative z-10 w-full lg:w-1/2 mb-12 lg:mb-5">
                <h1 className="text-white text-4xl mb-6 font-bold leading-snug capitalize">{title}</h1>
                <h3 className="text-white font-primary text-lg leading-relaxed">{description}</h3>
              </div>
            </div>
          </Container>
        )}
      </button>
    </div>
  );
};

PageBanner.Skeleton = ({ className, height }) => {
  return (
    <div className={`-mt-1 ${className}`}>
      <Skeleton style={{ borderRadius: 0 }} width="100%" height={height || '100%'} />
    </div>
  );
};

PageBanner.defaultProps = {
  bgImage: null,
  video: null,
  title: null,
  description: null,
  buttonText: null,
  className: '',
  onClickButton: () => {},
};
