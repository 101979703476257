import { SERVICE_COURSE } from '@/services';
import { TYPES_ACTIVITY } from '../activity/activity.types';
import { TYPES_COURSES } from './courses.types';

export const ACTION_COURSES = {
  getCourseListData: (page, limit, queries = null) => {
    return (dispatch) => {
      dispatch({ type: TYPES_COURSES.SET_COURSES_LOADING, listLoading: true });

      SERVICE_COURSE.getCourses(page, limit, queries, (res) => {
        dispatch({ type: TYPES_COURSES.SET_COURSES_LOADING, listLoading: false });
        dispatch({
          type: TYPES_COURSES.GET_COURSES_DATA,
          payload: res.payload || [],
          total: res.total,
          totalPage: res.totalPage,
          error: res.error,
        });
      });
    };
  },

  getCourseListLoadMoreData: (page, limit, queries = null) => {
    return (dispatch) => {
      dispatch({ type: TYPES_COURSES.SET_LIST_COURSES_LOADMORE_LOADING, loading: true });

      SERVICE_COURSE.getCourses(page, limit, queries, (res) => {
        dispatch({ type: TYPES_COURSES.SET_LIST_COURSES_LOADMORE_LOADING, loading: false });
        dispatch({
          type: TYPES_COURSES.GET_LIST_COURSES_LOADMORE_DATA,
          payload: res.payload || [],
          total: res.total,
          error: res.error,
        });
      });
    };
  },

  getCourseDetailData: (uid) => {
    return (dispatch) => {
      dispatch({ type: TYPES_COURSES.SET_DETAIL_COURSES_LOADING });

      SERVICE_COURSE.getCourseDetailData(uid, (res) => {
        dispatch({
          type: TYPES_COURSES.GET_DETAIL_COURSES_DATA,
          payload: res.payload,
          error: res.error,
        });
      });
    };
  },

  getCourseRating: (uid) => {
    return (dispatch) => {
      dispatch({ type: TYPES_COURSES.SET_COURSE_RATING_LOADING, loading: true });

      SERVICE_COURSE.getCourseRating(uid, (res) => {
        dispatch({ type: TYPES_COURSES.SET_COURSE_RATING_LOADING, loading: false });
        dispatch({
          type: TYPES_COURSES.GET_COURSE_RATING_DATA,
          payload: res.payload,
          error: res.error,
        });
      });
    };
  },

  setCourseRating: (uid, commentBody, ratingValue, callback) => {
    return (dispatch) => {
      dispatch({ type: TYPES_COURSES.SET_COURSE_RATING_IS_SUBMITTING, isSubmitting: true });

      SERVICE_COURSE.setCourseRating(uid, commentBody, ratingValue, (res) => {
        if (callback) callback();
        dispatch({ type: TYPES_COURSES.SET_COURSE_RATING_IS_SUBMITTING, isSubmitting: false });
        dispatch({
          type: TYPES_COURSES.SET_COURSE_RATING_LOADING,
          payload: res.payload,
          error: res.error,
        });
      });
    };
  },

  enrollCourse: (uuid, callback) => {
    return (dispatch) => {
      dispatch({ type: TYPES_ACTIVITY.SHOW_TOP_LOADING_BAR });
      dispatch({ type: TYPES_COURSES.SET_ENROLL_COURSE_LOADING, loading: true });

      SERVICE_COURSE.enrollCourse(uuid, (res) => {
        if (callback && res.payload) callback(res.payload);
        dispatch({ type: TYPES_ACTIVITY.HIDE_TOP_LOADING_BAR });
        dispatch({ type: TYPES_COURSES.SET_ENROLL_COURSE_LOADING, loading: false });
      });
    };
  },

  getCourseReviewsData: (uid, callback) => {
    return (dispatch) => {
      dispatch({ type: TYPES_COURSES.SET_COURSE_REVIEWS_LOADING, loading: true });

      SERVICE_COURSE.getCourseReviews(uid, (res) => {
        dispatch({ type: TYPES_COURSES.SET_COURSE_REVIEWS_LOADING, loading: false });
        if (callback) callback();
        dispatch({
          type: TYPES_COURSES.GET_COURSE_REVIEWS_DATA,
          payload: res.payload,
        });
      });
    };
  },

  setCourseVideoProgress: (
    uuid,
    chapterUuid,
    startedAt,
    pausedAt,
    skippedAt,
    skipPattern,
    totalDuration,
    playbackSpeed,
    finishedAt
  ) => {
    return () => {
      SERVICE_COURSE.setCourseVideoProgress(
        uuid,
        chapterUuid,
        startedAt,
        pausedAt,
        skippedAt,
        skipPattern,
        totalDuration,
        playbackSpeed,
        finishedAt,
        () => {}
      );
    };
  },

  getCourseCertificate: (uid) => {
    return (dispatch) => {
      dispatch({ type: TYPES_COURSES.SET_COURSE_CERTIFICATE_LOADING, loading: true });

      SERVICE_COURSE.getCourseReport(uid, (res) => {
        dispatch({ type: TYPES_COURSES.SET_COURSE_CERTIFICATE_LOADING, loading: false });
        dispatch({
          type: TYPES_COURSES.GET_COURSE_CERTIFICATE_DATA,
          payload: res.payload,
        });
      });
    };
  },
};
