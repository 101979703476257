import { TYPES_WORKINGPAPER } from './workingpaper.types';

const INITIAL_STATE = {
  submissionData: null,
  isLoading: false,
  isUploadingFile: false,
};

const workingPaperReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES_WORKINGPAPER.SET_WORKINGPAPER_LOADING:
      return { ...state, isLoading: action.isLoading };

    case TYPES_WORKINGPAPER.SET_WORKINGPAPER_UPLOADING_FILE:
      return { ...state, isUploadingFile: action.isUploadingFile };

    case TYPES_WORKINGPAPER.GET_WORKINGPAPER_SUBMISSION_DATA:
      return {
        ...state,
        submissionData: action.payload,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default workingPaperReducer;
