import { SERVICE_WORKINGPAPER } from '@/services';
import { TYPES_WORKINGPAPER } from './workingpaper.types';

const getWorkingPaper = (uuid) => {
  return (dispatch) => {
    dispatch({ type: TYPES_WORKINGPAPER.SET_WORKINGPAPER_LOADING, isLoading: true });

    SERVICE_WORKINGPAPER.getWorkingPaper(uuid, (res) => {
      dispatch({ type: TYPES_WORKINGPAPER.SET_WORKINGPAPER_LOADING, isLoading: false });
      dispatch({
        type: TYPES_WORKINGPAPER.GET_WORKINGPAPER_SUBMISSION_DATA,
        payload: res.payload,
      });
    });
  };
};

const submitWorkingPaper = (uuid, formData, callback) => {
  return (dispatch) => {
    dispatch({ type: TYPES_WORKINGPAPER.SET_WORKINGPAPER_UPLOADING_FILE, isUploadingFile: true });

    SERVICE_WORKINGPAPER.submitWorkingPaper(uuid, formData, ({ payload }) => {
      if (payload) callback(payload);
      dispatch({ type: TYPES_WORKINGPAPER.SET_WORKINGPAPER_UPLOADING_FILE, isUploadingFile: false });
    });
  };
};

export const ACTION_WORKINGPAPER = {
  getWorkingPaper,
  submitWorkingPaper,
};
