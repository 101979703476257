// import { NavLink, useHistory } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { useDispatch, useSelector } from 'react-redux';
import { useSelector } from 'react-redux';
// import { MdHistory, MdStars, MdStreetview, MdSettings, MdExitToApp } from 'react-icons/md';
import { MdHistory, MdStars, MdStreetview } from 'react-icons/md';
// import { ACTION_AUTH } from '@/redux/actions';
import PATH from '@/routes/path';

export const SideNavMenu = ({ handleClose }) => {
  // const dispatch = useDispatch();
  // const history = useHistory();

  const { loggedIn } = useSelector((state) => state.auth);

  const { t } = useTranslation();

  const MEMBER_MENUS = [
    {
      exact: false,
      title: 'Riwayat Belajar',
      link: PATH.PROFILE_RIWAYAT_BELAJAR,
      icon: <MdHistory />,
    },
    {
      exact: false,
      title: 'Poin Saya',
      link: PATH.PROFILE_POIN_SAYA,
      icon: <MdStars />,
    },
    {
      exact: false,
      title: 'Jadwal Coaching',
      link: PATH.PROFILE_JADWAL_COACHING,
      icon: <MdStreetview />,
    },
    // {
    //   exact: false,
    //   title: 'Pengaturan Profil',
    //   link: PATH.PROFILE_SETTING,
    //   icon: <MdSettings />,
    // },
    // {
    //   title: 'Keluar',
    //   onClick: () => dispatch(ACTION_AUTH.logout(() => history.push(PATH.HOME))),
    //   icon: <MdExitToApp />,
    // },
  ];

  const PUBLIC_MENUS = [
    {
      exact: false,
      title: 'Video',
      link: PATH.VIDEO,
    },
    {
      exact: false,
      title: 'Kursus',
      link: PATH.COURSE,
    },
    {
      exact: false,
      title: t('auth_login'),
      link: PATH.AUTH_LOGIN,
    },
    {
      exact: false,
      title: t('auth_register'),
      link: PATH.AUTH_REGISTER,
    },
  ];

  const menus = loggedIn ? MEMBER_MENUS : PUBLIC_MENUS;

  const className =
    'flex items-center text-left text-sm px-8 py-5 text-gray-500 hover:bg-primary-100 hover:bg-opacity-10 transition space-x-3';

  return (
    <ul>
      {menus.map((menu) => (
        <li key={menu.title}>
          {menu.onClick ? (
            <button
              className={`${className} w-full h-full`}
              type="button"
              onClick={() => {
                if (handleClose) handleClose();
                menu.onClick();
              }}
            >
              <span className="text-primary text-xl">{menu.icon}</span>
              <span>{menu.title}</span>
            </button>
          ) : (
            <NavLink
              exact
              activeClassName="bg-primary-100 bg-opacity-30 font-semibold text-primary"
              className={`${className}`}
              to={menu.link}
              onClick={handleClose}
            >
              <span className="text-primary text-xl">{menu.icon}</span>
              <span>{menu.title}</span>
            </NavLink>
          )}
        </li>
      ))}
    </ul>
  );
};
