export const TYPES_ACTIVITY = {
  TOGGLE_SIDENAV: 'TOGGLE_SIDENAV',
  SHOW_CATALOG_MENU: 'SHOW_CATALOG_MENU',
  HIDE_CATALOG_MENU: 'HIDE_CATALOG_MENU',
  SHOW_TOP_LOADING_BAR: 'SHOW_TOP_LOADING_BAR',
  HIDE_TOP_LOADING_BAR: 'HIDE_TOP_LOADING_BAR',
  SHOW_HEADER_PRODUCT_FILTER: 'SHOW_HEADER_PRODUCT_FILTER',
  HIDE_HEADER_PRODUCT_FILTER: 'HIDE_HEADER_PRODUCT_FILTER',
  SHOW_FULLPAGE_LOADER: 'SHOW_FULLPAGE_LOADER',
  HIDE_FULLPAGE_LOADER: 'HIDE_FULLPAGE_LOADER',
  SHOW_HEADER: 'SHOW_HEADER',
  HIDE_HEADER: 'HIDE_HEADER',
  SHOW_FOOTER: 'SHOW_FOOTER',
  HIDE_FOOTER: 'HIDE_FOOTER',
  SHOW_HEADER_NAV: 'SHOW_HEADER_NAV',
  HIDE_HEADER_NAV: 'HIDE_HEADER_NAV',
  IP_ADDRESS: 'IP_ADDRESS',
};
