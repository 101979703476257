import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GrClose } from 'react-icons/gr';
import { useTranslation } from 'react-i18next';
import { HELPERS } from '@/utils';
import { ACTION_ACTIVITY, ACTION_CATEGORIES } from '@/redux/actions';
import PATH from '@/routes/path';

export const CatalogMenu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const show = useSelector((state) => state.activity.showCatalogMenu);
  const categories = useSelector((state) => state.categories.payload);
  const classes = show ? 'visible opacity-100' : 'invisible opacity-0';

  const handleCloseCatalogMenu = () => dispatch(ACTION_ACTIVITY.showCatalogMenu(false));

  useEffect(() => dispatch(ACTION_CATEGORIES.getCategoriesData()), [show]);
  useEffect(() => HELPERS.disablePageScroll(show), [show]);

  return (
    <div
      className={`${classes} transition-all fixed left-0 z-40 top-0 h-screen max-h-screen min-w-full bg-white overflow-y-scroll`}
    >
      <div className="container mx-auto px-4 max-w-screen-lg min-h-screen">
        <div className="py-8 flex items-center justify-between">
          <div className="text-2xl font-semibold">{t('catalog')}</div>
          <button className="p-3 hover:bg-gray-100 rounded" type="button" onClick={handleCloseCatalogMenu}>
            <GrClose size="20" />
          </button>
        </div>

        <hr />

        <div className="py-5">
          {categories
            ? categories.map((category) => (
                <div className="mb-3 w-10/12" key={category.id}>
                  <div className="flex mb-2">
                    <img className="w-8 rounded mr-4" src={category.icon_url} alt={category.name} />
                    <div className="text-lg font-semibold text-gray-800">{category.name}</div>
                  </div>
                  <div style={{ marginLeft: 49 }}>
                    {category.subcategories.map((subcategory) => (
                      <Link
                        key={subcategory.id}
                        className="text-sm"
                        to={`${PATH.CATALOG}?category=${subcategory.id}`}
                        onClick={handleCloseCatalogMenu}
                      >
                        <span className="inline-block text-sm text-gray-600 hover:text-secondary transition-all mr-3">
                          {subcategory.name} ({subcategory.courses_count})
                        </span>
                      </Link>
                    ))}
                  </div>
                </div>
              ))
            : t('item_empty')}
        </div>
      </div>
    </div>
  );
};
