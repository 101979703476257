import { TYPES_QUIZ } from './quiz.types';

const INITIAL_STATE = {
  loading: false,
  loadingAssessment: false,
};

const quizReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES_QUIZ.SET_QUIZ_LOADING:
      return { ...state, loading: action.loading };
    case TYPES_QUIZ.SET_QUIZ_LOADING_ASSESSMENT:
      return { ...state, loadingAssessment: action.loadingAssessment };

    default:
      return state;
  }
};

export default quizReducer;
