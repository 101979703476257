import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import PATH from '@/routes/path';
import { Button } from '@/components/atoms';

export const ProductHeader = ({
  uuid,
  isLoading,
  title,
  totalViews,
  showMarkAsDone,
  enableRating,
  hasCertificate,
  enableCertificate,
  onClickRating,
}) => {
  const history = useHistory();

  const loggedIn = useSelector((state) => state.auth.loggedIn);

  const handleClickRating = () => {
    if (loggedIn) onClickRating();
    else history.push(PATH.AUTH_LOGIN);
  };

  return (
    <div className="lg:flex items-center justify-between p-6 border-b space-y-6 lg:space-y-0">
      {isLoading && <ProductHeader.Skeleton />}
      {!isLoading && (
        <div className="space-y-2">
          {title && <div className="text-2xl font-bold">{title}</div>}
          {totalViews && (
            <div className="text-sm text-gray-400">
              Telah ditonton <strong className="font-semibold">{totalViews}</strong> kali
            </div>
          )}
        </div>
      )}
      <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 sm:space-x-3">
        <Button
          className="w-full lg:w-auto"
          onClick={handleClickRating}
          disabled={!enableRating}
          variant={Button.VARIANTS.SECONDARY}
        >
          Beri Ulasan
        </Button>
        {showMarkAsDone && (
          <Button className="w-full lg:w-auto" disabled>
            Tandai Selesai
          </Button>
        )}
        {!isLoading && hasCertificate && uuid && (
          <Button
            className="w-full lg:w-auto"
            linkTo={`${PATH.CERTIFICATE}/${uuid}`}
            disabled={!enableCertificate}
          >
            Lihat Sertifikat
          </Button>
        )}
      </div>
    </div>
  );
};

ProductHeader.Skeleton = () => {
  return (
    <div>
      <div>
        <Skeleton width={300} height={30} />
      </div>
      <div>
        <Skeleton width={150} height={20} />
      </div>
    </div>
  );
};
