import React, { useState } from 'react';
import { Container, Button, Modal } from '@/components/atoms';
import PATH from '@/routes/path';
import TransactionPipe from './components/TransactionPipe';
import './TransactionDetail.css';

const TransactionDetail = () => {
  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
  const [isOpenCancellationModal, setIsOpenCancellationModal] = useState(false);

  return (
    <div className="bg-gray-100 py-10">
      <Container className="flex items-start md:space-x-5">
        <div className="bg-white w-1/3 rounded-md text-sm hidden md:block">
          <div className="p-5 border-b space-y-3">
            <div>
              <div className="font-semibold mb-1">Invoice Number</div>
              <div className="text-gray-500">20210726/135850421015030</div>
            </div>
            <div>
              <div className="font-semibold mb-1">Tanggal Pembelian</div>
              <div className="text-gray-500">26 Juli 2021</div>
            </div>
          </div>
          <div className="p-5">
            <div className="font-semibold mb-1">Modul</div>
          </div>
        </div>

        <div className="w-full space-y-3">
          <div className="w-full bg-white p-8 rounded-md">
            <TransactionPipe />
          </div>

          <div className="w-full bg-white p-8 rounded-md">
            <div className="text-center mb-6 text-gray-500 text-sm">Segera Lakukan Pembayaran</div>
            <div className="relative text-center mb-6">
              <span className="font-semibold text-xl">Rp100.031</span>
              <Button
                className="absolute right-0 top-0"
                style={{ marginTop: -7 }}
                size={Button.SIZES.SMALL}
                variant={Button.VARIANTS.PRIMARY_OUTLINE}
              >
                Salin
              </Button>
            </div>
            <div className="flex items-center bg-gray-100 rounded-md px-5 py-3 mb-10">
              <img className="w-8 mr-3" src="/images/icons/info.png" alt="" />
              <div className="text-sm">
                <span className="text-red-500 font-semibold">Penting!</span> Mohon transfer tepat sampai 3 angka
                terakhir
              </div>
            </div>
            <div className="space-y-6 text-center">
              <div className="text-sm text-gray-500">Sebelum Tanggal</div>
              <div className="text-xl font-semibold">31 Juli 2021 19:18:04 WIB</div>
            </div>
          </div>

          <div className="w-full bg-white p-8 rounded-md">
            <div className="text-center text-sm mb-6">Transfer ke rekening kami</div>
            <div className="flex items-center justify-between">
              <div>
                <img
                  className="w-20"
                  src="https://skydu.s3-ap-southeast-1.amazonaws.com/resources/file/8b3a8abc-aa42-4b2c-a437-12f3bc039e5c.jpg"
                  alt=""
                />
                <div className="font-semibold text-sm">3070012592</div>
                <div className="text-sm">(a/n Yayasan Bina Nurul Fikri)</div>
              </div>
              <Button size={Button.SIZES.SMALL} variant={Button.VARIANTS.PRIMARY_OUTLINE}>
                Salin
              </Button>
            </div>
          </div>

          <div className="w-full bg-white p-8 rounded-md">
            <div className="font-semibold text-lg mb-3">Detail Pemesanan</div>

            <table id="transactionDetail" className="w-full text-sm mb-3">
              <tr>
                <td>Pemesan</td>
                <td>Rayhan</td>
              </tr>
              <tr>
                <td>Nomor Tagihan</td>
                <td>20210726/135850421015030</td>
              </tr>
              <tr>
                <td>Metode Pembayaran</td>
                <td>Bank Transfer</td>
              </tr>
              <tr>
                <td>Transfer ke Bank</td>
                <td>Muamalah - Yayasan Bina Nurul Fikri - 3070012592</td>
              </tr>
              <tr>
                <td>Tanggal Pembelian</td>
                <td>26 Juli 2021</td>
              </tr>
            </table>

            <table className="price-list text-sm bg-gray-100 w-full">
              <tr>
                <td>Belajar Investasi Saham Sejak Dini</td>
                <td>Rp100.000</td>
              </tr>
              <tr className="border-b">
                <td>Kode Unik</td>
                <td>Rp31</td>
              </tr>
              <tr className="font-semibold">
                <td>Total</td>
                <td>Rp100.031</td>
              </tr>
            </table>

            <Button
              className="w-full mt-6"
              variant={Button.VARIANTS.SECONDARY}
              onClick={() => setIsOpenCancellationModal(true)}
            >
              Batalkan Transaksi
            </Button>
          </div>
        </div>

        <div
          className="fixed bottom-0 left-1/2 transform -translate-x-1/2 max-w-screen-md w-full bg-white p-5"
          style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.15)' }}
        >
          <div className="text-sm mb-3">
            Upload bukti untuk menyelesaikan pembayaran sebelum{' '}
            <span className="text-red-500">31 Juli 2021 19:18:04 WIB</span>
          </div>
          <Button className="w-full" onClick={() => setIsOpenUploadModal(true)}>
            Upload Bukti Pembayaran
          </Button>
        </div>
      </Container>

      <Modal title="Konfirmasi" open={isOpenCancellationModal} onClose={() => setIsOpenCancellationModal(false)}>
        <div className="text-center mb-6">Apakah Anda yakin ingin membatalkan transaksi?</div>
        <div className="flex items-center justify-center space-x-3">
          <Button variant={Button.VARIANTS.PRIMARY} onClick={() => setIsOpenCancellationModal(false)}>
            Tidak
          </Button>
          <Button variant={Button.VARIANTS.SECONDARY_OUTLINE} linkTo={PATH.TRANSACTION_CANCELLED}>
            Ya, Batalkan
          </Button>
        </div>
      </Modal>

      <Modal title="Bukti Pembayaran" open={isOpenUploadModal} onClose={() => setIsOpenUploadModal(false)}>
        <form>
          <label className="w-full block pb-5 mb-5 border-b" htmlFor="uploadPayment">
            <input className="w-full text-sm" id="uploadPayment" type="file" />
          </label>
          <Button className="w-full">Submit</Button>
        </form>
      </Modal>
    </div>
  );
};

export default TransactionDetail;
