import { useHistory } from 'react-router-dom';
import { MdPlayArrow } from 'react-icons/md';
// import { useSelector } from 'react-redux';
// import { Button } from '@/components/atoms';
import './banner.css';
import piching from '@/images/homepage/pitching-atas.png';
import PATH from '@/routes/path';

export const Banner = () => {
  const history = useHistory();
  // const loggedIn = useSelector((state) => state.auth.loggedIn);

  return (
    <div className="w-screen xl:flex px-10 py-10 bg-gray-100">
      <Banner.ImageTemp className="block xl:hidden banner-height-tablet" />
      <div className="w-full xl:w-1/2 xl:py-16 content-banner">
        <p className="font-medium text-primary tracking-wide">#YukKitaTumbuh</p>
        <h1 className="text-3xl sm:text-5xl md:text-7xl font-bold leading-normal">
          {/* Dengan <span className="text-primary">bisnis UKM</span> Anda bersama Tumbu */}
          Mulai dan Kembangkan <span className="text-primary">Bisnis Kamu</span> dengan Ilmu
        </h1>
        <p className="mt-3 mb-7 tracking-wider text-black-500">
          Yuk belajar dan tingkatkan keterampilan kewirausahaan bersama kami
          <br />
          Telah tersedia ratusan video pembelajaran disini. Gratis
        </p>
        <div className="flex space-x-10">
          {/* {!loggedIn && <Button linkTo={PATH.AUTH_REGISTER}>Register</Button>} */}
          <button onClick={() => history.push(PATH.VIDEO)} type="button" className="flex items-center">
            <div className="mr-2 rounded-full p-1 text-2xl text-white bg-secondary">
              <MdPlayArrow />
            </div>
            <span className="font-bold">Mulai Belajar Sekarang</span>
          </button>
        </div>
      </div>
      <Banner.ImageTemp className="hidden xl:block h-96 pb-10" />
    </div>
  );
};

Banner.ImageTemp = ({ className }) => {
  return (
    <div className={`w-full xl:w-3/4 ${className}`}>
      <div className="h-fit relative sm:left-1/4 xl:left-20">
        <div className="visible absolute z-20 banner-svg-top">
          {/* <svg height="490" viewBox="0 0 524 545" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="80.5" cy="512.5" r="16.5" fill="#058FCB" />
            <circle cx="70.5" cy="39.5" r="25.5" fill="#0FBDA9" />
            <circle cx="405.5" cy="78.5" r="39.5" fill="#ECD523" />
            <circle cx="484.5" cy="473.5" r="39.5" fill="#058FCB" />
            <circle cx="228" cy="477" r="8" fill="#ECD523" />
            <path
              d="M137 335.5L1 471.5M137 366.5L63.5 440M47.5 482.5L206 324M58 446L52.5 451.5"
              stroke="#ECD523"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg> */}
        </div>
        <img className="object-cover absolute xl:left-12 xl:top-10 banner-img z-10" src={piching} alt="" />
        {/* <div className="absolute z-0 left-12 xl:left-24 xl:top-10 banner-svg-bottom">
          <svg height="400" viewBox="0 0 479 469" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="232.84" cy="236.16" r="232.84" fill="#FA7821" />
            <circle cx="245.84" cy="233.16" r="231.84" stroke="#058FCB" strokeWidth="2" />
          </svg>
        </div> */}
      </div>
    </div>
  );
};
