export const InputCheckbox = ({ label, value, onChange, checked }) => {
  return (
    <label className="cursor-pointer" htmlFor={value}>
      <input
        className="mr-3 rounded-sm text-primary"
        value={value}
        id={value}
        type="checkbox"
        onChange={onChange}
        checked={checked}
      />
      <span className="text-sm">{label}</span>
    </label>
  );
};
