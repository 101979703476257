const CLIENT_WEB = 'web';
const CLIENT_TOKEN = 'aa6d8694eea3d1b52aa6e4169b0a822665b56fc4';
const GOOGLE_CLIENT_ID = '166005299972-r3522vbr1ja05tibj023ind7jc3l921u.apps.googleusercontent.com';
const GOOGLE_CLIENT_SECRET = 'K-anFrW_20BXOi05unS_9fnd';
const MARKETA_KEY = '92n9QuLfX1Z5znp1ZtTFyGHtsQcrEK84QEYbr2i7a3U=';

export const KEY = {
  CLIENT_WEB,
  CLIENT_TOKEN,
  GOOGLE_CLIENT_ID,
  GOOGLE_CLIENT_SECRET,
  MARKETA_KEY,
};
