import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import 'moment/locale/id';
import ReactGA from 'react-ga4';
import PATH from '@/routes/path';
import { StarRating } from '@/components/atoms';
import { CONSTANTS } from '@/utils';

export const SideCard = ({
  uuid,
  index,
  thumbnail,
  title,
  rating = 0,
  view = null,
  type,
  date,
  menu,
  categoriesName,
}) => {
  const DEFAULT_SRC = 'https://www.bastiaanmulder.nl/wp-content/uploads/2013/11/dummy-image-square.jpg';
  moment.locale('id');

  const [isLoading, setIsLoading] = useState(true);
  const [duration, setDuration] = useState('');

  const handleBrokenImage = (e) => {
    e.target.src = DEFAULT_SRC;
  };

  const handleAnalytics = () => {
    const item = {
      item_id: uuid,
      item_name: title,
      index: index + 1,
    };

    categoriesName.forEach((el, idx) => {
      if (idx === 0) item.item_category = el;
      else if (idx < 5) item[`item_category${idx + 1}`] = el;
    });

    let list = '';
    if (menu === 'terkait') {
      list = 'Related Video';
    } else if (menu === 'new') {
      list = 'New Video';
    } else if (menu === 'course') {
      list = 'Side Menu Course';
    }

    const data = {
      item_list_name: list,
      items: [item],
    };

    ReactGA.event('select_item', data);
  };

  useEffect(() => {
    if (date) {
      setDuration(moment(date).fromNow());
    }
  }, [date]);

  return (
    <Link
      to={`${type === CONSTANTS.PRODUCT_TYPE.VIDEO ? PATH.VIDEO : PATH.COURSE}/${uuid}`}
      onClick={handleAnalytics}
      className="flex mb-5"
    >
      <div className="h-fit w-1/3 flex items-center rounded-md overflow-hidden relative bg-primary-200">
        {isLoading && <Skeleton className="absolute left-0 top-0 w-full h-60" />}
        <img
          className="w-full h-full max-h-28 object-contain"
          src={thumbnail || DEFAULT_SRC}
          onError={handleBrokenImage}
          onLoad={() => setIsLoading(false)}
          alt=""
        />
      </div>
      <div className="w-2/3 pl-3">
        <h1 className="font-semibold text-primary-800 md:text-base text-sm">{title}</h1>
        <div className="mt-2 flex flex-col space-y-2">
          <div className="mr-3">
            <StarRating value={rating} />
          </div>
          <div className="flex flex-col md:block text-sm text-gray-400 md:space-x-3">
            {view !== null && (
              <span>
                <strong className="text-gray-500">{view}</strong> Penonton
              </span>
            )}
            <span>{duration}</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

SideCard.Skeleton = () => {
  return (
    <div>
      <Skeleton height={220} />
      <div>
        <Skeleton height={15} />
        <Skeleton width={120} height={15} />
      </div>
      <div>
        <Skeleton height={15} count={2} />
      </div>
    </div>
  );
};
