import Skeleton from 'react-loading-skeleton';
import { CONSTANTS, HELPERS } from '@/utils';
import { VideoPlayer } from '../VideoPlayer/VideoPlayer';
import { Quiz } from './Quiz';
import { WorkingPaper } from './WorkingPaper';

export const ProductResource = ({
  productType,
  productUuid,
  videoUuid,
  thumbnail,
  activeTopic,
  isLoading,
  onVideoEnd,
  className,
}) => {
  return (
    <div className={`overflow-hidden ${className}`}>
      {(isLoading || (isLoading && !activeTopic)) && <ProductResource.Skeleton />}
      {!isLoading && !activeTopic && (
        <div className="relative flex items-center justify-center text-center h-96 text-gray-400 italic">
          {thumbnail && <img className="w-full h-full object-contain" src={thumbnail} alt="" />}
          {!thumbnail && <div>No Resource</div>}
        </div>
      )}

      {!isLoading && activeTopic && activeTopic.resource_type === CONSTANTS.RESOURCE_TYPE.EMPTY && thumbnail && (
        <div className="relative flex items-center justify-center text-center h-96 md:h-120">
          {thumbnail && (
            <img className="absolute left-0 top-0 w-full h-full object-cover" src={thumbnail} alt="" />
          )}
          <div className="text-white italic relative">Resource is Empty</div>
        </div>
      )}

      {!isLoading && activeTopic && activeTopic.resource_type === CONSTANTS.RESOURCE_TYPE.VIDEO && (
        <VideoPlayer
          thumbnail={thumbnail}
          productUuid={productUuid}
          productType={productType}
          videoUuid={videoUuid}
          activeTopic={activeTopic}
          video360URL={activeTopic.video_360_url}
          video480URL={activeTopic.video_480_url}
          video720URL={activeTopic.video_720_url}
          video1080URL={activeTopic.video_1080_url}
          onVideoEnd={onVideoEnd}
        />
      )}

      {!isLoading && activeTopic && activeTopic.resource_type === CONSTANTS.RESOURCE_TYPE.QUIZ && (
        <Quiz
          className="p-6"
          title={activeTopic.title}
          isSubmitted={activeTopic.is_submitted}
          isCompleted={activeTopic.is_completed}
          testedCount={activeTopic.tested_count}
          uuid={activeTopic.uuid}
          questions={activeTopic.is_random ? HELPERS.shuffleArray(activeTopic.questions) : activeTopic.questions}
          onQuizEnd={onVideoEnd}
        />
      )}

      {!isLoading && activeTopic && activeTopic.resource_type === CONSTANTS.RESOURCE_TYPE.WORKING_PAPER && (
        <WorkingPaper
          className="p-6"
          title={activeTopic.title}
          description={activeTopic.description}
          fileURL={activeTopic.file_url}
          submissionStatus={activeTopic.is_submitted}
          uuid={activeTopic.uuid}
          productUuid={productUuid}
          productType={productType}
          onWorkEnd={onVideoEnd}
        />
      )}
    </div>
  );
};

ProductResource.Skeleton = () => {
  return (
    <div className="p-3">
      <Skeleton style={{ lineHeight: 'inherit' }} height={450} />
    </div>
  );
};
