import store from 'store';
import { CONSTANTS } from '@/utils';
import { http } from './http';

const BASE_URL = '/working_paper';

export const SERVICE_WORKINGPAPER = {
  getWorkingPaper: (uuid, callback) => {
    const config = { headers: { Authorization: `Bearer ${store.get(CONSTANTS.PROFILE_TOKEN)}` } };

    http
      .get(`${BASE_URL}/${uuid}/submissions`, config)
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },
  submitWorkingPaper: (uuid, formData, callback) => {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${store.get(CONSTANTS.PROFILE_TOKEN)}`,
      },
    };

    http
      .post(`${BASE_URL}/${uuid}`, formData, config)
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },
};
