import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FiUpload } from 'react-icons/fi';
import parse from 'html-react-parser';
import { Button, InputFeedback } from '@/components/atoms';
import { ACTION_COURSES, ACTION_VIDEOS, ACTION_WORKINGPAPER } from '@/redux/actions';
import { notify } from 'react-notify-toast';
import Skeleton from 'react-loading-skeleton';
import { CONSTANTS } from '@/utils';

export const WorkingPaper = ({
  uuid,
  className,
  fileURL,
  submissionStatus,
  title,
  description,
  productUuid,
  productType,
  onWorkEnd,
}) => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.workingPaper.isLoading);
  const isUploadingFile = useSelector((state) => state.workingPaper.isUploadingFile);
  const submissionData = useSelector((state) => state.workingPaper.submissionData);

  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(submissionStatus);
  const [error, setError] = useState(null);

  const VALID_ATTACHMENT_FILES = ['pdf', 'jpg', 'jpeg', 'png', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip'];
  const MAX_ATTACHMENT_SIZE = 100; // in MB

  const handleReattemptWorkingPaper = () => setIsSubmitted(false);

  const handleGetSubmissionStatus = () => {
    dispatch(ACTION_WORKINGPAPER.getWorkingPaper(uuid));
  };

  const handleChangeDocument = (e) => {
    const file = e.target.files[0];

    if (file) {
      const mediaExtension = file.name.split('.').pop().toLowerCase();

      setSelectedDocument(file);

      if (!VALID_ATTACHMENT_FILES.includes(mediaExtension)) setError('Attachment extension is not supported');
      else if (file.size > MAX_ATTACHMENT_SIZE * 1000000) {
        setError(`Attachment file must be less than ${MAX_ATTACHMENT_SIZE}MB`);
      } else {
        setError(null);
      }
    } else {
      setSelectedDocument(null);
      setError('Attachment is required!');
    }
  };

  const handleSubmitSubmission = () => {
    if (selectedDocument) {
      if (!error) {
        const formData = new FormData();

        formData.append('file', selectedDocument);

        dispatch(
          ACTION_WORKINGPAPER.submitWorkingPaper(uuid, formData, (successMessage) => {
            if (productType === CONSTANTS.PRODUCT_TYPE.VIDEO) {
              dispatch(ACTION_VIDEOS.getVideoDetailData(productUuid));
            } else if (productType === CONSTANTS.PRODUCT_TYPE.COURSE) {
              dispatch(ACTION_COURSES.getCourseDetailData(productUuid));
            }
            if (onWorkEnd) onWorkEnd();

            notify.show(successMessage, 'success');
          })
        );
      }
    } else setError('Attachment is required!');
  };

  useEffect(() => {
    if (isSubmitted) handleGetSubmissionStatus();
  }, []);

  return (
    <div className={`${className}`}>
      {!isSubmitted && (
        <div className="space-y-6">
          <div className="font-semibold">{title}</div>
          {(description || fileURL) && (
            <div className="space-y-6 p-3 bg-tertiary-100 border border-primary rounded-md">
              <div className="font-semibold">Deskripsi</div>
              {description && <div className="text-sm">{parse(description)}</div>}
              {fileURL && (
                <div className="text-sm space-x-3">
                  <span className="font-semibold">Template {title}</span>
                  <a
                    className="font-semibold underline text-primary"
                    href={fileURL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download
                  </a>
                </div>
              )}
            </div>
          )}
          <div className="space-y-3">
            <div className="font-semibold">Unggah Dokumen</div>
            <div className="text-sm">
              Format file harus (
              <span className="uppercase">{VALID_ATTACHMENT_FILES.map((ext) => `.${ext}`).join(', ')}</span>) dan
              ukuran file tidak lebih dari {MAX_ATTACHMENT_SIZE} MB
            </div>
            <label
              className="space-y-6 cursor-pointer bg-gray-100 border rounded-md py-6 px-10 inline-flex flex-col items-center justify-center"
              htmlFor="file"
            >
              <input
                className="hidden"
                type="file"
                id="file"
                accept={VALID_ATTACHMENT_FILES.map((ext) => `.${ext} `).join(',')}
                onChange={handleChangeDocument}
              />
              <FiUpload className="text-gray-500" size={24} />
              {selectedDocument && <div className="text-sm">{selectedDocument.name}</div>}
              <div className="text-blue-500 underline text-sm">Klik untuk memilih file</div>
            </label>
            {error && <InputFeedback type={InputFeedback.TYPES.ERROR} message={error} />}
          </div>
          <hr />
          <div className="flex justify-end">
            <Button loading={isUploadingFile} onClick={handleSubmitSubmission}>
              Kirim Tugas
            </Button>
          </div>
        </div>
      )}

      {isSubmitted && isLoading && <WorkingPaper.Skeleton />}
      {isSubmitted && !isLoading && (
        <div>
          {submissionData && (
            <div className="space-y-6">
              {submissionData.status === CONSTANTS.WORKING_PAPER_STATUS.WAITING && (
                <div className="flex flex-col items-center space-y-6 py-28">
                  <img className="w-24" src={require('@/images/icons/assessment_process.png').default} alt="" />
                  <p className="text-sm text-gray-400">Menunggu penilaian...</p>
                </div>
              )}

              {submissionData.status === CONSTANTS.WORKING_PAPER_STATUS.GRADED && (
                <>
                  <div className="flex flex-col items-center py-10">
                    {!!submissionData.is_passed && (
                      <img className="w-32" src={require('@/images/icons/quiz_success.png').default} alt="" />
                    )}
                    {!submissionData.is_passed && (
                      <img className="w-32" src={require('@/images/icons/quiz_failed.png').default} alt="" />
                    )}
                    <div className="space-y-2 text-center flex flex-col items-center">
                      <div className="font-semibold">Skor Kertas Kerja Anda :</div>
                      <div
                        className={`text-5xl font-semibold ${
                          submissionData.is_passed ? 'text-primary' : 'text-red-500'
                        }`}
                      >
                        {submissionData.score}
                      </div>

                      {!submissionData.is_passed && (
                        <div className="text-sm w-8/12">
                          Sayang sekali Anda belum dinyatakan lulus dari Kuis ini. Namun jangan khawatir, Anda bisa
                          mengulang kembali materi dan kuisnya. Semangat!
                        </div>
                      )}
                      {submissionData.feedback && (
                        <div className="text-sm w-8/12">
                          <span className="font-semibold">Masukan Penilai : </span>
                          {submissionData.feedback}
                        </div>
                      )}
                    </div>
                  </div>
                  {!submissionData.is_passed && (
                    <div className="w-full border-t border-gray-200 mt-6 pt-6 flex justify-center items-center">
                      <Button onClick={handleReattemptWorkingPaper}>Ulang Kertas Kerja</Button>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

WorkingPaper.Skeleton = () => {
  return (
    <div>
      <Skeleton className="mb-3" width={200} />
      <Skeleton className="mb-1" height={100} />
      <Skeleton className="mb-1" height={30} />
      <Skeleton className="mb-1" width={250} height={150} />
    </div>
  );
};
