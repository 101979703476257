import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import parse, { domToReact, attributesToProps } from 'html-react-parser';
import ReactGA from 'react-ga4';
import { ACTION_COURSES } from '@/redux/actions';
import { CONSTANTS } from '@/utils';
import { Button, Container, SkyduHelmet, StarRating } from '@/components/atoms';
import { Comment, ProductResource, ShareModal, SideMenu } from '@/components/molecules';
import { notify } from 'react-notify-toast';
import { FaShare, FaEllipsisH } from 'react-icons/fa';
import moment from 'moment';
import PATH from '../path';

const CourseDetail = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const base64 = require('uuid-base64');
  const history = useHistory();

  // useSelector Group
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const data = useSelector((state) => state.courses.detailData);
  const isLoading = useSelector((state) => state.courses.detailLoading);
  const ratingData = useSelector((state) => state.courses.reviewsData);
  const enrollCourseLoading = useSelector((state) => state.courses.enrollCourseLoading);

  let uuid;
  const titleParams = params.title;
  if (titleParams) {
    uuid = base64.decode(params.uid);
  } else {
    uuid = params.uid;
  }
  const idLocale = require('moment/locale/id');

  // useState Group
  const [chapters, setChapters] = useState(null);
  const [activeChapter, setActiveChapter] = useState([]);
  const [activeTopic, setActiveTopic] = useState(null);
  const [isEnd, setIsEnd] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [categories, setCategories] = useState('');
  const [categoriesName, setCategoriesName] = useState([]);
  // Share
  const [isOpenShareModal, setIsOpenShareModal] = useState(false);
  // rating
  const [rating, setRating] = useState(0);
  // description
  const [descHeight, setDescHeight] = useState('');
  const [readMore, setReadMore] = useState(false);

  // Function Group
  const handleCloseModal = () => {
    setIsOpenShareModal(false);
  };

  const handleEndCourse = () => {
    setTimeout(() => {
      dispatch(ACTION_COURSES.getCourseDetailData(uuid));
      if (loggedIn && chapters.length > 0) {
        let unfinished = 0;
        chapters.forEach((chapter) => {
          chapter.sections.forEach((topic) => {
            if (topic.resource_type !== CONSTANTS.RESOURCE_TYPE.WORKING_PAPER && !topic.is_completed) {
              unfinished += 1;
            }
            if (topic.resource_type === CONSTANTS.RESOURCE_TYPE.WORKING_PAPER && !topic.is_graded) {
              unfinished += 1;
            }
          });
        });
        if (unfinished === 0) setIsCompleted(true);
      }
    }, 5000);
  };

  const handleChangeActiveChapter = (chapter) => {
    if (activeChapter.includes(chapter.uuid)) activeChapter.splice(activeChapter.indexOf(chapter.uuid), 1);
    else activeChapter.push(chapter.uuid);
    setActiveChapter([...activeChapter]);
  };

  const getIndex = (chapter, topic) => {
    const temp = {};

    if (topic === null) {
      temp.currentChapter = -1;
      temp.currentTopic = -1;
    } else {
      temp.currentChapter = chapters.map((el) => el.uuid).indexOf(chapter.uuid);
      temp.currentTopic = chapters[temp.currentChapter].sections.map((el) => el.uuid).indexOf(topic.uuid);
    }

    // temp.idPrevChapter = temp.currentChapter - 1 < 0 ? null : temp.currentChapter - 1;
    temp.idNextChapter = temp.currentChapter + 1 >= chapters.length ? null : temp.currentChapter + 1;
    // let idPrevTopic = null;
    let idNextTopic = null;

    if (temp.currentChapter > -1) {
      if (temp.currentTopic + 1 >= chapters[temp.currentChapter].sections.length && temp.idNextChapter === null) {
        idNextTopic = null;
      } else if (
        temp.currentTopic + 1 >= chapters[temp.currentChapter].sections.length &&
        temp.idNextChapter !== null
      ) {
        idNextTopic = 0;
      } else if (temp.currentTopic + 1 < chapters[temp.currentChapter].sections.length) {
        idNextTopic = temp.currentTopic + 1;
      }
    } else {
      idNextTopic = 0;
    }

    // if (temp.currentTopic - 1 < 0 && temp.idPrevChapter === null) {
    //   idPrevTopic = null;
    // } else if (temp.currentTopic - 1 < 0 && temp.idPrevChapter !== null) {
    //   idPrevTopic = chapters[temp.idPrevChapter].sections.length - 1;
    // } else if (temp.currentTopic - 1 >= 0) {
    //   idPrevTopic = temp.currentTopic - 1;
    // }

    // temp.idPrevTopic = idPrevTopic;
    temp.idNextTopic = idNextTopic;

    return temp;
  };

  const onChangeActiveTopic = (chapter, topic) => {
    console.log('simpen chapter', chapter);
    if (topic.uuid !== 1) {
      chapters.forEach((chap) => {
        const pickTopic = chap.sections.find((el) => el.uuid === topic.uuid);
        if (pickTopic) {
          setActiveTopic(pickTopic);
        }
      });
    } else {
      setActiveTopic(null);
    }
  };

  const onNextVideo = () => {
    const currentChapter = activeTopic === null ? null : chapters.find((el) => el.sections.includes(activeTopic));
    const index = getIndex(currentChapter, activeTopic);
    if (index.idNextTopic === 0) {
      const nextTopic = chapters[index.idNextChapter].sections[index.idNextTopic];
      setActiveChapter([chapters[index.idNextChapter].uuid]);
      setActiveTopic(nextTopic);
    } else {
      const nextTopic = chapters[index.currentChapter].sections[index.idNextTopic];
      if (!nextTopic.is_locked) setActiveTopic(nextTopic);
      else notify.show('Selesaikan menonton video terlebih dahulu', 'warning');
    }
  };

  const handleEnrollCourse = () => {
    if (loggedIn) {
      dispatch(
        ACTION_COURSES.enrollCourse(uuid, (message) => {
          notify.show(message, 'success');
          dispatch(ACTION_COURSES.getCourseDetailData(uuid));
        })
      );
    } else {
      notify.show('Anda harus login terlebih dahulu', 'warning');
      history.push(PATH.AUTH_LOGIN);
    }
  };

  // useEffect Group
  useEffect(() => {
    setActiveChapter([]);
    setActiveTopic(null);
  }, []);

  useEffect(() => {
    setActiveChapter([]);
    setActiveTopic(null);
    dispatch(ACTION_COURSES.getCourseDetailData(uuid));
    dispatch(ACTION_COURSES.getCourseReviewsData(uuid));
  }, [uuid, loggedIn]);

  useEffect(() => {
    if (data) {
      ReactGA.initialize(process.env.REACT_APP_GA_ID);
      ReactGA.send('pageview');

      const pretest = data.chapters.find((chapter) => {
        const title = chapter.title.toLowerCase();
        if (title.split(' ').includes('pretest')) {
          return true;
        }
        return false;
      });
      const otherChapter = data.chapters.filter((chapter) => {
        const title = chapter.title.toLowerCase();
        if (!title.split(' ').includes('pretest')) {
          return true;
        }
        return false;
      });
      if (pretest && pretest.sections.length > 0) {
        setChapters([pretest, ...otherChapter]);
      } else {
        setChapters([...otherChapter]);
      }
      setCategories(data.categories.map((el) => el.id).join(','));
      setCategoriesName(data.categories.map((el) => el.name));
      setIsCompleted(data.is_completed);
      if (activeTopic) {
        data.chapters.forEach((chapter) => {
          chapter.sections.forEach((section) => {
            if (activeTopic.uuid === section.uuid) {
              setActiveTopic(section);
            }
          });
        });
      }
    } else {
      setChapters(null);
    }
  }, [data]);

  useEffect(() => {
    if (ratingData) {
      if (ratingData.length > 0) {
        const totalRating = ratingData.reduce((a, b) => a + b.rating, 0);
        setRating(totalRating / ratingData.length);
      } else {
        setRating(0);
      }
    }
  }, [ratingData]);

  useEffect(() => {
    if (readMore) {
      setDescHeight('');
    } else {
      setDescHeight('h-16');
    }
  }, [readMore]);

  useEffect(() => {
    if (chapters) {
      const lastTopic = chapters[chapters.length - 1].sections[chapters[chapters.length - 1].sections.length - 1];
      if (activeTopic && activeTopic.uuid === lastTopic.uuid) {
        setIsEnd(true);
      } else {
        setIsEnd(false);
      }
    }
  }, [chapters, activeTopic]);

  // parser styling
  const options = {
    replace: ({ name, children, attribs }) => {
      if (name === 'ol') {
        return <div className="pl-5 list-decimal">{domToReact(children, options)}</div>;
      }
      if (name === 'ul') {
        return <div className="pl-5 list-disc">{domToReact(children, options)}</div>;
      }
      if (name === 'h1') {
        return <h1 className="text-2xl">{domToReact(children, options)}</h1>;
      }
      if (name === 'h2') {
        return <h2 className="text-xl">{domToReact(children, options)}</h2>;
      }
      if (name === 'h3') {
        return <h3 className="text-lg">{domToReact(children, options)}</h3>;
      }
      if (name === 'h4') {
        return <h4 className="text-base">{domToReact(children, options)}</h4>;
      }
      if (name === 'h5') {
        return <h5 className="text-sm">{domToReact(children, options)}</h5>;
      }
      if (name === 'h6') {
        return <h6 className="text-xs">{domToReact(children, options)}</h6>;
      }
      if (name === 'hr') {
        return <hr className="border-gray-500" />;
      }
      if (name === 'a') {
        const props = attributesToProps(attribs);
        return (
          <a {...props} className="underline text-primary">
            {domToReact(children, options)}
          </a>
        );
      }
      if (name === 'table') {
        const props = attributesToProps(attribs);
        return (
          <table {...props} className="table border-collapse">
            {domToReact(children, options)}
          </table>
        );
      }
      if (name === 'caption') {
        return <caption className="font-bold">{domToReact(children, options)}</caption>;
      }
      if (name === 'td') {
        return <td className="border border-gray-500">{domToReact(children, options)}</td>;
      }
      return null;
    },
  };

  return (
    <div className="pt-0 pb-5 bg-gray-100 lg:pt-5">
      <SkyduHelmet
        title={data && `Tumbu - ${data.title}`}
        description={data && data.description}
        thumbnail={data && data.thumbnail}
        url={window.location.href}
      />

      <ShareModal courseUuid={uuid} open={isOpenShareModal} onClose={handleCloseModal} />

      <Container className="relative px-0 lg:flex lg:px-4">
        <div className="w-full mb-5 lg:w-7/12 lg:pr-5">
          <ProductResource
            productUuid={uuid}
            productType={CONSTANTS.PRODUCT_TYPE.COURSE}
            thumbnail={data && data.thumbnail}
            isLoading={isLoading}
            activeTopic={activeTopic}
            onVideoEnd={handleEndCourse}
          />
          <div className="px-5">
            {activeTopic &&
              activeTopic.resource_type !== CONSTANTS.RESOURCE_TYPE.QUIZ &&
              activeTopic.resource_type !== CONSTANTS.RESOURCE_TYPE.WORKING_PAPER && (
                <div className="pb-5 border-b border-gray-400">
                  <h1 className="my-3 text-base font-medium sm:text-xl">{activeTopic.title}</h1>
                  <div className="flex justify-between">
                    <div className="flex items-center space-x-2 text-xs sm:space-x-3 sm:text-base">
                      <span>
                        {activeTopic && activeTopic.total_view ? activeTopic.total_view : 0}
                        &nbsp; Penonton
                      </span>
                      <span>{moment(activeTopic.date).locale('id', idLocale).format('D MMMM YYYY')}</span>
                      <div className="hidden sm:block">
                        <StarRating value={rating} />
                      </div>
                      <div className="block sm:hidden">
                        <StarRating value={rating} size={12} />
                      </div>
                    </div>
                    <div className="flex mr-0 space-x-3 sm:space-x-5 md:mr-3">
                      <button
                        type="button"
                        className="flex items-center space-x-2 text-gray-500 hover:text-black"
                        onClick={() => setIsOpenShareModal(true)}
                      >
                        <FaShare />
                        <span className="hidden md:block">SHARE</span>
                      </button>
                      <button type="button" className="mr-3 space-x-2 text-gray-500 hover:text-black">
                        <FaEllipsisH />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            {data && !data.is_locked && (
              <div className="flex flex-wrap items-end justify-center space-x-3 space-y-3 sm:mt-5 sm:space-y-0">
                {!isEnd && <Button onClick={onNextVideo}>Video Selanjutnya</Button>}
                <Button linkTo={`${PATH.CERTIFICATE}/${uuid}`} disabled={!isCompleted}>
                  Lihat Sertifikat
                </Button>
              </div>
            )}
            {data && data.is_locked && (
              <div className="flex flex-wrap items-end justify-center space-x-3 space-y-3 sm:mt-5 sm:space-y-0">
                <Button onClick={handleEnrollCourse} loading={enrollCourseLoading}>
                  Mulai Sekarang
                </Button>
              </div>
            )}
            {activeTopic === null ||
            (activeTopic &&
              activeTopic.resource_type !== CONSTANTS.RESOURCE_TYPE.QUIZ &&
              activeTopic.resource_type !== CONSTANTS.RESOURCE_TYPE.WORKING_PAPER) ? (
              <div className="py-5 border-b border-gray-400">
                <div className={`${descHeight} overflow-hidden`}>
                  {activeTopic !== null && (
                    <div className="mb-3 space-y-3">
                      <p className="mb-3 font-bold">Deskripsi Video</p>
                      {parse(activeTopic.description, options)}
                    </div>
                  )}
                  {data && (
                    <div className="space-y-3">
                      <p className="mb-3 font-bold">Deskripsi Course</p>
                      {parse(data?.description, options)}
                    </div>
                  )}
                </div>
                {data && (
                  <>
                    {readMore ? (
                      <button
                        type="button"
                        className="mt-3 text-gray-500 hover:text-black"
                        onClick={() => setReadMore(false)}
                      >
                        SHOW LESS
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="mt-3 text-gray-500 hover:text-black"
                        onClick={() => setReadMore(true)}
                      >
                        SHOW MORE
                      </button>
                    )}
                  </>
                )}
              </div>
            ) : (
              ''
            )}
            {activeTopic &&
              loggedIn &&
              activeTopic.resource_type !== CONSTANTS.RESOURCE_TYPE.QUIZ &&
              activeTopic.resource_type !== CONSTANTS.RESOURCE_TYPE.WORKING_PAPER && (
                <Comment uuid={uuid} productType={CONSTANTS.PRODUCT_TYPE.COURSE} />
              )}
          </div>
        </div>
        <SideMenu
          type={CONSTANTS.PRODUCT_TYPE.COURSE}
          courseName={data && data.title}
          isLoading={isLoading}
          chapters={chapters}
          activeChapter={activeChapter}
          activeTopic={activeTopic}
          isCourseLocked={data && data.is_locked}
          onChangeActiveChapter={handleChangeActiveChapter}
          // onChangeActiveTopic={(topic) => setActiveTopic(topic)}
          onChangeActiveTopic={onChangeActiveTopic}
          categories={categories}
          categoriesName={categoriesName}
        />
      </Container>
    </div>
  );
};

export default CourseDetail;
