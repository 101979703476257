import { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

export const ProfilePagination = ({ itemLength, activePage, setActivePage, perPage }) => {
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    setPageCount(Math.ceil(itemLength / perPage));
  }, [itemOffset, activePage, perPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * perPage) % itemLength;
    setActivePage(event.selected + 1);
    setItemOffset(newOffset);
  };

  return (
    <div>
      <ReactPaginate
        breakLabel="..."
        renderOnZeroPageCount={null}
        marginPagesDisplayed={2}
        pageRangeDisplayed={1}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        initialPage={activePage - 1}
        className="flex items-center"
        pageLinkClassName="flex items-center justify-center border border-gray-300 w-5 h-5 p-4 text-sm hover:bg-primary-100"
        breakLinkClassName="flex items-center justify-center border border-gray-300 w-5 h-5 p-4 text-sm hover:bg-primary-100"
        previousLinkClassName="flex items-center justify-center border border-gray-300 w-full h-5 px-4 py-4 text-sm hover:bg-primary-100 rounded-l-sm"
        nextLinkClassName="flex items-center justify-center border border-gray-300 w-full h-5 px-4 py-4 text-sm hover:bg-primary-100 rounded-r-sm"
        activeClassName="bg-primary text-white"
        previousLabel={<FiChevronLeft />}
        nextLabel={<FiChevronRight />}
      />
    </div>
  );
};
