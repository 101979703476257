export const TYPES_COURSES = {
  GET_COURSES_DATA: 'GET_COURSES_DATA',
  SET_COURSES_LOADING: 'SET_COURSES_LOADING',
  GET_DETAIL_COURSES_DATA: 'GET_DETAIL_COURSES_DATA',
  SET_DETAIL_COURSES_LOADING: 'SET_DETAIL_COURSES_LOADING',
  GET_LIST_COURSES_LOADMORE_DATA: 'GET_LIST_COURSES_LOADMORE_DATA',
  SET_LIST_COURSES_LOADMORE_LOADING: 'SET_LIST_COURSES_LOADMORE_LOADING',
  SET_COURSE_RATING_IS_SUBMITTING: 'SET_COURSE_RATING_IS_SUBMITTING',
  SET_COURSE_RATING_LOADING: 'SET_COURSE_RATING_LOADING',
  GET_COURSE_RATING_DATA: 'GET_COURSE_RATING_DATA',
  SET_COURSE_REVIEWS_LOADING: 'SET_COURSE_REVIEWS_LOADING',
  GET_COURSE_REVIEWS_DATA: 'GET_COURSE_REVIEWS_DATA',
  SET_ENROLL_COURSE_LOADING: 'SET_ENROLL_COURSE_LOADING',
  SET_COURSE_CERTIFICATE_LOADING: 'SET_COURSE_CERTIFICATE_LOADING',
  GET_COURSE_CERTIFICATE_DATA: 'GET_COURSE_CERTIFICATE_DATA',
};
