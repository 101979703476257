import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ACTION_ACTIVITY } from '@/redux/actions';
import { Button, Container } from '@/components/atoms';
import EnvelopeIcon from '@/images/icons/envelope.png';
import PATH from '../path';

const EmailSent = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!location.state) history.push(PATH.HOME);
    dispatch(ACTION_ACTIVITY.showFooter(false));
    dispatch(ACTION_ACTIVITY.showHeader(false));

    return () => {
      dispatch(ACTION_ACTIVITY.showFooter(true));
      dispatch(ACTION_ACTIVITY.showHeader(true));
    };
  }, []);

  return (
    <div className="bg-primary-100 min-h-screen flex items-center">
      <Container className="max-w-screen-sm py-40">
        <div className="flex flex-col items-center">
          <div className="flex flex-col items-center text-center space-y-6 mb-12">
            <img className="w-44" src={EnvelopeIcon} alt="" />
            <h1 className="text-2xl font-semibold text-primary-600">Berhasil Kirim!</h1>
            <p>Link pengaturan ulang kata sandi berhasil dikirim ke email Anda. Silahkan cek email Anda.</p>
          </div>
          <Button linkTo={PATH.HOME}>Kembali Ke Beranda</Button>
        </div>
      </Container>
    </div>
  );
};

export default EmailSent;
