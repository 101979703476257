export const TYPES_VIDEOS = {
  SET_LIST_VIDEOS_LOADING: 'SET_LIST_VIDEOS_LOADING',
  SET_LIST_VIDEOS_LOADMORE_LOADING: 'SET_LIST_VIDEOS_LOADMORE_LOADING',
  GET_LIST_VIDEOS_DATA_TERKAIT: 'GET_LIST_VIDEOS_DATA_TERKAIT',
  GET_LIST_VIDEOS_DATA: 'GET_LIST_VIDEOS_DATA',
  GET_LIST_VIDEOS_LOADMORE_DATA: 'GET_LIST_VIDEOS_LOADMORE_DATA',
  SET_DETAIL_VIDEOS_LOADING: 'SET_DETAIL_VIDEOS_LOADING',
  GET_DETAIL_VIDEOS_DATA: 'GET_DETAIL_VIDEOS_DATA',
  SET_VIDEO_RATING_LOADING: 'SET_VIDEO_RATING_LOADING',
  SET_VIDEO_RATING_IS_SUBMITTING: 'SET_VIDEO_RATING_IS_SUBMITTING',
  GET_VIDEO_RATING_DATA: 'GET_VIDEO_RATING_DATA',
  SET_VIDEO_RATING_DATA: 'SET_VIDEO_RATING_DATA',
  SET_VIDEO_REVIEWS_LOADING: 'SET_VIDEO_REVIEWS_LOADING',
  GET_VIDEO_REVIEWS_DATA: 'SET_VIDEO_REVIEWS_DATA',
};
