// import { useState, useEffect } from 'react';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';
import ReactGA from 'react-ga4';
import { Button } from '@/components/atoms';
import { ACTION_COURSES } from '@/redux/actions';
import { HELPERS } from '@/utils';
import PATH from '@/routes/path';

export const CourseShowcase = ({ formatting }) => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.courses.listLoading);
  const courses = useSelector((state) => state.courses.listData);

  useEffect(() => {
    dispatch(ACTION_COURSES.getCourseListData(1, 4, HELPERS.queryStringToObject('?sort_by=id&order=desc')));
  }, []);

  return (
    <div className="w-screen xl:flex px-10 py-20 bg-white">
      <div className="w-full xl:w-1/2 xl:ml-20 hidden xl:block">
        {isLoading && <CourseShowcase.Skeleton />}
        {!isLoading && <CourseShowcase.Video courseList={courses} formatting={formatting} />}
      </div>
      <div className="w-full xl:w-1/2 xl:px-20 mb-10 xl:mb-0">
        <div className="xl:w-2/3">
          <p className="mb-3 font-medium text-primary tracking-wide">Siap untuk dipelajari</p>
          <h2 className="text-3xl md:text-4xl font-bold leading-normal">Kursus yang Dapat Diikuti</h2>
          <p className="mt-3 mb-10 text-gray-500">
            Ikuti kursus #YukKitaTumbuh di sini, dan dapatkan benefit coaching bersama tutor berpengalaman di
            bidangnya - daftar dan pelajari sekarang juga!
          </p>
          <Button linkTo={PATH.COURSE}>See all</Button>
        </div>
      </div>
      <div className="w-full xl:w-1/2 xl:ml-20 block xl:hidden">
        {isLoading && <CourseShowcase.Skeleton />}
        {!isLoading && <CourseShowcase.Video courseList={courses} formatting={formatting} />}
      </div>
    </div>
  );
};

CourseShowcase.Video = ({ className, courseList, formatting }) => {
  const history = useHistory();

  const shortDesc = (desc) => {
    let temp = desc.split(' ').slice(0, 8).join(' ');
    if (!temp.includes('</p>')) {
      temp += '...</p>';
    }
    return temp;
  };

  const handleAnalytics = (course, index) => {
    const item = {
      item_id: course.uuid,
      item_name: course.title,
      index: index + 1,
    };

    const category = course.categories.map((el) => el.name);
    category.forEach((el, idx) => {
      if (idx === 0) item.item_category = el;
      else if (idx < 5) item[`item_category${idx + 1}`] = el;
    });

    const data = {
      item_list_name: 'Recommendation Course',
      items: [item],
    };

    ReactGA.event('select_item', data);
    history.push(`${PATH.COURSE}/${course.uuid}`);
  };

  return (
    <div className={`grid grid-cols-1 md:grid-cols-2 grid-rows-2 gap-6 ${className}`}>
      {courseList &&
        courseList.slice(0, 4).map((course, idx) => (
          <button
            onClick={() => handleAnalytics(course, idx)}
            key={course.uuid}
            type="button"
            className="flex flex-col rounded-xl overflow-hidden shadow-lg border"
          >
            <div className="relative h-56 w-full bg-primary-200">
              <img className="h-full w-full absolute object-contain" src={course.thumbnail} alt="" />
            </div>
            <div className="pt-3 py-5 px-5">
              <h4 className="mb-3 font-bold text-lg text-left">{course.title}</h4>
              <div className="flex justify-between space-x-3 mb-3">
                <span className="text-sm text-gray-500">
                  <span className="font-bold">{course.total_videos}</span> Video
                </span>
                <span className="text-sm text-gray-500">
                  <span className="font-bold">{formatting(course.total_views)}</span> Views
                </span>
              </div>
              <span className="text-sm text-left">{parse(shortDesc(course.description))}</span>
            </div>
          </button>
        ))}
    </div>
  );
};

CourseShowcase.Skeleton = ({ className }) => {
  return (
    <div className={`grid grid-cols-1 md:grid-cols-2 grid-rows-6 gap-6 ${className}`}>
      <Skeleton height={320} />
      <Skeleton height={320} />
      <Skeleton height={320} />
      <Skeleton height={320} />
    </div>
  );
};
