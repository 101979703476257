import moment from 'moment';
import 'moment/locale/id';
import { StarRating } from '@/components/atoms';
import Avatar from '@/images/avatar/avatar.png';
import { useEffect, useState } from 'react';

export const CommentCard = ({ name, body, date, rating }) => {
  moment.locale('id');

  const [duration, setDuration] = useState('');

  useEffect(() => {
    if (date) {
      setDuration(moment(date).fromNow());
    }
  }, [date]);

  return (
    <div className="my-5 flex space-x-5">
      <img className="rounded-full sm:w-16 w-12 object-contain" src={Avatar} alt="Avatar" />
      <div className="space-y-1">
        <div className="flex space-x-5">
          <span className="font-medium">{name}</span>
          <StarRating value={rating} />
          <span className="text-gray-500">{duration}</span>
        </div>
        <p>{body}</p>
      </div>
    </div>
  );
};
