export const TYPES_PROFILE = {
  GET_LEARNING_DURATION: 'GET_LEARNING_DURATION',
  GET_ONGOING_VIDEOS: 'GET_ONGOING_VIDEOS',
  SET_ONGOING_VIDEOS_LOADING: 'SET_ONGOING_VIDEOS_LOADING',
  GET_COMPLETED_VIDEOS: 'GET_COMPLETED_VIDEOS',
  SET_COMPLETED_VIDEOS_LOADING: 'SET_COMPLETED_VIDEOS_LOADING',
  GET_ONGOING_COURSES: 'GET_ONGOING_COURSES',
  SET_ONGOING_COURSES_LOADING: 'SET_ONGOING_COURSES_LOADING',
  GET_COMPLETED_COURSES: 'GET_COMPLETED_COURSES',
  SET_COMPLETED_COURSES_LOADING: 'SET_COMPLETED_COURSES_LOADING',
  GET_PROFILE: 'GET_PROFILE',
  GET_PROFILE_SURVEY: 'GET_PROFILE_SURVEY',
  UPDATE_PROFILE_LOADING: 'UPDATE_PROFILE_LOADING',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  UPDATE_PROFILE_SUBMITTING: 'UPDATE_PROFILE_SUBMITTING',
  DELETE_PROFILE: 'DELETE_PROFILE',
};
