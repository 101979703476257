import { SERVICE_VIDEOS } from '@/services';
import { TYPES_VIDEOS } from './videos.types';

export const ACTION_VIDEOS = {
  getVideoListData: (page, limit, queries = null, terkait = false) => {
    return (dispatch) => {
      dispatch({ type: TYPES_VIDEOS.SET_LIST_VIDEOS_LOADING });

      SERVICE_VIDEOS.getVideoListData(page, limit, queries, (res) => {
        dispatch({ type: TYPES_VIDEOS.SET_LIST_VIDEOS_LOADMORE_LOADING, loading: false });
        if (terkait) {
          dispatch({
            type: TYPES_VIDEOS.GET_LIST_VIDEOS_DATA_TERKAIT,
            payload: res.payload || [],
            total: res.total,
            totalPage: res.totalPage,
            error: res.error,
          });
        } else {
          dispatch({
            type: TYPES_VIDEOS.GET_LIST_VIDEOS_DATA,
            payload: res.payload || [],
            total: res.total,
            totalPage: res.totalPage,
            error: res.error,
          });
        }
      });
    };
  },

  getVideoListLoadMoreData: (page, limit, queries = null) => {
    return (dispatch) => {
      dispatch({ type: TYPES_VIDEOS.SET_LIST_VIDEOS_LOADMORE_LOADING, loading: true });

      SERVICE_VIDEOS.getVideoListData(page, limit, queries, (res) => {
        dispatch({ type: TYPES_VIDEOS.SET_LIST_VIDEOS_LOADMORE_LOADING, loading: false });
        dispatch({
          type: TYPES_VIDEOS.GET_LIST_VIDEOS_LOADMORE_DATA,
          payload: res.payload || [],
          total: res.total,
          error: res.error,
        });
      });
    };
  },

  getVideoDetailData: (uid) => {
    return (dispatch) => {
      dispatch({ type: TYPES_VIDEOS.SET_DETAIL_VIDEOS_LOADING });

      SERVICE_VIDEOS.getVideoDetailData(uid, (res) => {
        dispatch({
          type: TYPES_VIDEOS.GET_DETAIL_VIDEOS_DATA,
          payload: res.payload,
          error: res.error,
        });
      });
    };
  },

  getVideoDetailRatingData: (uid) => {
    return (dispatch) => {
      dispatch({ type: TYPES_VIDEOS.SET_VIDEO_RATING_LOADING, loading: true });

      SERVICE_VIDEOS.getVideoRating(uid, (res) => {
        dispatch({ type: TYPES_VIDEOS.SET_VIDEO_RATING_LOADING, loading: false });
        dispatch({
          type: TYPES_VIDEOS.GET_VIDEO_RATING_DATA,
          payload: res.payload,
          error: res.error,
        });
      });
    };
  },

  setVideoRating: (uid, commentBody, ratingValue, callback) => {
    return (dispatch) => {
      dispatch({ type: TYPES_VIDEOS.SET_VIDEO_RATING_IS_SUBMITTING, isSubmitting: true });

      SERVICE_VIDEOS.setVideoRating(uid, commentBody, ratingValue, (res) => {
        if (callback) callback();
        dispatch({ type: TYPES_VIDEOS.SET_VIDEO_RATING_IS_SUBMITTING, isSubmitting: false });
        dispatch({
          type: TYPES_VIDEOS.SET_VIDEO_RATING_LOADING,
          payload: res.payload,
          error: res.error,
        });
      });
    };
  },

  getVideoReviewsData: (uid, callback) => {
    return (dispatch) => {
      dispatch({ type: TYPES_VIDEOS.SET_VIDEO_REVIEWS_LOADING, loading: true });

      SERVICE_VIDEOS.getVideoReviews(uid, (res) => {
        dispatch({ type: TYPES_VIDEOS.SET_VIDEO_REVIEWS_LOADING, loading: false });
        if (callback) callback(res);
        dispatch({
          type: TYPES_VIDEOS.GET_VIDEO_REVIEWS_DATA,
          payload: res.payload,
        });
      });
    };
  },

  setVideoProgress: (
    uuid,
    startedAt,
    pausedAt,
    skippedAt,
    skipPattern,
    totalDuration,
    playbackSpeed,
    finishedAt
  ) => {
    return () => {
      SERVICE_VIDEOS.setVideoProgress(
        uuid,
        startedAt,
        pausedAt,
        skippedAt,
        skipPattern,
        totalDuration,
        playbackSpeed,
        finishedAt,
        () => {}
      );
    };
  },
};
