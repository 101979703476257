import PATH from '@/routes/path';

const LOGIN_TYPE = {
  GOOGLE: 'google',
  EMAIL: 'email',
};

const PROFILE_TOKEN = 'PROFILE_TOKEN';

const PRODUCT_TYPE = {
  COURSE: {
    id: 0,
    path: PATH.COURSE,
    title: 'Kursus',
  },
  VIDEO: {
    id: 1,
    path: PATH.VIDEO,
    title: 'Video',
  },
};

const PRODUCT_FILTER = {
  KEDALAMAN: {
    id: 0,
    path: PATH.COURSE,
    title: 'Kursus',
  },
  VIDEO: {
    id: 1,
    path: PATH.VIDEO,
    title: 'Video',
  },
};

const RESOURCE_TYPE = {
  EMPTY: 0,
  VIDEO: 1,
  AUDIO: 2,
  TEXT: 3,
  QUIZ: 4,
  WORKING_PAPER: 5,
  PDF: 'PDF',
};

const LANGUAGES = {
  INDONESIAN: 'id',
  ENGLISH: 'en',
};

const PAYMENT_METHODS = {
  BANK_TRANSFER: {
    value: 'BANK_TRANSFER',
    label: 'Bank Transfer',
  },
  E_WALLET: {
    value: 'E_WALLET',
    label: 'E-Wallet',
  },
};

const POINT_HISTORY_TYPES = {
  SUBMIT_QUIZ: 1,
  REVIEW_COURSE: 2,
  REVIEW_VIDEO: 3,
  COMPLETED_COURSE: 4,
  COMPLETED_VIDEO: 5,
  SUBMIT_WORKING_PAPPER: 6,
  FINISHED_COACHING: 7,
  REQUEST_COACHING: 8,
};

const COACHING_SCHEDULE_STATUS = {
  STATUS_SUBMITED: 0,
  STATUS_FINISHED: 1,
};

const WORKING_PAPER_STATUS = {
  WAITING: 0,
  GRADED: 1,
};

export const CONSTANTS = {
  LOGIN_TYPE,
  PRODUCT_TYPE,
  PRODUCT_FILTER,
  RESOURCE_TYPE,
  LANGUAGES,
  PAYMENT_METHODS,
  PROFILE_TOKEN,
  WORKING_PAPER_STATUS,
  POINT_HISTORY_TYPES,
  COACHING_SCHEDULE_STATUS,
};
