import { TYPES_HOME } from './home.types';

const INITIAL_STATE = {
  data: null,
  error: null,
  loading: false,
  videoData: null,
  videoError: null,
  videoLoading: true,
};

const homeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES_HOME.SET_HOME_LOADING:
      return { ...state, loading: true };
    case TYPES_HOME.GET_HOME_DATA:
      return {
        ...state,
        data: action.payload,
        error: action.error,
        loading: false,
      };

    case TYPES_HOME.SET_HOME_VIDEO_LOADING:
      return { ...state, videoLoading: true };
    case TYPES_HOME.GET_HOME_VIDEO_DATA:
      return {
        ...state,
        videoData: action.payload,
        videoError: action.error,
        videoLoading: false,
      };
    default:
      return state;
  }
};

export default homeReducer;
