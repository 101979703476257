import PropTypes from 'prop-types';
import { CompanyLogo } from './CompanyLogo/CompanyLogo';

export const FullPageLoader = ({ show }) => {
  const showClassName = show ? 'opacity-100 visible' : 'opacity-0 invisible';
  const barHeight = '5px';

  return (
    <div
      className={`fixed top-0 left-0 w-screen h-screen bg-white transition-all flex flex-col items-center justify-center z-50 ${showClassName}`}
    >
      <div className="flex flex-col items-center -mt-20">
        <CompanyLogo />
        <div
          className="relative mt-5 w-48 md:w-56 rounded bg-gray-200 overflow-hidden"
          style={{ height: barHeight }}
        >
          <div className="absolute top-0 left-0 animate-fullpage-loading transform  w-20 h-full bg-primary transition-all" />
        </div>
      </div>
    </div>
  );
};

FullPageLoader.propTypes = {
  show: PropTypes.bool,
};

FullPageLoader.defaultProps = {
  show: true,
};
