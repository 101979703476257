const disablePageScroll = (isDisable = true) => {
  const elHTML = document.querySelector('html');

  if (isDisable) elHTML.style.overflowY = 'hidden';
  else elHTML.style.removeProperty('overflow-y');
};

const truncateText = (text, maxLength = 10) => {
  let result = text.substr(0, maxLength);
  if (text.length > maxLength) result += '...';
  return result;
};

const formatSeconds = (seconds) => {
  if (Number.isNaN(seconds)) return '00:00';

  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes().toString().padStart(2, '0');
  const ss = date.getUTCSeconds().toString().padStart(2, '0');

  return hh ? `${hh.toString().padStart(2, '0')}:${mm}:${ss}` : `${mm}:${ss}`;
};

const removeDuplicateArrayOfObject = (array) => {
  return array.filter((v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i);
};

const shuffleArray = (array) => array.sort(() => Math.random() - 0.5);

const queryStringToObject = (queryString) => {
  const pairs = queryString.substring(1).split('&');

  const array = pairs.map((el) => {
    const parts = el.split('=');
    return parts;
  });

  return Object.fromEntries(array);
};

const objectToQueryString = (obj) => {
  return Object.keys(obj).reduce((str, value, i) => {
    const key = value;
    const val = obj[key];
    const delimiter = i === 0 ? '?' : '&';
    return [str, delimiter, key, '=', val].join('');
  }, '');
};

export const HELPERS = {
  disablePageScroll,
  truncateText,
  formatSeconds,
  shuffleArray,
  queryStringToObject,
  objectToQueryString,
  removeDuplicateArrayOfObject,
};
