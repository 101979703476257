import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import PATH from '@/routes/path';
import { ACTION_ACTIVITY } from '@/redux/actions';
import AuthBanner from '@/images/client/ukm-indonesia/auth-banner.jpg';
import { CLIENT } from '@/config';

const AuthContainer = ({ title, description, hideBanner, hideGoHomeButton, children, className }) => {
  const dispatch = useDispatch();

  const containerClasses = classnames({
    'justify-center': hideBanner,
    'lg:grid lg:grid-cols-12': !hideBanner,
  });

  useEffect(() => {
    dispatch(ACTION_ACTIVITY.showHeader(false));
    dispatch(ACTION_ACTIVITY.showFooter(false));

    return () => {
      dispatch(ACTION_ACTIVITY.showHeader(true));
      dispatch(ACTION_ACTIVITY.showFooter(true));
    };
  }, []);

  return (
    <div style={{ background: '#EEF6F5' }}>
      <div className={`min-h-screen flex flex-col lg:flex-row items-center ${containerClasses} ${className}`}>
        {!hideBanner && (
          <div className="relative w-full h-32 lg:min-h-screen lg:h-full col-span-5">
            <div className="absolute z-10 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <img className="w-32 lg:w-40" src={CLIENT.LOGO_WHITE} alt="tumbu logo white" />
            </div>
            <img
              className="absolute left-0 top-0 z-0 h-full w-full object-cover object-top lg:object-center"
              src={AuthBanner}
              alt="Tumbu Auth Banner"
            />
          </div>
        )}

        <div className="w-full lg:w-auto col-span-7 flex items-center py-16 lg:py-24 px-6 md:px-12 lg:px-16 xl:px-28">
          <div className="w-full">
            <div className="space-y-3 mb-10">
              <h1 className="font-semibold text-primary text-2xl">{title}</h1>
              <p>{description}</p>
            </div>
            <div className="bg-white p-8 lg:p-10 rounded-md">{children}</div>
            {!hideGoHomeButton && (
              <div className="text-center text-primary font-semibold mt-6 hover:underline">
                <Link to={PATH.HOME}>Kembali Ke Beranda</Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

AuthContainer.defaultProps = {
  hideBanner: false,
  hideGoHomeButton: false,
};

export default AuthContainer;
