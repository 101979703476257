import store from 'store';
import { SERVICE_AUTH } from '@/services';
import { CONSTANTS } from '@/utils';
import { TYPES_AUTH } from './auth.types';
import { TYPES_PROFILE } from '../profile/profile.types';
import { ACTION_ACTIVITY } from '../activity/activity.action';

export const ACTION_AUTH = {
  register: (data, callback) => {
    return (dispatch) => {
      dispatch(ACTION_ACTIVITY.showTopLoadingBar(true));

      SERVICE_AUTH.register(data, (res) => {
        dispatch(ACTION_ACTIVITY.showTopLoadingBar(false));

        if (res.success) callback();
      });
    };
  },

  registerByGoogle: (accessToken, callback) => {
    return (dispatch) => {
      SERVICE_AUTH.registerByGoogle(accessToken, (res) => {
        dispatch(ACTION_ACTIVITY.showTopLoadingBar(false));

        if (res.success) callback();
      });
    };
  },

  registerByWa: (data, callback) => {
    return (dispatch) => {
      SERVICE_AUTH.registerByWa(data, (res) => {
        dispatch(ACTION_ACTIVITY.showTopLoadingBar(false));

        if (res.success) callback();
      });
    };
  },

  loginByEmail: (data, callback) => {
    return (dispatch) => {
      dispatch(ACTION_ACTIVITY.showTopLoadingBar(true));

      SERVICE_AUTH.loginByEmail(data, (res) => {
        dispatch(ACTION_ACTIVITY.showTopLoadingBar(false));

        if (res.success) {
          store.set(CONSTANTS.PROFILE_TOKEN, res.payload.token);
          dispatch({
            type: TYPES_AUTH.LOGIN,
            profile: res.payload.profile,
            token: res.payload.token,
          });
        }

        if (callback) callback(res.payload);
      });
    };
  },

  loginByGoogle: (accessToken, callback) => {
    return (dispatch) => {
      dispatch(ACTION_ACTIVITY.showTopLoadingBar(true));

      SERVICE_AUTH.loginByGoogle(accessToken, (res) => {
        dispatch(ACTION_ACTIVITY.showTopLoadingBar(false));

        if (res.success) {
          store.set(CONSTANTS.PROFILE_TOKEN, res.payload.token);

          dispatch({
            type: TYPES_AUTH.LOGIN,
            profile: res.success ? res.payload.profile : null,
            token: res.success ? res.payload.token : null,
          });
        }

        if (callback) callback(res.payload);
      });
    };
  },

  loginByWa: (data, callback) => {
    return (dispatch) => {
      dispatch(ACTION_ACTIVITY.showTopLoadingBar(true));

      SERVICE_AUTH.loginByWa(data, (res) => {
        dispatch(ACTION_ACTIVITY.showTopLoadingBar(false));

        if (res.success) {
          store.set(CONSTANTS.PROFILE_TOKEN, res.payload.token);

          dispatch({
            type: TYPES_AUTH.LOGIN,
            profile: res.success ? res.payload.profile : null,
            token: res.success ? res.payload.token : null,
          });

          if (callback) callback(res.payload);
        }
      });
    };
  },

  checkWa: (data, callback) => {
    return (dispatch) => {
      dispatch(ACTION_ACTIVITY.showTopLoadingBar(true));

      SERVICE_AUTH.checkWa(data, (res) => {
        dispatch(ACTION_ACTIVITY.showTopLoadingBar(false));

        if (res.success && typeof res.payload === 'object') {
          if (callback) callback();
        }
      });
    };
  },

  logout: (callback) => {
    return (dispatch) => {
      store.clearAll();
      store.remove(CONSTANTS.PROFILE_TOKEN);
      if (callback) callback();
      dispatch({ type: TYPES_AUTH.LOGOUT });
      dispatch({ type: TYPES_PROFILE.DELETE_PROFILE });
    };
  },
};
