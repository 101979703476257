import classNames from 'classnames';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { InputFeedback } from '../InputFeedback/InputFeedback';
import { InputLabel } from '../InputLabel/InputLabel';

export const InputText = ({
  label,
  icon,
  disabled,
  size,
  variant,
  iconSuffix,
  className,
  containerClassName,
  feedback,
  feedbackType,
  meta,
  required,
  isLoading,
  ...props
}) => {
  const variantClasses = classNames({
    'border bg-white rounded-md': variant === InputText.VARIANTS.DEFAULT,
    'border-b-2 focus-within:border-secondary': variant === InputText.VARIANTS.MATERIAL,
  });

  const labelClasses = classNames({
    'border-red-500':
      ((meta && meta.touched && meta.error) || feedback) && feedbackType === InputText.FEEDBACK_TYPE.ERROR,
    'border-green-500':
      ((meta && meta.touched && meta.error) || feedback) && feedbackType === InputText.FEEDBACK_TYPE.SUCCESS,
    'border-yellow-500':
      ((meta && meta.touched && meta.error) || feedback) && feedbackType === InputText.FEEDBACK_TYPE.WARNING,
  });

  const sizeClasses = classNames({
    'px-5 py-3': size === InputText.SIZES.MEDIUM,
    'p-2': size === InputText.SIZES.SMALL,
  });

  const disabledClasses = classNames({
    'text-gray-400 placeholder-gray-400': disabled,
    'text-black placeholder-gray-500': !disabled,
  });

  const disabledClassesContainer = classNames({
    'bg-gray-50': disabled,
    'bg-white': !disabled,
  });

  return (
    <div className={`${containerClassName}`}>
      {isLoading && (
        <>
          <Skeleton className="mb-1" height={20} width={100} />
          <Skeleton height={46} />
        </>
      )}
      {!isLoading && (
        <>
          {label && <InputLabel label={label} required={required} />}
          <label
            className={`flex items-center justify-center transition-all space-x-3 text-gray-500 ${labelClasses} ${sizeClasses} ${variantClasses} ${className} ${disabledClassesContainer}`}
            htmlFor={props.name}
          >
            {icon}
            <input
              className={`border-0 bg-transparent p-0 outline-none focus:ring-0 text-sm w-full ${disabledClasses}`}
              name={props.name}
              id={props.name}
              disabled={disabled}
              {...props}
            />
            {iconSuffix}
          </label>
          {((meta && meta.touched && meta.error) || feedback) && (
            <InputFeedback type={feedbackType} message={meta.error || feedback} />
          )}
        </>
      )}
    </div>
  );
};
InputText.VARIANTS = {
  DEFAULT: 'DEFAULT',
  MATERIAL: 'MATERIAL',
};

InputText.SIZES = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
};

InputText.FEEDBACK_TYPE = { ...InputFeedback.TYPES };

InputText.propTypes = {
  variant: PropTypes.oneOf(Object.values(InputText.VARIANTS)),
  size: PropTypes.oneOf(Object.values(InputText.SIZES)),
  icon: PropTypes.node,
  disabled: PropTypes.bool,
  iconSuffix: PropTypes.node,
  label: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  feedback: PropTypes.string,
  required: PropTypes.bool,
  feedbackType: PropTypes.oneOf(Object.values(InputText.FEEDBACK_TYPE)),
  meta: PropTypes.shape({
    error: PropTypes.string,
    initialError: PropTypes.string,
    initialTouched: PropTypes.bool,
    initialValue: PropTypes.string,
    touched: PropTypes.bool,
  }),
};

InputText.defaultProps = {
  label: '',
  variant: InputText.VARIANTS.DEFAULT,
  size: InputText.SIZES.MEDIUM,
  icon: null,
  disabled: false,
  iconSuffix: null,
  className: '',
  required: false,
  containerClassName: '',
  feedback: '',
  feedbackType: InputText.FEEDBACK_TYPE.ERROR,
  meta: null,
};
