import store from 'store';
import { CONSTANTS } from '@/utils';
import { http } from './http';

const BASE_URL = '/gamefication';

export const SERVICE_GAMIFICATION = {
  getPoint: (callback) => {
    const config = {
      headers: {
        Authorization: `Bearer ${store.get(CONSTANTS.PROFILE_TOKEN)}`,
      },
    };

    http
      .get(`${BASE_URL}/point`, config)
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },
  getPointHistory: (page, limit, callback) => {
    const config = {
      headers: {
        Authorization: `Bearer ${store.get(CONSTANTS.PROFILE_TOKEN)}`,
      },
    };

    http
      .get(`${BASE_URL}/point-history?page=${page}&limit=${limit}`, config)
      .then((res) => callback({ payload: res.data.payload, total: res.data.total, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },
  getCoachingHistory: (page, limit, callback) => {
    const config = {
      headers: {
        Authorization: `Bearer ${store.get(CONSTANTS.PROFILE_TOKEN)}`,
      },
    };

    http
      .get(`${BASE_URL}/coaching/history?page=${page}&limit=${limit}`, config)
      .then((res) => callback({ payload: res.data.payload, total: res.data.total, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },

  getCoachingCategories: (callback) => {
    const config = { headers: { Authorization: `Bearer ${store.get(CONSTANTS.PROFILE_TOKEN)}` } };

    http
      .get(`${BASE_URL}/coaching/categories`, config)
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },
  getCoachingSurvey: (callback) => {
    const config = { headers: { Authorization: `Bearer ${store.get(CONSTANTS.PROFILE_TOKEN)}` } };

    http
      .get(`${BASE_URL}/coaching/survey`, config)
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },

  requestCoaching: (date, categoryID, questions, mediaSocial, surveyId, surveyAnswer, callback) => {
    const config = {
      headers: {
        Authorization: `Bearer ${store.get(CONSTANTS.PROFILE_TOKEN)}`,
      },
    };

    const data = {
      category_id: categoryID,
      questions,
      media_socials: mediaSocial,
      date,
      survey_id: surveyId,
      survey_answers: surveyAnswer,
    };

    http
      .post(`${BASE_URL}/coaching/request`, data, config)
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },
};
