import { useSelector } from 'react-redux';
import { HiPlay, HiCheckCircle, HiLockClosed } from 'react-icons/hi';
import { GiBookCover, GiFiles } from 'react-icons/gi';
import { notify } from 'react-notify-toast';
import { CONSTANTS } from '@/utils';
import Skeleton from 'react-loading-skeleton';
import { MdReport } from 'react-icons/md';

export const ProductTopicList = ({
  isLoading,
  topics,
  activeTopic,
  isCourseLocked,
  chapterTitle,
  chapter,
  onChangeActiveTopic,
  className,
}) => {
  const isLoggedIn = useSelector((state) => state.auth.token);

  const handleChangeActiveTopic = (topic) => {
    if (isLoggedIn || topic.resource_type === CONSTANTS.RESOURCE_TYPE.VIDEO) {
      if (
        (!topic.is_locked && !isCourseLocked) ||
        (!isCourseLocked && topic.resource_type === CONSTANTS.RESOURCE_TYPE.VIDEO)
      ) {
        onChangeActiveTopic(chapter, topic);
      } else if (isCourseLocked) {
        notify.show(`Silakan mulai kursus pada section intro terlebih dahulu`, 'warning');
      } else if (chapterTitle) {
        notify.show(`Selesaikan menonton video pada Chapter ${chapterTitle} terlebih dahulu`, 'warning');
      } else notify.show('Selesaikan menonton video terlebih dahulu', 'warning');
    } else notify.show('Anda harus login terlebih dahulu', 'warning');
  };

  return (
    <div className={className}>
      {(isLoading || !topics) && <ProductTopicList.Skeleton />}
      {!isLoading && topics && topics.length === 0 && (
        <div className="flex items-center justify-center text-center text-gray-400 italic bg-gray-100 text-sm py-5">
          No topics found on this chapter
        </div>
      )}
      {!isLoading &&
        topics &&
        topics.length > 0 &&
        topics.map((topic) => (
          <button
            key={topic.uuid}
            onClick={() => handleChangeActiveTopic(topic)}
            className={`flex text-left justify-between w-full items-center  px-8 py-5 ${
              activeTopic && topic.uuid === activeTopic.uuid ? 'bg-primary-100 bg-opacity-30' : ''
            } transition-all hover:bg-primary-100 hover:bg-opacity-50 border-b border-gray-100`}
            type="button"
          >
            <div className="flex items-center space-x-3">
              <div className="w-7">
                {topic.resource_type === CONSTANTS.RESOURCE_TYPE.EMPTY && <MdReport size={24} />}
                {topic.resource_type === CONSTANTS.RESOURCE_TYPE.VIDEO && <HiPlay size={24} />}
                {topic.resource_type === CONSTANTS.RESOURCE_TYPE.QUIZ && <GiBookCover size={20} />}
                {topic.resource_type === CONSTANTS.RESOURCE_TYPE.WORKING_PAPER && <GiFiles size={20} />}
              </div>
              <div className="space-y-1">
                <div className="text-sm">{topic.title}</div>
                <div className="text-xs text-gray-400 flex items-center space-x-3">
                  {(!isLoggedIn || topic.is_locked) &&
                    (topic.resource_type !== CONSTANTS.RESOURCE_TYPE.VIDEO || isCourseLocked) && (
                      <span className="flex items-center space-x-1">
                        <HiLockClosed size={12} /> <span>Locked</span>
                      </span>
                    )}
                  {isLoggedIn && !topic.is_locked && !isCourseLocked && (
                    <span>
                      {topic.resource_type === CONSTANTS.RESOURCE_TYPE.VIDEO && (
                        <span>{topic.resource_length}</span>
                      )}
                      {topic.resource_type === CONSTANTS.RESOURCE_TYPE.QUIZ && (
                        <span>{topic.tested_count ? topic.tested_count : topic.questions.length} Pertanyaan</span>
                      )}
                      {topic.resource_type === CONSTANTS.RESOURCE_TYPE.WORKING_PAPER && (
                        <span>Unggah Dokumen</span>
                      )}
                    </span>
                  )}
                </div>
              </div>
            </div>
            {isLoggedIn &&
              !topic.is_locked &&
              !isCourseLocked &&
              topic.is_completed &&
              (topic.resource_type !== CONSTANTS.RESOURCE_TYPE.WORKING_PAPER ||
                (topic.resource_type === CONSTANTS.RESOURCE_TYPE.WORKING_PAPER && topic.is_graded)) && (
                <div className="text-primary">
                  <HiCheckCircle size={20} />
                </div>
              )}
          </button>
        ))}
    </div>
  );
};

ProductTopicList.TITLE = 'Materi';
ProductTopicList.Skeleton = () => {
  return (
    <div className="p-3">
      <Skeleton className="mb-1" style={{ lineHeight: 'inherit' }} count={3} height={45} />
    </div>
  );
};
