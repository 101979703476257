import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import { ACTION_PROFILE } from '@/redux/actions';
import { ProfileContainer } from '../components/ProfileContainer';
import { CardTotalStudyHours } from './CardTotalStudyHours';
import { CardOnProgressList } from './CardOnProgressList';
import { CardCompletedList } from './CardCompletedList';

const RiwayatBelajar = ({ documentTitle }) => {
  const dispatch = useDispatch();

  const learningDuration = useSelector((state) => state.profile.learningDuration);

  useEffect(() => {
    document.title = documentTitle;
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send('pageview');
    dispatch(ACTION_PROFILE.getLearningDuration());
  }, []);

  return (
    <ProfileContainer>
      <div className="grid lg:grid-cols-2 gap-8">
        <CardTotalStudyHours duration={learningDuration && learningDuration.total_duration} />

        <div className="hidden lg:block" />

        <CardOnProgressList />
        <CardCompletedList />
      </div>
    </ProfileContainer>
  );
};

export default RiwayatBelajar;
