import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FiSearch } from 'react-icons/fi';
import { InputText } from '@/components/atoms';
import PATH from '@/routes/path';
import { useHistory, useLocation } from 'react-router-dom';
import { HELPERS } from '@/utils';

export const HeaderSearch = ({ desktop }) => {
  const { pathname, search } = useLocation();
  const history = useHistory();

  const [value, setValue] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const targetPath = pathname !== PATH.VIDEO && pathname !== PATH.COURSE ? PATH.VIDEO : pathname;
    const queries = search ? HELPERS.queryStringToObject(search) : {};

    if (value) queries.search = value;
    else delete queries.search;

    history.push({ pathname: targetPath, search: HELPERS.objectToQueryString(queries) });
  };

  useEffect(() => {
    if (search) {
      const queries = HELPERS.queryStringToObject(search);
      if (queries.search) setValue(queries.search);
    } else setValue('');
  }, [search]);

  return desktop ? (
    <form className="w-1/2" onSubmit={handleSubmit}>
      <InputText
        className="border"
        type="search"
        name="search"
        value={value}
        icon={
          <button type="button" onClick={handleSubmit}>
            <FiSearch size={18} />
          </button>
        }
        onChange={(e) => setValue(e.target.value)}
        placeholder="Cari..."
      />
    </form>
  ) : (
    <form className="absolute left-0 h-full focus-within:w-full" onSubmit={(e) => handleSubmit(e)}>
      <label className="flex h-full items-stretch" htmlFor="searchMobile">
        <div className="flex items-center cursor-pointer px-6">
          <FiSearch className="text-gray-800" size={22} />
        </div>
        <div className="w-3/5">
          <input
            className="h-full text-sm border-0 w-0 focus:w-full opacity-0 focus:opacity-100 p-0 transition-all duration-200 text-gray-500 rounded outline-none focus:ring-0"
            type="search"
            id="searchMobile"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            name="search"
            placeholder="Cari..."
          />
        </div>
      </label>
    </form>
  );
};

HeaderSearch.propTypes = {
  desktop: PropTypes.bool,
};

HeaderSearch.defaultProps = {
  desktop: false,
};
