import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { notify } from 'react-notify-toast';
import { FaEye, FaEyeSlash, FaWhatsapp } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import { Formik, Form } from 'formik';
import GoogleLogin from 'react-google-login';
import { useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';
import PATH from '@/routes/path';
import { VALIDATION_SCHEMA } from '@/utils';
import { InputText, Button } from '@/components/atoms';
import { ACTION_AUTH, ACTION_FORMS } from '@/redux/actions';
import { CLIENT, KEY } from '@/config';
import AuthContainer from './components/AuthContainer';

const Register = ({ documentTitle }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const INITIAL_VALUES = {
    name: '',
    phone: '',
    email: '',
    password: '',
    confirmPassword: '',
  };

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);

  const handleRegisterSuccess = () => {
    ReactGA.event('sign_up', { method: 'Email' });
    history.push(PATH.AUTH_LOGIN);
    notify.show(
      `Terima kasih sudah mendaftar di ${CLIENT.NAME}. Mohon cek email Anda terlebih dahulu untuk aktivasi email.`,
      'success'
    );
  };

  const handleRegisterGoogleSuccess = (accessToken) => {
    ReactGA.event('sign_up', { method: 'Google' });
    notify.show(
      `Terima kasih sudah mendaftar di ${CLIENT.NAME}. Silakan lengkapi profile untuk melanjutkan.`,
      'success'
    );

    dispatch(ACTION_AUTH.loginByGoogle(accessToken, () => history.push(PATH.PROFILE)));
  };

  const handleRegister = (value) => {
    const data = {
      name: value.name,
      phone: value.phone,
      password: value.password,
      email: value.email,
      client_token: KEY.CLIENT_TOKEN,
    };

    dispatch(ACTION_AUTH.register(data, () => handleRegisterSuccess()));
  };

  const handleRegisterByGoogle = (response) => {
    if (!response.error) {
      const { accessToken } = response;
      dispatch(ACTION_AUTH.registerByGoogle(accessToken, () => handleRegisterGoogleSuccess(accessToken)));
    }
  };

  useEffect(() => {
    document.title = documentTitle;
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send('pageview');
  }, []);

  return (
    <AuthContainer
      title="Daftar Akun Baru"
      description="Dengan daftar dan buat akun, Kakak akan bisa memantau perkembangan dan poin pembelajaran. Jadi, mohon isi dengan informasi yang benar ya, kak :)  "
    >
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={VALIDATION_SCHEMA.registerSchema}
        onSubmit={handleRegister}
      >
        {({ values, handleChange, handleSubmit, setFieldValue, getFieldMeta, touched, errors }) => {
          const isAnyError = Object.keys(errors).length;
          const isAnyTouched = Object.values(touched).includes(true);

          return (
            <Form onSubmit={handleSubmit}>
              <div className="mb-12 space-y-6">
                <InputText
                  label="Nama Lengkap"
                  required
                  name="name"
                  placeholder="Masukkan Nama Lengkap"
                  onChange={(event) => {
                    handleChange(event);
                    dispatch(ACTION_FORMS.handleChangeRegister({ name: event.target.value }));
                  }}
                  value={values.name}
                  meta={getFieldMeta('name')}
                />

                <InputText
                  label="Nomor Handphone"
                  required
                  className="mb-3"
                  name="phone"
                  icon={<span className="text-sm text-gray-400">+62</span>}
                  placeholder="Masukkan Nomor Handphone"
                  onChange={(event) => {
                    const { value } = event.target;
                    const phone = value[0] === '0' ? value.substr(1, value.length) : value;

                    setFieldValue('phone', phone, true);
                    dispatch(ACTION_FORMS.handleChangeRegister({ phone }));
                  }}
                  value={values.phone}
                  meta={getFieldMeta('phone')}
                />

                <InputText
                  label="Email"
                  required
                  name="email"
                  placeholder="Masukkan Email"
                  onChange={(event) => {
                    dispatch(ACTION_FORMS.handleChangeRegister({ email: event.target.value }));
                    handleChange(event);
                  }}
                  value={values.email}
                  meta={getFieldMeta('email')}
                />

                <InputText
                  label="Kata Sandi"
                  required
                  className="mb-3"
                  onChange={(event) => {
                    dispatch(ACTION_FORMS.handleChangeRegister({ password: event.target.value }));
                    handleChange(event);
                  }}
                  iconSuffix={
                    <button
                      className="hover:text-gray-400"
                      type="button"
                      onClick={() => setIsShowPassword(!isShowPassword)}
                    >
                      {isShowPassword ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
                    </button>
                  }
                  type={isShowPassword ? 'text' : 'password'}
                  name="password"
                  placeholder="Masukkan Kata Sandi"
                  value={values.password}
                  autoComplete="off"
                  meta={getFieldMeta('password')}
                />

                <InputText
                  label="Konfirmasi Kata Sandi"
                  required
                  className="mb-3"
                  onChange={(event) => {
                    dispatch(ACTION_FORMS.handleChangeRegister({ confirmPassword: event.target.value }));
                    handleChange(event);
                  }}
                  iconSuffix={
                    <button
                      className="hover:text-gray-400"
                      type="button"
                      onClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}
                    >
                      {isShowConfirmPassword ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
                    </button>
                  }
                  type={isShowConfirmPassword ? 'text' : 'password'}
                  name="confirmPassword"
                  placeholder="Ketik Ulang Kata Sandi"
                  value={values.confirmPassword}
                  autoComplete="off"
                  meta={getFieldMeta('confirmPassword')}
                />
              </div>
              <Button
                className="w-full mb-3"
                size={Button.SIZES.LARGE}
                disabled={!!isAnyError && !!isAnyTouched}
                submit
              >
                Daftar
              </Button>
              <Button
                className="w-full"
                variant={Button.VARIANTS.PRIMARY_OUTLINE}
                size={Button.SIZES.LARGE}
                linkTo={PATH.AUTH_LOGIN}
              >
                Masuk
              </Button>

              <div className="py-6 text-sm">
                <div className="border-b bg-gray-200" />
              </div>

              <GoogleLogin
                clientId={KEY.GOOGLE_CLIENT_ID}
                style={{ backgroundColor: 'none', marginTop: 12 }}
                css={{ backgroundColor: 'none', marginTop: 12 }}
                className="bg-primary text-white w-full flex items-center justify-center py-3"
                buttonText="Masuk Dengan Google"
                cookiePolicy="single_host_origin"
                theme="dark"
                onSuccess={handleRegisterByGoogle}
                onFailure={handleRegisterByGoogle}
                render={({ onClick }) => (
                  <Button
                    variant={null}
                    size={Button.SIZES.LARGE}
                    className="bg-primary-900 text-white w-full flex items-center justify-center"
                    onClick={onClick}
                  >
                    <img
                      src={require('@/images/icons/google.png').default}
                      className="w-5 mr-3"
                      alt="Google Icon"
                    />{' '}
                    Daftar Dengan Google
                  </Button>
                )}
              />
              <a href={process.env.REACT_APP_WA_MARKETA}>
                <Button size={Button.SIZES.LARGE} className="w-full mt-3">
                  <IconContext.Provider value={{ className: 'mr-3 text-2xl' }}>
                    <div>
                      <FaWhatsapp />
                    </div>
                  </IconContext.Provider>
                  Daftar Dengan WhatsApp
                </Button>
              </a>
            </Form>
          );
        }}
      </Formik>
    </AuthContainer>
  );
};

export default Register;
