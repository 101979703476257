import parse, { domToReact, attributesToProps } from 'html-react-parser';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Container } from '@/components/atoms';
import { ACTION_FOOTER } from '@/redux/actions';

const Disclaimer = ({ documentTitle }) => {
  const dispatch = useDispatch();

  const disclaimer = useSelector((state) => state.footer.disclaimer);

  useEffect(() => {
    document.title = documentTitle;
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send('pageview');
    dispatch(ACTION_FOOTER.getDisclaimer());
  }, []);

  // parser styling
  const options = {
    replace: ({ name, children, attribs }) => {
      if (name === 'ol') {
        return <div className="list-decimal pl-5">{domToReact(children, options)}</div>;
      }
      if (name === 'ul') {
        return <div className="list-disc pl-5">{domToReact(children, options)}</div>;
      }
      if (name === 'h1') {
        return <h1 className="text-2xl">{domToReact(children, options)}</h1>;
      }
      if (name === 'h2') {
        return <h2 className="text-xl">{domToReact(children, options)}</h2>;
      }
      if (name === 'h3') {
        return <h3 className="text-lg">{domToReact(children, options)}</h3>;
      }
      if (name === 'h4') {
        return <h4 className="text-base">{domToReact(children, options)}</h4>;
      }
      if (name === 'h5') {
        return <h5 className="text-sm">{domToReact(children, options)}</h5>;
      }
      if (name === 'h6') {
        return <h6 className="text-xs">{domToReact(children, options)}</h6>;
      }
      if (name === 'hr') {
        return <hr className="border-gray-500" />;
      }
      if (name === 'a') {
        const props = attributesToProps(attribs);
        return (
          <a {...props} className="text-primary underline">
            {domToReact(children, options)}
          </a>
        );
      }
      if (name === 'table') {
        const props = attributesToProps(attribs);
        return (
          <table {...props} className="table border-collapse">
            {domToReact(children, options)}
          </table>
        );
      }
      if (name === 'caption') {
        return <caption className="font-bold">{domToReact(children, options)}</caption>;
      }
      if (name === 'td') {
        return <td className="border border-gray-500">{domToReact(children, options)}</td>;
      }
      return null;
    },
  };

  return (
    <div className="bg-gray-100 py-5">
      <Container>
        <div className="px-5 py-10 space-y-10 bg-white shadow-md">
          <h1 className="text-2xl text-center font-bold">Disclaimer</h1>
          {disclaimer !== null && <div className="space-y-3">{parse(disclaimer, options)}</div>}
        </div>
      </Container>
    </div>
  );
};

export default Disclaimer;
