import { TYPES_GAMIFICATION } from './gamification.types';

const INITIAL_STATE = {
  pointData: null,
  pointHistoryData: null,
  pointHistoryTotal: null,
  pointHistoryDataLoading: true,
  coachingHistoryData: null,
  coachingHistoryTotal: null,
  coachingHistoryDataLoading: true,
  coachingCategoriesData: null,
  coachingCategoriesDataLoading: true,
  coachingSurveyData: null,
  requestCoachingSubmittingForm: false,
};

const gamificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES_GAMIFICATION.GET_GAMIFICATION_POINT:
      return { ...state, pointData: action.payload };

    case TYPES_GAMIFICATION.SET_GAMIFICATION_POINT_HISTORY_LOADING:
      return { ...state, pointHistoryDataLoading: action.isLoading };
    case TYPES_GAMIFICATION.GET_GAMIFICATION_POINT_HISTORY:
      return { ...state, pointHistoryData: action.payload, pointHistoryTotal: action.total };

    case TYPES_GAMIFICATION.SET_GAMIFICATION_COACHING_HISTORY_LOADING:
      return { ...state, coachingHistoryDataLoading: action.isLoading };
    case TYPES_GAMIFICATION.GET_GAMIFICATION_COACHING_HISTORY:
      return { ...state, coachingHistoryData: action.payload, coachingHistoryTotal: action.total };

    case TYPES_GAMIFICATION.SET_GAMIFICATION_COACHING_CATEGORIES_LOADING:
      return { ...state, coachingCategoriesDataLoading: action.isLoading };
    case TYPES_GAMIFICATION.GET_GAMIFICATION_COACHING_CATEGORIES:
      return { ...state, coachingCategoriesData: action.payload };
    case TYPES_GAMIFICATION.GET_GAMIFICATION_COACHING_SURVEY:
      return { ...state, coachingSurveyData: action.payload };

    case TYPES_GAMIFICATION.SET_GAMIFICATION_REQUEST_COACHING_SUBMITTING_FORM:
      return { ...state, requestCoachingSubmittingForm: action.isLoading };

    default:
      return state;
  }
};

export default gamificationReducer;
