import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { InputCheckbox, InputRadio } from '@/components/atoms';
import { CONSTANTS, HELPERS } from '@/utils';
import { ACTION_CATEGORIES } from '@/redux/actions';

export const ProductListFilter = ({ className, onChangeCategory }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  let queries = location.search ? HELPERS.queryStringToObject(location.search) : null;
  const categoriesData = useSelector((state) => state.categories.data);

  const selectedCategoryIDs = queries && queries.category_id ? queries.category_id.split(',') : [];
  let selectedSortBy = queries && queries.sort_by ? queries.sort_by : '';
  let selectedOrder = queries && queries.order ? queries.order : '';
  const selectedProductType = location.pathname;
  const [activeAccordion, setActiveAccordion] = useState([]);

  const handleChangeProductType = (productType) => {
    history.push({ pathname: productType.path });
  };

  const handleChangeSortBy = (sortBy, order) => {
    if (!selectedSortBy === sortBy) selectedSortBy = sortBy;
    if (!selectedOrder === order) selectedOrder = order;

    if (selectedCategoryIDs) queries = { ...queries, sort_by: sortBy, order };
    else delete queries.sort_by;

    if (onChangeCategory) onChangeCategory();
    history.push({ search: HELPERS.objectToQueryString({ ...queries }) });
  };

  const handleChangeCategory = (e) => {
    const { value } = e.target;

    if (!selectedCategoryIDs.includes(value)) selectedCategoryIDs.push(value);
    else selectedCategoryIDs.splice(selectedCategoryIDs.indexOf(value), 1);

    if (selectedCategoryIDs.length) queries = { ...queries, category_id: selectedCategoryIDs.join(',') };
    else delete queries.category_id;

    if (onChangeCategory) onChangeCategory();
    history.push({ search: HELPERS.objectToQueryString({ ...queries }) });
  };

  const handleClickAccordion = (categoryID) => {
    if (activeAccordion.includes(categoryID)) activeAccordion.splice(activeAccordion.indexOf(categoryID), 1);
    else setActiveAccordion(activeAccordion.push(categoryID));
    setActiveAccordion([...activeAccordion]);
  };

  useEffect(() => dispatch(ACTION_CATEGORIES.getCategoriesData()), []);
  useEffect(() => categoriesData && setActiveAccordion(categoriesData.map((category) => category.id)), [
    categoriesData,
  ]);

  return (
    <form className={`${className} md:sticky md:top-28 h-auto space-y-5`}>
      {/* {queries && queries.search && (
        <div className="text-sm text-gray-400">
          Anda mencari : <span className="italic font-semibold">{queries.search}</span>
        </div>
      )} */}
      <div>
        <div className="font-semibold mb-5">Tipe</div>
        <div className="flex flex-col space-y-3">
          {Object.values(CONSTANTS.PRODUCT_TYPE).map((productType) => (
            <InputRadio
              key={productType.id}
              label={productType.title}
              onChange={() => handleChangeProductType(productType)}
              checked={productType.path === selectedProductType}
            />
          ))}
        </div>
      </div>

      <hr />
      <div>
        <button
          className="w-full flex items-center justify-between mt-5"
          type="button"
          onClick={() => handleClickAccordion(0)}
        >
          <div className="font-semibold text-left">Urutkan Berdasarkan</div>
          <div>{activeAccordion.includes(0) ? <FiChevronUp /> : <FiChevronDown />}</div>
        </button>
        {activeAccordion.includes(0) && (
          <div className="flex flex-col space-y-2 mt-5">
            <InputRadio
              label="Judul A-Z"
              onChange={() => handleChangeSortBy('title', 'asc')}
              checked={selectedSortBy && selectedSortBy === 'title' && selectedOrder && selectedOrder === 'asc'}
            />
            <InputRadio
              label="Judul Z-A"
              onChange={() => handleChangeSortBy('title', 'desc')}
              checked={selectedSortBy && selectedSortBy === 'title' && selectedOrder && selectedOrder === 'desc'}
            />
            <InputRadio
              label="Posting Terlama"
              onChange={() => handleChangeSortBy('id', 'asc')}
              checked={selectedSortBy && selectedSortBy === 'id' && selectedOrder && selectedOrder === 'asc'}
            />
            <InputRadio
              label="Posting Terbaru"
              onChange={() => handleChangeSortBy('id', 'desc')}
              checked={selectedSortBy && selectedSortBy === 'id' && selectedOrder && selectedOrder === 'desc'}
            />
            {false && selectedProductType === '/course' && (
              <>
                <InputRadio
                  label="Rating 0-5"
                  onChange={() => handleChangeSortBy('average_rating', 'asc')}
                  checked={
                    selectedSortBy &&
                    selectedSortBy === 'average_rating' &&
                    selectedOrder &&
                    selectedOrder === 'asc'
                  }
                />
                <InputRadio
                  label="Rating 5-0"
                  onChange={() => handleChangeSortBy('average_rating', 'desc')}
                  checked={
                    selectedSortBy &&
                    selectedSortBy === 'average_rating' &&
                    selectedOrder &&
                    selectedOrder === 'desc'
                  }
                />
              </>
            )}
            {false && selectedProductType === '/video' && (
              <>
                <InputRadio
                  label="Rating 0-5"
                  onChange={() => handleChangeSortBy('point_rating', 'asc')}
                  checked={
                    selectedSortBy && selectedSortBy === 'point_rating' && selectedOrder && selectedOrder === 'asc'
                  }
                />
                <InputRadio
                  label="Rating 5-0"
                  onChange={() => handleChangeSortBy('point_rating', 'desc')}
                  checked={
                    selectedSortBy &&
                    selectedSortBy === 'point_rating' &&
                    selectedOrder &&
                    selectedOrder === 'desc'
                  }
                />
              </>
            )}
          </div>
        )}
      </div>

      {categoriesData &&
        categoriesData.map((category) => (
          <div key={category.id}>
            <hr />

            <div>
              <button
                className="w-full flex items-center justify-between mt-5"
                type="button"
                onClick={() => handleClickAccordion(category.id)}
              >
                <div className="font-semibold text-left">{category.name}</div>
                <div>{activeAccordion.includes(category.id) ? <FiChevronUp /> : <FiChevronDown />}</div>
              </button>
              {activeAccordion.includes(category.id) && (
                <div className="flex flex-col space-y-2 mt-5">
                  {category.subcategories &&
                    category.subcategories.map((subcategory) => (
                      <InputCheckbox
                        key={subcategory.id}
                        label={subcategory.name}
                        value={subcategory.id}
                        checked={selectedCategoryIDs && selectedCategoryIDs.includes(subcategory.id.toString())}
                        onChange={handleChangeCategory}
                      />
                    ))}
                </div>
              )}
            </div>
          </div>
        ))}
    </form>
  );
};
