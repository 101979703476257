import { SERVICE_FOOTER } from '@/services';
import { TYPES_FOOTER } from './footer.types';

export const ACTION_FOOTER = {
  getDisclaimer: () => {
    return (dispatch) => {
      SERVICE_FOOTER.getDisclaimer((res) => {
        dispatch({
          type: TYPES_FOOTER.GET_FOOTER_DISCLAIMER,
          payload: res.payload,
          error: res.error,
        });
      });
    };
  },
  getPrivacy: () => {
    return (dispatch) => {
      SERVICE_FOOTER.getPrivacy((res) => {
        dispatch({
          type: TYPES_FOOTER.GET_FOOTER_PRIVACY,
          payload: res.payload,
          error: res.error,
        });
      });
    };
  },
};
