import axios from 'axios';
import { http } from './http';

const BASE_URL_SHARE = '/share';
const BASE_URL_VISITOR = '/visitor';

export const SERVICE_SHARE = {
  storeShare: (data, callback) => {
    http
      .post(`${BASE_URL_SHARE}/store`, data)
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },
  getIPAddress: (callback) => {
    axios
      .get('https://geolocation-db.com/json/')
      .then((res) => callback({ payload: res.data.IPv4, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },
  storeVisitor: (data, callback) => {
    http
      .post(`${BASE_URL_VISITOR}/store`, data)
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },
};
