import React, { useState } from 'react';
import { Container, InputRadio } from '@/components/atoms';

const Transaction = () => {
  const FILTERS = ['Semua', 'Menunggu Pembayaran', 'Menunggu Konfirmasi', 'Kadaluwarsa', 'Ditolak', 'Berhasil'];
  const [selectedFilter, setSelectedFilter] = useState(FILTERS[0]);
  const data = 1;

  return (
    <>
      <div className="bg-primary">
        <Container>
          <div className="text-white py-16 text-2xl font-semibold">Riwayat Transaksi Anda</div>
        </Container>
      </div>
      <div className="bg-gray-100 py-8">
        <Container className="flex items-stretch space-x-6">
          <div className="py-10 px-8 w-1/3 rounded-md bg-white shadow-md h-80 sticky top-24">
            <div className="text-primary text-xl font-semibold mb-6">Filter</div>
            <div className="space-y-3">
              {FILTERS.map((filter) => (
                <InputRadio
                  checked={selectedFilter === filter}
                  onChange={() => setSelectedFilter(filter)}
                  color={InputRadio.COLORS.PRIMARY}
                  label={filter}
                />
              ))}
            </div>
          </div>
          <div className="w-full">
            {data === 0 && (
              <div className="rounded-md shadow-md bg-white flex flex-col items-center justify-center h-full">
                <img className="w-28 mb-6" src="/images/icons/ic-riwayat.png" alt="Riwayat Transaksi Empty" />
                <div className="text-sm text-gray-500">Belum ada riwayat transaksi</div>
              </div>
            )}
            {data !== 0 && (
              <div className="space-y-6">
                <div className="rounded-md shadow-md bg-white flex flex-col p-6 space-y-3">
                  <div className="text-blue-500">Gratis</div>
                  <div className="font-semibold text-xl">Panduan Memilih Karir dan Jurusan Kuliah</div>
                  <div className="text-sm text-gray-500 font-semibold pb-3">
                    Invoice Number : 20210406/135850370014485
                  </div>
                  <div className="flex justify-between">
                    <div className="text-gray-500 text-sm">6 April 2021</div>
                    <div className="text-right text-sm text-green-500">Berhasil</div>
                  </div>
                </div>
                <div className="rounded-md shadow-md bg-white flex flex-col p-6 space-y-3">
                  <div className="text-blue-500">Gratis</div>
                  <div className="font-semibold text-xl">Panduan Memilih Karir dan Jurusan Kuliah</div>
                  <div className="text-sm text-gray-500 font-semibold pb-3">
                    Invoice Number : 20210406/135850370014485
                  </div>
                  <div className="flex justify-between">
                    <div className="text-gray-500 text-sm">6 April 2021</div>
                    <div className="text-right text-sm text-green-500">Berhasil</div>
                  </div>
                </div>
                <div className="rounded-md shadow-md bg-white flex flex-col p-6 space-y-3">
                  <div className="text-blue-500">Gratis</div>
                  <div className="font-semibold text-xl">Panduan Memilih Karir dan Jurusan Kuliah</div>
                  <div className="text-sm text-gray-500 font-semibold pb-3">
                    Invoice Number : 20210406/135850370014485
                  </div>
                  <div className="flex justify-between">
                    <div className="text-gray-500 text-sm">6 April 2021</div>
                    <div className="text-right text-sm text-green-500">Berhasil</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default Transaction;
