import { http } from './http';

const BASE_URL = '/home';

export const SERVICE_HOME = {
  getHomeData: (callback) => {
    http
      .get(BASE_URL)
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },

  getHomeVideoData: (callback) => {
    http
      .get(`${BASE_URL}/video`)
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },
};
