import { SERVICE_GAMIFICATION } from '@/services';
import { TYPES_GAMIFICATION } from './gamification.types';

const getPoint = () => {
  return (dispatch) => {
    SERVICE_GAMIFICATION.getPoint((res) => {
      dispatch({
        type: TYPES_GAMIFICATION.GET_GAMIFICATION_POINT,
        payload: res.payload,
      });
    });
  };
};

const getPointHistory = (page, limit) => {
  return (dispatch) => {
    dispatch({ type: TYPES_GAMIFICATION.SET_GAMIFICATION_POINT_HISTORY_LOADING, isLoading: true });
    SERVICE_GAMIFICATION.getPointHistory(page, limit, (res) => {
      dispatch({ type: TYPES_GAMIFICATION.SET_GAMIFICATION_POINT_HISTORY_LOADING, isLoading: false });
      dispatch({
        type: TYPES_GAMIFICATION.GET_GAMIFICATION_POINT_HISTORY,
        total: res.total,
        payload: res.payload,
      });
    });
  };
};

const getCoachingHistory = (page, limit) => {
  return (dispatch) => {
    dispatch({ type: TYPES_GAMIFICATION.SET_GAMIFICATION_COACHING_HISTORY_LOADING, isLoading: true });
    SERVICE_GAMIFICATION.getCoachingHistory(page, limit, (res) => {
      dispatch({ type: TYPES_GAMIFICATION.SET_GAMIFICATION_COACHING_HISTORY_LOADING, isLoading: false });
      dispatch({
        type: TYPES_GAMIFICATION.GET_GAMIFICATION_COACHING_HISTORY,
        total: res.total,
        payload: res.payload,
      });
    });
  };
};

const getCoachingCategories = () => {
  return (dispatch) => {
    dispatch({ type: TYPES_GAMIFICATION.SET_GAMIFICATION_COACHING_CATEGORIES_LOADING, isLoading: true });
    SERVICE_GAMIFICATION.getCoachingCategories((res) => {
      dispatch({ type: TYPES_GAMIFICATION.SET_GAMIFICATION_COACHING_CATEGORIES_LOADING, isLoading: false });
      dispatch({
        type: TYPES_GAMIFICATION.GET_GAMIFICATION_COACHING_CATEGORIES,
        payload: res.payload,
      });
    });
  };
};

const getCoachingSurvey = () => {
  return (dispatch) => {
    dispatch({ type: TYPES_GAMIFICATION.SET_GAMIFICATION_COACHING_CATEGORIES_LOADING, isLoading: true });
    SERVICE_GAMIFICATION.getCoachingSurvey((res) => {
      dispatch({ type: TYPES_GAMIFICATION.SET_GAMIFICATION_COACHING_CATEGORIES_LOADING, isLoading: false });
      dispatch({
        type: TYPES_GAMIFICATION.GET_GAMIFICATION_COACHING_SURVEY,
        payload: res.payload,
      });
    });
  };
};

const requestCoaching = (coachingDate, categoryID, question, socialMedia, surveyId, surveyAnswer, callback) => {
  return (dispatch) => {
    dispatch({ type: TYPES_GAMIFICATION.SET_GAMIFICATION_REQUEST_COACHING_SUBMITTING_FORM, isLoading: true });
    SERVICE_GAMIFICATION.requestCoaching(
      coachingDate,
      categoryID,
      question,
      socialMedia,
      surveyId,
      surveyAnswer,
      (res) => {
        if (res.payload) callback(res.payload);
        dispatch({ type: TYPES_GAMIFICATION.SET_GAMIFICATION_REQUEST_COACHING_SUBMITTING_FORM, isLoading: false });
      }
    );
  };
};

export const ACTION_GAMIFICATION = {
  getPoint,
  getPointHistory,
  getCoachingHistory,
  getCoachingCategories,
  getCoachingSurvey,
  requestCoaching,
};
