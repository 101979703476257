import { TYPES_FOOTER } from './footer.types';

const INITIAL_STATE = {
  error: null,
  disclaimer: null,
  privacy: null,
};

const footerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES_FOOTER.GET_FOOTER_DISCLAIMER:
      return {
        ...state,
        disclaimer: action.payload,
        error: action.error,
      };
    case TYPES_FOOTER.GET_FOOTER_PRIVACY:
      return {
        ...state,
        privacy: action.payload,
        error: action.error,
      };
    default:
      return state;
  }
};

export default footerReducer;
