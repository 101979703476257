import { CONSTANTS } from '@/utils';
import store from 'store';
import { http } from './http';

const BASE_URL = '/videos';

export const SERVICE_VIDEOS = {
  getVideoListData: (page, limit, queries, callback) => {
    let searchQuery = null;
    let sortByQuery = null;
    let orderQuery = null;
    let categoryQuery = null;
    let params = [];

    if (queries && queries.search) searchQuery = `search=${queries.search}`;
    if (queries && queries.sort_by) sortByQuery = `sort_by=${queries.sort_by}`;
    if (queries && queries.order) orderQuery = `sort_dir=${queries.order}`;
    if (queries && queries.category_id) {
      categoryQuery = queries.category_id
        .split(',')
        .map((id) => `categories_id[]=${id}`)
        .join('&');
    }

    params.push(`page=${page}`);
    params.push(`limit=${limit}`);

    if (searchQuery) params.push(searchQuery);
    if (sortByQuery) params.push(sortByQuery);
    if (orderQuery) params.push(orderQuery);
    if (categoryQuery) params.push(categoryQuery);

    params = params.length ? params.join('&') : '';

    http
      .get(`${BASE_URL}?${params}`)
      .then((res) =>
        callback({ payload: res.data.payload, total: res.data.total, totalPage: res.data.total_page, error: null })
      )
      .catch((error) => callback({ payload: null, error: error.response }));
  },
  getVideoDetailData: (uid, callback) => {
    const userToken = store.get(CONSTANTS.PROFILE_TOKEN);
    const config = userToken ? { headers: { Authorization: `Bearer ${userToken}` } } : null;

    http
      .get(`${BASE_URL}/${uid}`, config)
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },
  getVideoRating: (uid, callback) => {
    const config = { headers: { Authorization: `Bearer ${store.get(CONSTANTS.PROFILE_TOKEN)}` } };

    http
      .get(`${BASE_URL}/${uid}/review`, config)
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },
  setVideoRating: (uid, commentBody, ratingValue, callback) => {
    http
      .post(
        `${BASE_URL}/${uid}/review`,
        {
          user_comment: commentBody,
          rating: ratingValue,
        },
        {
          headers: { Authorization: `Bearer ${store.get(CONSTANTS.PROFILE_TOKEN)}` },
        }
      )
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },
  setVideoProgress: (
    uuid,
    startedAt,
    pausedAt,
    skippedAt,
    skipPattern,
    totalDuration,
    playbackSpeed,
    finishedAt,
    callback
  ) => {
    const data = {
      paused_at: pausedAt,
      skiped_at: skippedAt,
      skip_pattern: skipPattern,
      total_duration: totalDuration,
      playback_speed: playbackSpeed,
      started_at: startedAt,
      finished_at: finishedAt,
    };

    const config = {
      headers: { Authorization: `Bearer ${store.get(CONSTANTS.PROFILE_TOKEN)}` },
    };

    http
      .post(`${BASE_URL}/${uuid}/progress`, data, config)
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },
  getVideoReviews: (uid, callback) => {
    http
      .get(`${BASE_URL}/${uid}/reviews`)
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },
};
