import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MdPerson } from 'react-icons/md';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Button, Container } from '@/components/atoms';
import PATH from '../path';

const LoginWarning = ({ documentTitle }) => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = documentTitle;
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send('pageview');
  }, []);

  return (
    <Container className="pt-28 pb-24 px-16 lg:py-36 sm:flex items-start sm:space-y-0 sm:space-x-8">
      <div className="flex flex-col lg:flex-row items-center lg:space-x-8 space-y-8">
        <div>
          <img src={require('@/images/icons/login_warning.png').default} alt="" />
        </div>
        <div className="lg:w-8/12">
          <div className="text-primary text-xl font-bold mb-10 text-center lg:text-left">Halo pembelajar!</div>
          <div className="text-gray-800 mb-6 text-center lg:text-left">
            Daftar dulu yuk untuk lanjutkan belajarnya. GRATIS dan mudah banget! Langsung klik aja link ini ya:{' '}
            <Link className="text-primary font-bold" to={PATH.AUTH_LOGIN}>
              Masuk
            </Link>{' '}
            atau{' '}
            <Link className="text-primary font-bold" to={PATH.AUTH_REGISTER}>
              {' '}
              Daftar
            </Link>
            .
          </div>
          <div className="mb-6">
            <div className="flex justify-center lg:justify-start space-x-3">
              <Button
                variant={Button.VARIANTS.PRIMARY_OUTLINE}
                size={Button.SIZES.MEDIUM}
                className="flex items-center"
                linkTo={PATH.AUTH_LOGIN}
              >
                <MdPerson className="mr-3" size={18} />
                {t('auth_login')}
              </Button>
              <Button linkTo={PATH.AUTH_REGISTER}>{t('auth_register')}</Button>
            </div>
          </div>
          <div>
            Yuk kita tumbuh! <br />
            <span className="text-gray-400">tim {window.location.hostname}</span>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default LoginWarning;
