import { ACTION_PROFILE } from '@/redux/actions';
import { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HeaderProductFilter } from './components/HeaderProductFilter';
import { HeaderProductFilterButton } from './components/HeaderProductFilterButton';
import { HeaderDesktop } from './HeaderDesktop';
import { HeaderMobile } from './HeaderMobile';

export const Header = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const { showHeaderProductFilter } = useSelector((state) => state.activity);
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const token = useSelector((state) => state.auth.token);
  const authProfile = useSelector((state) => state.auth.profile);
  const profile = useSelector((state) => state.profile.profile);

  const [isOpenProductFilter, setIsOpenProductFilter] = useState(false);

  useEffect(() => {
    if (token) dispatch(ACTION_PROFILE.getProfile());
  }, []);

  return (
    <>
      <header className="fixed bg-white shadow-lg top-0 w-full z-40">
        <div ref={ref}>
          <div style={{ height: 80 }}>
            <HeaderMobile loggedIn={loggedIn} authProfile={profile || authProfile} />
            <HeaderDesktop loggedIn={loggedIn} authProfile={profile || authProfile} />
          </div>
          <div className={`block md:hidden ${showHeaderProductFilter ? 'block' : 'hidden'}`}>
            <HeaderProductFilterButton onClick={() => setIsOpenProductFilter(!isOpenProductFilter)} />
          </div>
        </div>
        {isOpenProductFilter && (
          <div>
            <HeaderProductFilter onClick={() => setIsOpenProductFilter(false)} />
          </div>
        )}
      </header>
    </>
  );
});
