import { useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import { notify } from 'react-notify-toast';
import { FaEnvelope } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';
import { Button, InputText } from '@/components/atoms';
import PATH from '@/routes/path';
import { VALIDATION_SCHEMA } from '@/utils';
import { ACTION_ACTIVITY } from '@/redux/actions';
import { SERVICE_AUTH } from '@/services';
import AuthContainer from './components/AuthContainer';

const INITIAL_VALUES = { email: '', phone: '' };

const ForgotPassword = ({ documentTitle }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const FORGOT_PASSWORD_TYPE = ForgotPassword.TYPE.EMAIL;
  const FORGOT_PASSWORD_SCHEMA = VALIDATION_SCHEMA.emailSchema;

  const handleSubmit = (values) => {
    if (FORGOT_PASSWORD_TYPE === ForgotPassword.TYPE.PHONE) {
      notify.show(`value : ${values.phone}`, 'success');
      history.push(PATH.AUTH_OTP);
    } else if (FORGOT_PASSWORD_TYPE === ForgotPassword.TYPE.EMAIL) {
      dispatch(ACTION_ACTIVITY.showTopLoadingBar(true));

      const data = { email: values.email };

      SERVICE_AUTH.forgotPassword(data, (res) => {
        dispatch(ACTION_ACTIVITY.showTopLoadingBar(false));
        if (res.success) {
          notify.show(res.payload, 'success');
          history.push(PATH.AUTH_FORGOT_PASSWORD_EMAIL_SENT, data);
        }
      });
    }
  };

  useEffect(() => {
    document.title = documentTitle;
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send('pageview');
  }, []);

  return (
    <AuthContainer
      className="max-w-screen-md mx-auto"
      title="Atur Ulang Kata Sandi"
      description="Masukkan Email yang terdaftar pada akun Anda. Dan kami akan kirimkan formulir pengaturang ulang kata sandi ke email Anda."
      hideBanner
      hideGoHomeButton
    >
      <Formik initialValues={INITIAL_VALUES} onSubmit={handleSubmit} validationSchema={FORGOT_PASSWORD_SCHEMA}>
        {({ values, setFieldValue, getFieldMeta, handleChange }) => (
          <Form>
            <div className="mb-6">
              {FORGOT_PASSWORD_TYPE === ForgotPassword.TYPE.PHONE && (
                <InputText
                  variant={InputText.VARIANTS.MATERIAL}
                  className="mb-3"
                  name="phone"
                  icon={<span className="text-sm text-gray-400">+62</span>}
                  placeholder="Nomor Handphone"
                  onChange={(event) => {
                    const { value } = event.target;
                    setFieldValue('phone', value[0] === '0' ? value.substr(1, value.length) : value, true);
                  }}
                  value={values.phone}
                  meta={getFieldMeta('phone')}
                />
              )}
              {FORGOT_PASSWORD_TYPE === ForgotPassword.TYPE.EMAIL && (
                <InputText
                  label="Email"
                  required
                  className="mb-3"
                  name="email"
                  placeholder="Masukkan Email"
                  icon={<FaEnvelope />}
                  onChange={handleChange}
                  value={values.email}
                  meta={getFieldMeta('email')}
                />
              )}
            </div>
            <Button type="submit" className="w-full">
              Kirim
            </Button>
          </Form>
        )}
      </Formik>
    </AuthContainer>
  );
};

ForgotPassword.TYPE = {
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
};

export default ForgotPassword;
