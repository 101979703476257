import { useState, useEffect } from 'react';
import { FaEye, FaEyeSlash, FaWhatsapp } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { notify } from 'react-notify-toast';
import GoogleLogin from 'react-google-login';
import ReactGA from 'react-ga4';
import PATH from '@/routes/path';
import { ACTION_ACTIVITY, ACTION_AUTH } from '@/redux/actions';
import { CLIENT, KEY } from '@/config';
import { CONSTANTS, HELPERS, VALIDATION_SCHEMA } from '@/utils';
import { InputText, Button } from '@/components/atoms';
import { SERVICE_AUTH } from '@/services';
import AuthContainer from './components/AuthContainer';

const INITIAL_VALUES = {
  email: '',
  password: '',
};

const Login = ({ documentTitle }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const TOKEN = '';
  const API_TOKEN = '';

  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleLogin = (values) => {
    const data = {
      username: values.email,
      password: values.password,
      token: TOKEN,
      api_token: API_TOKEN,
      is_password_hidden: true,
      is_valid_username: true,
      set_failed_counter: 3,
      set_countdown: 60,
      activation_token: TOKEN,
      email: values.email,
      login_type: CONSTANTS.LOGIN_TYPE.EMAIL,
      client_token: KEY.CLIENT_TOKEN,
    };

    dispatch(
      ACTION_AUTH.loginByEmail(data, (action) => {
        ReactGA.event('login', { method: 'Email' });
        if (action.profile && action.profile.has_filled_survey) {
          notify.show(`Selamat datang kembali di ${CLIENT.NAME}.`, 'success');
        } else {
          history.push(PATH.PROFILE);
          notify.show(
            `Selamat datang kembali di ${CLIENT.NAME}. Silakan lengkapi data tambahan untuk melanjutkan.`,
            'success'
          );
        }
      })
    );
  };

  const handleGoogleLogin = (response) => {
    if (!response.error) {
      const { accessToken } = response;
      dispatch(
        ACTION_AUTH.loginByGoogle(accessToken, (action) => {
          ReactGA.event('login', { method: 'Google' });
          if (action.profile && action.profile.has_filled_survey) {
            notify.show(`Selamat datang kembali di ${CLIENT.NAME}.`, 'success');
          } else {
            history.push(PATH.PROFILE);
            notify.show(
              `Selamat datang kembali di ${CLIENT.NAME}. Silakan lengkapi data tambahan untuk melanjutkan.`,
              'success'
            );
          }
        })
      );
    }
  };

  const handleActivateAccount = ({ email, token }) => {
    dispatch(ACTION_ACTIVITY.showTopLoadingBar(true));

    SERVICE_AUTH.verifyEmail({ email, token }, (res) => {
      if (res.payload) {
        const notifyType = res.success ? 'success' : 'error';
        notify.show(res.payload, notifyType);
      }

      dispatch(ACTION_ACTIVITY.showTopLoadingBar(false));
    });
  };

  useEffect(() => {
    document.title = documentTitle;
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send('pageview');
  }, []);

  useEffect(() => {
    if (location.search) {
      const query = HELPERS.queryStringToObject(decodeURIComponent(location.search));

      if (query.message) {
        notify.show(query.message, 'warning');
        history.push(PATH.AUTH_LOGIN);
      }

      if (query.email && query.token) handleActivateAccount({ email: query.email, token: query.token });
    }
  }, [location]);

  return (
    <AuthContainer
      title="Masuk ke Akun Anda"
      description="Agar Kamu bisa rekam dan evaluasi perkembangan belajarmu, masuk dulu, yuk :)"
    >
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={handleLogin}
        validationSchema={VALIDATION_SCHEMA.loginSchema}
      >
        {({ values, handleChange, getFieldMeta }) => (
          <Form>
            <div className="w-full mb-7">
              <div className="space-y-3">
                <GoogleLogin
                  clientId={KEY.GOOGLE_CLIENT_ID}
                  style={{ backgroundColor: 'none', marginTop: 12 }}
                  css={{ backgroundColor: 'none', marginTop: 12 }}
                  className="bg-primary text-white w-full flex items-center justify-center py-3"
                  buttonText="Masuk Dengan Google"
                  cookiePolicy="single_host_origin"
                  theme="dark"
                  onSuccess={handleGoogleLogin}
                  onFailure={handleGoogleLogin}
                  render={({ onClick }) => (
                    <Button
                      variant={null}
                      size={Button.SIZES.LARGE}
                      className="bg-primary-900 text-white w-full flex items-center justify-center"
                      onClick={onClick}
                    >
                      <img
                        src={require('@/images/icons/google.png').default}
                        className="w-5 mr-3"
                        alt="Google Icon"
                      />{' '}
                      Masuk Dengan Google
                    </Button>
                  )}
                />
                <a href={process.env.REACT_APP_WA_MARKETA}>
                  <Button size={Button.SIZES.LARGE} className="w-full mt-3">
                    <IconContext.Provider value={{ className: 'mr-3 text-2xl' }}>
                      <div>
                        <FaWhatsapp />
                      </div>
                    </IconContext.Provider>
                    Masuk Dengan WhatsApp
                  </Button>
                </a>

                <div className="py-3 flex justify-center items-center">
                  <div className="text-sm">
                    <div className="border-b bg-black-200 w-20" />
                  </div>
                  <div className="mx-2 text-xs text-gray-300">Atau</div>
                  <div className="text-sm">
                    <div className="border-b bg-black-200 w-20" />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <InputText
                  label="Email"
                  containerClassName="mb-5"
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  required
                  meta={getFieldMeta('email')}
                  placeholder="Masukkan Email"
                />
                <InputText
                  label="Kata Sandi"
                  required
                  iconSuffix={
                    <button
                      className="hover:text-gray-400"
                      type="button"
                      onClick={() => setIsShowPassword(!isShowPassword)}
                    >
                      {isShowPassword ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
                    </button>
                  }
                  type={isShowPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange}
                  name="password"
                  meta={getFieldMeta('password')}
                  placeholder="Masukkan kata sandi minimal 8 karakter"
                />
              </div>

              <div className="text-right">
                <Link className="link" to={PATH.AUTH_FORGOT_PASSWORD}>
                  Lupa Kata Sandi?
                </Link>
              </div>
            </div>

            <div className="space-y-3">
              <Button className="w-full" size={Button.SIZES.LARGE} type="submit">
                Masuk
              </Button>
              <Button
                linkTo={PATH.AUTH_REGISTER}
                className="w-full"
                size={Button.SIZES.LARGE}
                variant={Button.VARIANTS.PRIMARY_OUTLINE}
              >
                Daftar
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </AuthContainer>
  );
};

export default Login;
