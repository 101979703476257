import PATH from '@/routes/path';
import { CONSTANTS } from '@/utils';
import { HiCheckCircle } from 'react-icons/hi';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

export const RiwayatBelajarProductListItem = ({ uuid, thumbnail, title, progress, type, activeProduct }) => {
  const link = activeProduct === CONSTANTS.PRODUCT_TYPE.VIDEO ? PATH.VIDEO : PATH.COURSE;

  return (
    <Link
      className="w-full inline-block p-5 hover:bg-gray-100 hover:bg-opacity-50 border-b"
      to={`${link}/${uuid}`}
    >
      <div className="flex space-x-3">
        <div className="w-1/3">
          <img
            className="w-full h-32 object-cover rounded-md"
            src={thumbnail || require('@/images/client/ukm-indonesia/course-4.png').default}
            alt=""
          />
        </div>
        <div className="flex flex-col items-stretch w-2/3 space-y-3">
          <div className="font-semibold">{title}</div>
          {type === RiwayatBelajarProductListItem.TYPES.ON_PROGRESS &&
            activeProduct === CONSTANTS.PRODUCT_TYPE.COURSE && (
              <div className="text-sm text-primary font-semibold">{progress}% diikuti</div>
            )}

          {type === RiwayatBelajarProductListItem.TYPES.FINISHED && (
            <div className="flex space-x-2 text-sm text-primary">
              <span>Selesai {activeProduct === CONSTANTS.PRODUCT_TYPE.VIDEO ? 'ditonton' : 'diikuti'}</span>
              <span className="text-primary">
                <HiCheckCircle size={20} />
              </span>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

RiwayatBelajarProductListItem.TYPES = {
  ON_PROGRESS: 'ON_PROGRESS',
  FINISHED: 'FINISHED',
};

RiwayatBelajarProductListItem.Skeleton = () => {
  return (
    <div className="flex p-5 space-x-3 border-b">
      <div className="sm:w-1/2 md:w-1/3 lg:w-1/2">
        <Skeleton height={128} />
      </div>
      <div className="w-full flex flex-col items-stretch">
        <Skeleton className="mb-3" height={30} />
        <Skeleton width={120} height={20} />
      </div>
    </div>
  );
};
