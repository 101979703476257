import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ConditionalWrapper } from '@/components/atoms';

export const ProductCard = ({ title, image, category, instructor, linkTo, className }) => {
  return (
    <div
      className={`mb-3 h-full bg-white rounded overflow-hidden transition-all shadow-md hover:shadow-lg ${className}`}
    >
      <ConditionalWrapper condition={!!linkTo} wrapper={(childs) => <Link to={linkTo}>{childs}</Link>}>
        <div className="h-48">
          <img className="object-cover object-center w-full h-full" src={image} alt={title} />
        </div>
        <div className="p-3">
          <h3 className="line-clamp-2 mb-3">{title}</h3>
          <p className="text-gray-500 text-xs">{category}</p>
          <p className="text-xs">{instructor}</p>
        </div>
      </ConditionalWrapper>
    </div>
  );
};

ProductCard.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  category: PropTypes.string,
  instructor: PropTypes.string,
  linkTo: PropTypes.string,
  className: PropTypes.string,
};

ProductCard.defaultProps = {
  title: '',
  image: null,
  category: '',
  instructor: '',
  linkTo: null,
  className: '',
};
