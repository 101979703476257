import { useDispatch, useSelector } from 'react-redux';
import { notify } from 'react-notify-toast';
import { TwitterShareButton, FacebookShareButton, WhatsappShareButton } from 'react-share';
import { FaFacebook, FaLink, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import moment from 'moment';
import ReactGA from 'react-ga4';
import { ACTION_ACTIVITY } from '@/redux/actions';
import { Button, Modal } from '@/components/atoms';

export const ShareModal = ({ open, onClose, moduleUuid = null, courseUuid = null }) => {
  const URL = window.location.href;

  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile.profile);

  const handleShare = (shareTo) => {
    const data = {
      user_id: profile === null ? null : profile.uuid,
      course_id: courseUuid,
      module_id: moduleUuid,
      share_to: shareTo,
      share_at: moment().format('DD/MM/YYYY HH:mm:ss'),
    };
    dispatch(
      ACTION_ACTIVITY.storeShare(data, () => {
        ReactGA.event('share', {
          method: shareTo.charAt(0).toUpperCase() + shareTo.slice(1),
          content_type: 'Link',
        });
      })
    );
  };

  const handleCopyLink = () => {
    const tempInput = document.createElement('input');
    const text = window.location.href;

    document.body.appendChild(tempInput);
    tempInput.value = text;
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    notify.show('Link berhasil dicopy!', 'success');
  };

  return (
    <Modal open={open} title="Bagikan Konten ini!" onClose={onClose}>
      <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 justify-center items-center bg-white py-3">
        <div className="mr-3">Bagikan Ke : </div>
        <div className="flex items-center space-x-3">
          <Button title="Copy Link" className="rounded-full" size={Button.SIZES.SQUARE} onClick={handleCopyLink}>
            <FaLink size={16} />
          </Button>
          <TwitterShareButton url={URL}>
            <Button className="rounded-full" size={Button.SIZES.SQUARE} onClick={() => handleShare('twitter')}>
              <FaTwitter size={16} />
            </Button>
          </TwitterShareButton>
          <FacebookShareButton url={URL}>
            <Button className="rounded-full" size={Button.SIZES.SQUARE} onClick={() => handleShare('facebook')}>
              <FaFacebook size={16} />
            </Button>
          </FacebookShareButton>
          <WhatsappShareButton url={URL}>
            <Button className="rounded-full" size={Button.SIZES.SQUARE} onClick={() => handleShare('whatsapp')}>
              <FaWhatsapp size={16} />
            </Button>
          </WhatsappShareButton>
        </div>
      </div>
    </Modal>
  );
};
