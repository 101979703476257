import { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import { Link, useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import ReactGA from 'react-ga4';
import PATH from '@/routes/path';
import { StarRating } from '@/components/atoms';

export const CourseCard = ({
  uuid,
  index,
  page,
  thumbnail,
  title,
  rating,
  view,
  video,
  description,
  categories,
}) => {
  const history = useHistory();
  const base64 = require('uuid-base64');

  const [shortDesc, setShortDesc] = useState('');
  // const [src, setSrc] = useState('');

  const urlRegex = /\s/g;
  const urlTitle = title.toLowerCase().replace(urlRegex, '_');

  const formatting = (num) => {
    const numArr = num.toString().split('');
    const totalIndex = numArr.length - 1;
    return numArr.map((el, id) => (totalIndex - id !== 0 && (totalIndex - id) % 3 === 0 ? `${el},` : el)).join('');
  };

  const id = base64.encode(uuid);

  const handleAnalytics = () => {
    const item = {
      item_id: uuid,
      item_name: title,
      index: index + 1,
      location_id: `page_${page}`,
    };

    const category = categories.map((el) => el.name);
    category.forEach((el, idx) => {
      if (idx === 0) item.item_category = el;
      else if (idx < 5) item[`item_category${idx + 1}`] = el;
    });

    const data = {
      item_list_name: 'Course List',
      items: [item],
    };

    ReactGA.event('select_item', data);
  };

  useEffect(() => {
    if (description) {
      let temp = description.split(' ').slice(0, 10).join(' ');
      if (!temp.includes('</p>')) {
        temp += '...</p>';
      }
      setShortDesc(temp);
    }
  }, [description]);

  // useEffect(() => {
  //   if (thumbnail) {
  //     console.log(thumbnail);
  //     const address = thumbnail.split('.com')[1];
  //     setSrc(`https://tumbu.superwa.io${address}`);
  //     console.log(src);
  //   }
  // }, [thumbnail]);

  return (
    <div className="p-5 transition-all bg-white rounded-md hover:shadow-md">
      <Link to={`${PATH.COURSE}/${id}/${urlTitle}` || `${PATH.VIDEO}/${uuid}`} onClick={handleAnalytics}>
        <div className="relative mb-7">
          <img className="relative z-10 object-contain w-full h-48 rounded-md" src={thumbnail} alt="" />
          <div className="absolute w-10/12 h-full transform -translate-x-1/2 rounded-md left-1/2 top-4 bg-primary-200" />
          <div className="absolute w-11/12 h-full transform -translate-x-1/2 rounded-md left-1/2 top-2 bg-primary-100" />
        </div>
        <h1 className="mb-3 text-xl font-semibold text-primary">{title}</h1>
        <div className="items-center space-y-3 xl:flex xl:space-y-0">
          <div className="flex items-center mr-3 space-x-1 text-lg text-secondary-500">
            <StarRating value={rating} />
          </div>
          <div className="text-sm text-gray-400">
            <strong className="text-gray-500">{video}</strong> video dengan&nbsp;
            <strong className="text-gray-500">{formatting(view)}</strong> penonton
          </div>
        </div>
        {description && <div className="my-2 text-sm">{parse(shortDesc)}</div>}
      </Link>
      {categories && (
        <div className="flex flex-wrap">
          {categories.map((category) => (
            <button
              key={category.id}
              onClick={() => history.push(`${PATH.COURSE}?category_id=${category.id}`)}
              type="button"
              className="px-3 py-2 mb-2 mr-2 text-xs rounded-full"
              style={{ backgroundColor: '#87DED4' }}
            >
              {category.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

CourseCard.Skeleton = () => {
  return (
    <div className="w-full p-3 bg-white rounded-md">
      <Skeleton height={200} />
      <Skeleton className="mt-3" width={120} height={20} />
      <Skeleton className="mt-3" height={20} />
    </div>
  );
};
