import { TYPES_FORMS } from './forms.types';

export const ACTION_FORMS = {
  handleChangeRegister: (values) => {
    return (dispatch) => {
      dispatch({
        type: TYPES_FORMS.FORM_REGISTER,
        values,
      });
    };
  },
};
