import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import parse from 'html-react-parser';
import ReactGA from 'react-ga4';
import PATH from '@/routes/path';
import { StarRating } from '@/components/atoms';

export const VideoCard = ({ uuid, index, page, thumbnail, title, description, rating, view, categories }) => {
  const DEFAULT_SRC = 'https://www.bastiaanmulder.nl/wp-content/uploads/2013/11/dummy-image-square.jpg';

  const history = useHistory();
  const base64 = require('uuid-base64');

  const [isLoading, setIsLoading] = useState(true);
  const [shortDesc, setShortDesc] = useState('');

  const urlRegex = /\s/g;
  const urlTitle = title.toLowerCase().replace(urlRegex, '_');
  const handleBrokenImage = (e) => {
    e.target.src = DEFAULT_SRC;
  };

  const formatting = (num) => {
    const numArr = num.toString().split('');
    const totalIndex = numArr.length - 1;
    return numArr.map((el, id) => (totalIndex - id !== 0 && (totalIndex - id) % 3 === 0 ? `${el},` : el)).join('');
  };

  const id = base64.encode(uuid);

  const handleAnalytics = () => {
    // const ud = base64.decode(id);
    // console.log(ud);
    const item = {
      item_id: uuid,
      item_name: title,
      index: index + 1,
      location_id: `page_${page}`,
    };

    const category = categories.map((el) => el.name);
    category.forEach((el, idx) => {
      if (idx === 0) item.item_category = el;
      else if (idx < 5) item[`item_category${idx + 1}`] = el;
    });

    const data = {
      item_list_name: 'Video List',
      items: [item],
    };

    ReactGA.event('select_item', data);
  };

  useEffect(() => {
    if (description) {
      let temp = description.split(' ').slice(0, 10).join(' ');
      if (!temp.includes('</p>')) {
        temp += '...</p>';
      }
      setShortDesc(temp);
    }
  }, [description]);

  return (
    <div>
      <div className="space-y-3">
        <Link to={`${PATH.VIDEO}/${id}/${urlTitle}` || `${PATH.VIDEO}/${uuid}`} onClick={handleAnalytics}>
          <div className="rounded-md overflow-hidden relative bg-primary-200">
            {isLoading && <Skeleton className="absolute left-0 top-0 w-full h-60" />}
            <img
              className="w-full h-60 object-contain"
              src={thumbnail || DEFAULT_SRC}
              onError={handleBrokenImage}
              onLoad={() => setIsLoading(false)}
              alt=""
            />
          </div>
          <h1 className="font-semibold text-xl text-primary-800 mt-2">{title}</h1>
          {/* {(rating || view) && ( */}
          <div className="mt-2 flex flex-wrap items-center">
            {/* {rating && ( */}
            <div className="mr-3">
              <StarRating value={rating} />
            </div>
            {/* )}
            {view && ( */}
            <div className="text-sm text-gray-400">
              Telah ditonton <strong className="text-gray-500">{formatting(view)}</strong> kali
            </div>
            {/* )} */}
          </div>
          {/* )} */}
          {description && <div className="text-sm my-2">{parse(shortDesc)}</div>}
        </Link>
        {categories && (
          <div className="flex flex-wrap">
            {categories.map((category) => (
              <button
                key={category.id}
                onClick={() => history.push(`${PATH.VIDEO}?category_id=${category.id}`)}
                type="button"
                className="mr-2 mb-2 px-3 py-2 rounded-full text-xs"
                style={{ backgroundColor: '#87DED4' }}
              >
                {category.name}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

VideoCard.Skeleton = () => {
  return (
    <div>
      <Skeleton height={220} />
      <div>
        <Skeleton height={15} />
        <Skeleton width={120} height={15} />
      </div>
      <div>
        <Skeleton height={15} count={2} />
      </div>
    </div>
  );
};
