// import { useEffect, useState, useCallback } from 'react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { MdInfoOutline } from 'react-icons/md';
import moment from 'moment';
import ReactGA from 'react-ga4';
import { Button, InputText, InputTextArea, InputSelect, Modal } from '@/components/atoms';
import PATH from '@/routes/path';
import { VALIDATION_SCHEMA } from '@/utils';
import { ACTION_GAMIFICATION } from '@/redux/actions';
// survey
import 'survey-core/defaultV2.css';
import { StylesManager, Model } from 'survey-core';
import { Survey } from 'survey-react-ui';

export const ModalBookingCoaching = ({ open, onClose, onRequestCoachingSuccess, point }) => {
  const dispatch = useDispatch();

  const INITIAL_VALUES = {
    coaching_date: '',
    theme: null,
    question: '',
    social_media: '',
  };

  const MAX_QUESTION_WORD_LENGTH = 20;

  const loading = useSelector((state) => state.gamification.coachingCategoriesDataLoading);
  const categoriesData = useSelector((state) => state.gamification.coachingCategoriesData);
  const SurveyData = useSelector((state) => state.gamification.coachingSurveyData);
  const isSubmitting = useSelector((state) => state.gamification.requestCoachingSubmittingForm);

  const [themeOptions, setThemeOptions] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submittedCoachingMessage, setSubmittedCoachingMessage] = useState('Booking Jadwal Coaching Sukses!');
  const [questionFeedback, setQuestionFeedback] = useState(null);
  const [surveyAnswer, setSurveyAnswer] = useState(null);
  const [isSurveyPage, setIsSurveyPage] = useState(true);

  const handleSubmitBookingCoaching = (value) => {
    const questionWordLength = value.question.split(' ');

    if (questionWordLength.length >= 20) {
      const coachingDate = moment(value.coaching_date).format('DD/MM/yyyy');
      const categoryID = value.theme.value;
      const { question } = value;
      const socialMedia = value.social_media;

      dispatch(
        ACTION_GAMIFICATION.requestCoaching(
          coachingDate,
          categoryID,
          question,
          socialMedia,
          SurveyData.id,
          surveyAnswer,
          (message) => {
            ReactGA.event('spend_virtual_currency', {
              value: point,
              virtual_currency_name: 'Point',
              item_name: 'Coaching',
            });
            setQuestionFeedback('');
            setSurveyAnswer(null);
            setSubmittedCoachingMessage(message);
            setIsSubmitted(true);
            onRequestCoachingSuccess();
          }
        )
      );
    } else setQuestionFeedback(`Pertanyaan minimal ${MAX_QUESTION_WORD_LENGTH} kata`);
  };

  const handleCloseModal = () => {
    setSurveyAnswer(null);
    setIsSurveyPage(true);
    if (!isSubmitting) onClose();
  };

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    dispatch(ACTION_GAMIFICATION.getCoachingCategories());
    dispatch(ACTION_GAMIFICATION.getCoachingSurvey());
  }, []);

  useEffect(() => {
    if (categoriesData) {
      setThemeOptions(
        categoriesData.map((category) => {
          return { label: category.name, value: category.id };
        })
      );
    }
  }, [categoriesData]);

  // survey
  const survey = new Model(SurveyData ? SurveyData.survey_json : null);
  survey.locale = 'id';
  survey.onCompleting.add((sender) => {
    const results = JSON.stringify(sender.data);
    console.log(results);
    setSurveyAnswer(results);
    setIsSurveyPage(false);
  });
  StylesManager.applyTheme('defaultV2');

  return (
    <div>
      <Modal
        open={open}
        onClose={handleCloseModal}
        title={isSubmitted ? submittedCoachingMessage : 'Booking Coaching'}
      >
        {isSubmitted && (
          <div className="space-y-6">
            <div className="text-center">
              Mohon tunggu konfirmasi dari kami <br /> melalui email atau telepon.
            </div>
            <div className="sm:flex justify-end space-y-3 sm:space-y-0 sm:space-x-3">
              <Button
                className="w-full"
                variant={Button.VARIANTS.PRIMARY_OUTLINE}
                onClick={() => {
                  onClose();
                  setIsSubmitted(false);
                }}
              >
                Tutup
              </Button>
              <Button className="w-full" linkTo={PATH.PROFILE_JADWAL_COACHING}>
                Lihat Riwayat Coaching
              </Button>
            </div>
          </div>
        )}

        {!loading && !isSubmitted && isSurveyPage && <Survey model={survey} />}

        {!loading && !isSubmitted && !isSurveyPage && (
          <div>
            <div className="flex items-center bg-gray-100 p-3 rounded-md border border-gray-200 space-x-3 mb-6">
              <MdInfoOutline className="text-secondary" size={22} />
              <div className="w-11/12 text-sm">Booking coaching akan mengurangi point Anda secara otomatis</div>
            </div>

            <Formik
              initialValues={INITIAL_VALUES}
              onSubmit={handleSubmitBookingCoaching}
              validationSchema={VALIDATION_SCHEMA.bookingCoachingSchema}
            >
              {({ values, getFieldMeta, handleSubmit, handleChange, setFieldValue }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="space-y-6">
                    <div className="space-y-3">
                      <InputText
                        type="date"
                        label="Preferensi Tanggal Coaching"
                        onChange={handleChange}
                        required
                        value={values.coaching_date}
                        name="coaching_date"
                        min={moment().add('days', 8).format('yyyy-MM-DD')}
                        meta={getFieldMeta('coaching_date')}
                        placeholder="Pilih tanggal"
                        disabled={isSubmitting}
                      />
                      <InputSelect
                        label="Tema"
                        onChange={(value) => setFieldValue('theme', value)}
                        required
                        options={themeOptions}
                        name="theme"
                        value={values.theme}
                        meta={getFieldMeta('theme')}
                        placeholder="Pilih tema"
                        loading={!themeOptions}
                        disabled={isSubmitting}
                      />
                      <InputTextArea
                        label="Pertanyaan"
                        onChange={handleChange}
                        required
                        value={values.question}
                        name="question"
                        meta={getFieldMeta('question')}
                        placeholder="Ajukan pertanyaan/fokus masalah minimal 20 kata"
                        feedback={questionFeedback}
                        disabled={isSubmitting}
                      />
                      <InputTextArea
                        label="Link Media Sosial"
                        onChange={handleChange}
                        value={values.social_media}
                        meta={getFieldMeta('social_media')}
                        name="social_media"
                        placeholder="Memberikan link media sosial bisnis Anda akan sangat membantu kami mengenal bisnis Anda lebih baik dan menentukan fasilitator yang sesuai."
                        disabled={isSubmitting}
                      />
                    </div>

                    <div className="flex justify-end space-x-3">
                      <Button
                        disabled={isSubmitting}
                        variant={Button.VARIANTS.PRIMARY_OUTLINE}
                        onClick={handleCloseModal}
                      >
                        Batal
                      </Button>
                      <Button loading={isSubmitting} onClick={handleSubmit}>
                        Kirim
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </Modal>
    </div>
  );
};
