import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Notification from 'react-notify-toast';
import TopLoader from 'react-top-loader';
import { COLOR } from '@/config';
import { Header, SideNav, CatalogMenu, Footer } from '@/components/organisms';
import FloatingWhatsApp from 'react-floating-whatsapp';
import './App.css';
import avatarWa from '@/images/avatar/avatarWa.jpeg';
import Routes from './routes';

import '@/styles/custom-notify-toast.css';
import { SkyduHelmet } from './components/atoms';

const App = () => {
  const history = useHistory();
  const headerRef = useRef();

  const { showTopLoadingBar, showHeader, showFooter } = useSelector((state) => state.activity);

  useEffect(() => {
    const unlisten = history.listen(() => window.scrollTo({ top: 0 }));
    return () => unlisten();
  }, []);

  return (
    <>
      <SkyduHelmet
        description="yuk kita tumbuh, dengan belajar bersama TUMBU"
        thumbnail="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHIAAAAbCAYAAACgJtvvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAATjSURBVHgB7VpNUuM6EG6bwIrimR1V/DzPCQZO8Mzy1SwG1m+Bc4LACYacIHCChMVbT7J6VW9DOMHkBihAqlhmqlgBsefrjGwcRxJ2jQOYyVelkiOplVa3Wt1q2yKJtf+aboUqPikQBnZ78OmfHv1m2Nzc9C3Laqr6rq6uLHpDqDw9VFzLtr+oBoU0Eqh+O0VCiR6VBHb0EAaPQ5qjtIgVOfhU7S0E9ocwCHZ/FqrTHKVBfLS6//+7PQqCmsW6DaxOYIXiTTmBOYyIFfk4Chz4SJ+fQ2vUh7EKmqM0iI/WR3oUyQ6LAkFzlAaxRd7+XRXE+pvEmz9dXYDrocRz4x2JPDSvMWdeWFtbWw3Ue5QPXdyjqmtra+7S0tK5asDCwoJ3eXnZV/XhfnaO0N5VdJ1i3hP076G/ke4MgqBzc3NzuLGx4aH/C8o2mp3EEBGGYffh4aF+e3srokYW8srKSg2PPoqbmpavVa37+/tOkoYB2bRQHSj4pNFotGPb9gF42FPMOQQfbZQz8NtV0evkHq1RRaOTC4MtUklkAhh0uYYSfZpehBFsQWDW03SPlaIRDkFwf2AxTfT7uum5j/mCoI4Sm6JJkwpPgjfDCWgO19fX9weDQab7MjbqN0O3w3xwwf+30htLQil3XqNuUp1cxnRULuwZlJhGA0JsYPxX0isxCRfKOedThgqE3FiFz5tG2RSZRSExIMS8p42zuLjYpOIxdkGRH50FbCy2S3PE4LQcTv9tKh4u/HRuN5YVdr/f3+UEMPzWrm4QfGKVx0Tl+vp6l14XAuWI+UJ9loWAgyAen4XG4MOn+MB8+/BrqywX1DvMF4ouaq3NyiorVD4IFpgQIhJWC4ENBygNA00Lm6+a/A0apq9pxn+k/HwQnpmPHiy6i83A0Xxaac7y8vI2biddKhhl85FsLdWk8BgcnZLeCjgSrCvaTqhgPiJIhZ5peJnFsV0+Rd7d3emuB0LTPoRgp/pUbXmgux9G4KNc05XF2nPjxRWJO9Uv+Qhd1gRBis4iX+X1HBT5ov87M0ViIauqdgh8ZiH4S+K5oEW3ThzJ38mMVcOcf+r6ZqZIXeQHZg7oHQBXCf+ZIZ9VjfCRgsz4S9XICQUYh6cjevpCwHAUaPKiTCNIj1o6m4FI8SBHZuato6bL1pjWiQ0e+XhBajign/jkhq0fCYWvZEB8/UBGY4g/0Y2rIQ/Z5jykTFg7COfbzBTSWjqacTYDabKeZJqjNY/eD6L1cXK8U6lUBBLpvEa2RF9DIxJB0gXp89THmNdD3ZNHNOdYjUd5rEiO4uTdSkXgcJIY/eMfMiIbK1be4XQhtauz5ncCXt8hFzYC1MbBkFs98dw1uRn54ZdHGZH2kaeUAfwnbJmSoUw0c4yTEq3oh3wWVBAmFCkvySIToW17EUP83o3mMKEHeR2lG9G2TwVdjyYUyZkKTM55VJGBNk5vQZk+qqxf3Q3ple52BUJQdmuqI/O0o8oCcQZI5mcFZYN2XEUxOQ/+IF/IspPlTETkAyMldOAT2kk6MHuMHGML7ewzPsJKk2/vBdp6aLsA4y0EBRwwTYXn6B9HdHwyYJ6QcgDjTzGnUHSZNs0RKTItzOczfAist85fomPMZ/miPZYRrwO88FpPdGm8eKIneSvnop/Ku2B5I6DqoT7G76mXzz8ATIc+80b/9z4AAAAASUVORK5CYII="
      />

      <Notification />
      <TopLoader
        show={showTopLoadingBar}
        backgroundColor="#ddd"
        thickness={4}
        zIndex={100}
        delay={0}
        color={COLOR.PRIMARY[500]}
        duration={1000}
      />

      {showHeader && <Header ref={headerRef} />}

      <SideNav />
      <CatalogMenu />

      <div style={{ marginTop: showHeader ? 80 : 0 }}>
        <Routes />
      </div>

      <FloatingWhatsApp
        className="position-floating"
        phoneNumber="06281181131555"
        accountName="Tumbuh Juwara"
        avatar={avatarWa}
      />
      {showFooter && <Footer />}
    </>
  );
};

export default App;
