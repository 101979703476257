/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

export const CardTotalStudyHours = ({ duration }) => {
  const [filteredDuration, setFilteredDuration] = useState(0);
  const [durationUnit, setDurationUnit] = useState('jam');

  useEffect(() => {
    if (duration) {
      const splitDuration = duration.split(':');
      const hours = splitDuration[0];
      const minutes = splitDuration[1];
      const seconds = splitDuration[2];

      if (hours > 0) {
        setFilteredDuration(hours);
        setDurationUnit('jam');
      } else if (minutes > 0) {
        setFilteredDuration(minutes);
        setDurationUnit('menit');
      } else if (seconds > 0) {
        setFilteredDuration(minutes);
        setDurationUnit('detik');
      } else {
        setFilteredDuration(0);
        setDurationUnit('jam');
      }
    }
  }, [duration]);

  return (
    <div className="relative w-full flex items-end bg-primary rounded-md p-5 text-white">
      <div className="absolute left-0 top-0 h-8">
        <img
          className="w-full h-full"
          src={require('@/images/client/ukm-indonesia/point-ornament.png').default}
          alt=""
        />
      </div>
      <div className="font-semibold w-full">Total Jam Belajar Saya</div>
      {!duration && <Skeleton width={80} height={50} />}
      {duration && (
        <div className="flex items-center">
          <div className="flex flex-col items-center">
            <div className="text-4xl font-semibold">{filteredDuration}</div>
            <div>{durationUnit}</div>
          </div>
          {/* <div className="text-3xl font-semibold">+</div> */}
        </div>
      )}
    </div>
  );
};
