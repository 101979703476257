import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import parse, { domToReact, attributesToProps } from 'html-react-parser';
import ReactGA from 'react-ga4';
import { ACTION_VIDEOS } from '@/redux/actions';
import { Comment, ProductResource, ShareModal, SideMenu } from '@/components/molecules';
import { CONSTANTS } from '@/utils';
import { Container, SkyduHelmet, StarRating } from '@/components/atoms';
import { FaShare, FaEllipsisH } from 'react-icons/fa';
import moment from 'moment';

const VideoDetail = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const base64 = require('uuid-base64');

  let uuid;
  const titleParams = params.title;
  if (titleParams) {
    uuid = base64.decode(params.uid);
  } else {
    uuid = params.uid;
  }
  // const uuid = base64.decode(params.uid);
  console.log(uuid);
  const idLocale = require('moment/locale/id');

  // useSelector Group
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const data = useSelector((state) => state.videos.detailData);
  const isLoading = useSelector((state) => state.videos.detailLoading);
  // const ratingData = useSelector((state) => state.videos.reviewsData);

  // useState Group
  // const [relatedVideosData, setRelatedVideosData] = useState(null);
  const [singleVideoData, setSingleVideoData] = useState(null);
  const [categories, setCategories] = useState('');
  const [categoriesName, setCategoriesName] = useState([]);
  const [topicListData, setTopicListData] = useState(null);
  const [activeTopic, setActiveTopic] = useState(null);
  // description
  const [descHeight, setDescHeight] = useState('');
  const [readMore, setReadMore] = useState(false);
  // rating
  // const [rating, setRating] = useState(0);
  // Share
  const [isOpenShareModal, setIsOpenShareModal] = useState(false);

  // function Group
  const handleCloseModal = () => {
    setIsOpenShareModal(false);
  };

  // useEffect Group

  useEffect(() => {
    dispatch(ACTION_VIDEOS.getVideoDetailData(uuid));
    // dispatch(ACTION_VIDEOS.getVideoReviewsData(uuid));
  }, [uuid]);

  useEffect(() => {
    if (data) {
      ReactGA.initialize(process.env.REACT_APP_GA_ID);
      ReactGA.send('pageview');
    }
    // if (data?.related_videos) setRelatedVideosData(data.related_videos);
    if (data?.video) {
      setSingleVideoData(data.video);
      setCategories(data.video.categories.map((el) => el.id).join(','));
      setCategoriesName(data.video.categories.map((el) => el.name));
    }
    if (data?.video?.sections) setTopicListData(data.video.sections.filter((section) => section !== null));
  }, [data]);

  useEffect(() => {
    if (topicListData) {
      const unfinishedTopicIndex = topicListData.findIndex((topic) => {
        return !topic.is_completed && !topic.is_locked;
      });

      if (!loggedIn) setActiveTopic(topicListData[0]);
      else if (unfinishedTopicIndex >= 0) setActiveTopic(topicListData[unfinishedTopicIndex]);
      else setActiveTopic(topicListData[topicListData.length - 1]);
    }
  }, [topicListData]);

  useEffect(() => {
    if (readMore) {
      setDescHeight('');
    } else {
      setDescHeight('h-16');
    }
  }, [readMore]);

  // useEffect(() => {
  //   if (ratingData) {
  //     if (ratingData.length > 0) {
  //       const totalRating = ratingData.reduce((a, b) => a + b.rating, 0);
  //       setRating(totalRating / ratingData.length);
  //     } else {
  //       setRating(0);
  //     }
  //   }
  // }, [ratingData]);

  // parser styling
  const options = {
    replace: ({ name, children, attribs }) => {
      if (name === 'ol') {
        return <div className="list-decimal pl-5">{domToReact(children, options)}</div>;
      }
      if (name === 'ul') {
        return <div className="list-disc pl-5">{domToReact(children, options)}</div>;
      }
      if (name === 'h1') {
        return <h1 className="text-2xl">{domToReact(children, options)}</h1>;
      }
      if (name === 'h2') {
        return <h2 className="text-xl">{domToReact(children, options)}</h2>;
      }
      if (name === 'h3') {
        return <h3 className="text-lg">{domToReact(children, options)}</h3>;
      }
      if (name === 'h4') {
        return <h4 className="text-base">{domToReact(children, options)}</h4>;
      }
      if (name === 'h5') {
        return <h5 className="text-sm">{domToReact(children, options)}</h5>;
      }
      if (name === 'h6') {
        return <h6 className="text-xs">{domToReact(children, options)}</h6>;
      }
      if (name === 'hr') {
        return <hr className="border-gray-500" />;
      }
      if (name === 'a') {
        const props = attributesToProps(attribs);
        return (
          <a {...props} className="text-primary underline">
            {domToReact(children, options)}
          </a>
        );
      }
      if (name === 'table') {
        const props = attributesToProps(attribs);
        return (
          <table {...props} className="table border-collapse">
            {domToReact(children, options)}
          </table>
        );
      }
      if (name === 'caption') {
        return <caption className="font-bold">{domToReact(children, options)}</caption>;
      }
      if (name === 'td') {
        return <td className="border border-gray-500">{domToReact(children, options)}</td>;
      }
      return null;
    },
  };

  return (
    <div className="bg-gray-100 pb-5 pt-0 lg:pt-5">
      <SkyduHelmet
        title={data && `Tumbu - ${data.video.title}`}
        description={data && data.video.description}
        thumbnail={data && data.video.thumbnail}
        url={window.location.href}
      />

      <ShareModal moduleUuid={uuid} open={isOpenShareModal} onClose={handleCloseModal} />

      <Container className="lg:flex relative px-0 lg:px-4">
        <div className="w-full lg:w-7/12 lg:pr-5 mb-5">
          <ProductResource
            productUuid={uuid}
            productType={CONSTANTS.PRODUCT_TYPE.VIDEO}
            thumbnail={singleVideoData && singleVideoData.thumbnail}
            isLoading={isLoading}
            activeTopic={activeTopic}
          />
          {activeTopic &&
            activeTopic.resource_type !== CONSTANTS.RESOURCE_TYPE.QUIZ &&
            activeTopic.resource_type !== CONSTANTS.RESOURCE_TYPE.WORKING_PAPER && (
              <div className="px-5">
                <div className="pb-5 border-b border-gray-400">
                  <h1 className="my-3 text-base sm:text-xl font-medium">{singleVideoData.title}</h1>
                  <div className="flex justify-between">
                    <div className="flex items-center space-x-2 sm:space-x-3 text-xs sm:text-base">
                      <span>
                        Ditonton&nbsp;
                        {singleVideoData && singleVideoData?.analytic?.total_view
                          ? singleVideoData?.analytic?.total_view
                          : 0}
                        &nbsp;kali
                      </span>
                      <span>
                        {moment(singleVideoData.created_at).locale('id', idLocale).format('D MMMM YYYY')}
                      </span>
                      <div className="hidden sm:block">
                        <StarRating
                          value={
                            singleVideoData?.analytic?.average_rating
                              ? singleVideoData?.analytic?.average_rating
                              : 0
                          }
                        />
                      </div>
                      <div className="block sm:hidden">
                        <StarRating
                          value={
                            singleVideoData?.analytic?.average_rating
                              ? singleVideoData?.analytic?.average_rating
                              : 0
                          }
                          size={12}
                        />
                      </div>
                    </div>
                    <div className="flex space-x-3 sm:space-x-5 mr-0 md:mr-3">
                      <button
                        type="button"
                        className="flex items-center space-x-2 text-gray-500 hover:text-black"
                        onClick={() => setIsOpenShareModal(true)}
                      >
                        <FaShare />
                        <span className="hidden md:block">SHARE</span>
                      </button>
                      <button type="button" className="mr-3 space-x-2 text-gray-500 hover:text-black">
                        <FaEllipsisH />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="py-5 border-b border-gray-400">
                  <div className={`${descHeight} overflow-hidden space-y-3`}>
                    <p className="mb-3 font-bold">Deskripsi Video</p>
                    {parse(singleVideoData.description, options)}
                  </div>
                  {readMore ? (
                    <button
                      type="button"
                      className="mt-3 text-gray-500 hover:text-black"
                      onClick={() => setReadMore(false)}
                    >
                      SHOW LESS
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="mt-3 text-gray-500 hover:text-black"
                      onClick={() => setReadMore(true)}
                    >
                      SHOW MORE
                    </button>
                  )}
                </div>
                {loggedIn && <Comment uuid={uuid} productType={CONSTANTS.PRODUCT_TYPE.VIDEO} />}
              </div>
            )}
        </div>
        <SideMenu
          type={CONSTANTS.PRODUCT_TYPE.VIDEO}
          topicListData={topicListData}
          activeTopic={activeTopic}
          isLoading={isLoading}
          onChangeActiveTopic={(topic) => setActiveTopic(topic)}
          categories={categories}
          categoriesName={categoriesName}
        />
      </Container>
    </div>
  );
};

export default VideoDetail;
