import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { notify } from 'react-notify-toast';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Button, InputText } from '@/components/atoms';
import PATH from '@/routes/path';
import { ACTION_ACTIVITY } from '@/redux/actions';
import { HELPERS, VALIDATION_SCHEMA } from '@/utils';
import { SERVICE_AUTH } from '@/services';
import AuthContainer from './components/AuthContainer';

const INITIAL_VALUES = { password: '', confirmPassword: '' };

const ResetPassword = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [email, setEmail] = useState(null);
  const [token, setToken] = useState(null);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);

  const FORGOT_PASSWORD_SCHEMA = VALIDATION_SCHEMA.resetPasswordSchema;

  const handleSubmit = (values) => {
    dispatch(ACTION_ACTIVITY.showTopLoadingBar(true));

    const data = {
      email,
      token,
      password: values.password,
    };

    SERVICE_AUTH.resetPassword(data, (res) => {
      if (res.success) {
        notify.show(res.payload, 'success');
        history.push(PATH.AUTH_LOGIN);
      } else notify.show(`Atur ulang kata sandi gagal`, 'error');

      dispatch(ACTION_ACTIVITY.showTopLoadingBar(false));
    });
  };

  useEffect(() => {
    if (location.search) {
      const query = HELPERS.queryStringToObject(location.search);

      if (query.email && query.token) {
        setEmail(decodeURIComponent(query.email));
        setToken(query.token);
      } else history.push(PATH.HOME);
    } else history.push(PATH.HOME);
  }, [location]);

  return (
    <AuthContainer
      className="max-w-screen-md mx-auto"
      title="Atur Ulang Kata Sandi"
      description="Masukkan Email yang terdaftar pada akun Anda. Dan kami akan kirimkan formulir pengaturang ulang kata sandi ke email Anda."
      hideBanner
      hideGoHomeButton
    >
      <Formik initialValues={INITIAL_VALUES} onSubmit={handleSubmit} validationSchema={FORGOT_PASSWORD_SCHEMA}>
        {({ values, getFieldMeta, handleChange }) => (
          <Form>
            <div className="mb-6 space-y-6">
              <InputText
                label="Kata Sandi"
                required
                className="mb-3"
                name="password"
                type={isShowPassword ? 'text' : 'password'}
                placeholder="Masukkan Kata Sandi Baru"
                iconSuffix={
                  <button
                    className="hover:text-gray-400"
                    type="button"
                    onClick={() => setIsShowPassword(!isShowPassword)}
                  >
                    {isShowPassword ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
                  </button>
                }
                onChange={handleChange}
                value={values.password}
                meta={getFieldMeta('password')}
              />
              <InputText
                label="Konfirmasi Kata Sandi"
                required
                type={isShowConfirmPassword ? 'text' : 'password'}
                className="mb-3"
                iconSuffix={
                  <button
                    className="hover:text-gray-400"
                    type="button"
                    onClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}
                  >
                    {isShowConfirmPassword ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
                  </button>
                }
                name="confirmPassword"
                placeholder="Konfirmasi kata sandi"
                onChange={handleChange}
                value={values.confirmPassword}
                meta={getFieldMeta('confirmPassword')}
              />
            </div>
            <Button type="submit" className="w-full">
              Atur Ulang
            </Button>
          </Form>
        )}
      </Formik>
    </AuthContainer>
  );
};

export default ResetPassword;
