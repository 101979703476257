import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { MdFilterList } from 'react-icons/md';

export const HeaderProductFilterButton = ({ isOpen, onClick }) => {
  return (
    <div className="relative w-full bg-white border-t border-b px-6">
      <button className="flex justify-between w-full py-5" type="button" onClick={onClick}>
        <div className="flex space-x-3">
          <MdFilterList size={20} />
          <span>Filter</span>
        </div>
        {isOpen ? <FiChevronUp size={16} /> : <FiChevronDown size={16} />}
      </button>
    </div>
  );
};

HeaderProductFilterButton.defaultProps = {
  isOpen: false,
};
