export const TYPES_GAMIFICATION = {
  GET_GAMIFICATION_POINT: 'GET_GAMIFICATION_POINT',
  GET_GAMIFICATION_POINT_HISTORY: 'GET_GAMIFICATION_POINT_HISTORY',
  SET_GAMIFICATION_POINT_HISTORY_LOADING: 'SET_GAMIFICATION_POINT_HISTORY_LOADING',
  SET_GAMIFICATION_COACHING_HISTORY_LOADING: 'SET_GAMIFICATION_COACHING_HISTORY_LOADING',
  GET_GAMIFICATION_COACHING_HISTORY: 'GET_GAMIFICATION_COACHING_HISTORY',
  SET_GAMIFICATION_COACHING_CATEGORIES_LOADING: 'SET_GAMIFICATION_COACHING_CATEGORIES_LOADING',
  GET_GAMIFICATION_COACHING_CATEGORIES: 'GET_GAMIFICATION_COACHING_CATEGORIES',
  GET_GAMIFICATION_COACHING_SURVEY: 'GET_GAMIFICATION_COACHING_SURVEY',
  SET_GAMIFICATION_REQUEST_COACHING_SUBMITTING_FORM: 'SET_GAMIFICATION_REQUEST_COACHING_SUBMITTING_FORM',
};
