import { useRef, useEffect } from 'react';
import { FaDownload } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ReactToPdf from 'react-to-pdf';
import ReactGA from 'react-ga4';
import { Button, CompanyLogo, Container } from '@/components/atoms';
import { ACTION_COURSES } from '@/redux/actions';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import { ProductTabUlasan } from '@/components/molecules';
import { CONSTANTS } from '@/utils';

const Certificate = ({ documentTitle }) => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const uuid = params.uid;
  const api = process.env.REACT_APP_API_BASE_URL;

  const certificateData = useSelector((state) => state.courses.certificateData);
  const certificateLoading = useSelector((state) => state.courses.certificateLoading);

  const certificateRef = useRef();

  const CERTIFICATE_WIDTH = 1123;
  const CERTIFICATE_HEIGHT = 794;
  const CERTIFICATE_OPTIONS = {
    orientation: 'landscape',
  };

  const handleClickBack = () => {
    history.goBack();
  };

  useEffect(() => {
    document.title = documentTitle;
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send('pageview');
  }, []);

  useEffect(() => {
    if (uuid) dispatch(ACTION_COURSES.getCourseCertificate(uuid));
  }, [uuid]);

  return (
    <div className="bg-gray-100 py-5">
      <Container className="space-y-6">
        <div className="bg-white shadow-md">
          <div className="p-6 border-b">
            {(certificateLoading || !certificateData) && <Skeleton height={50} />}
            {!certificateLoading && certificateData && (
              <h1 className="text-xl font-bold">{certificateData.title}</h1>
            )}
          </div>
          <div className="p-6 overflow-scroll">
            {(certificateLoading || !certificateData) && (
              <Skeleton height={CERTIFICATE_HEIGHT} width={CERTIFICATE_WIDTH} />
            )}
            {!certificateLoading && certificateData && !certificateData.template && (
              <div
                ref={certificateRef}
                className="relative flex flex-col items-center rounded-md justify-center text-center bg-gray-100 p-20 space-y-10"
                style={{ width: CERTIFICATE_WIDTH, height: CERTIFICATE_HEIGHT }}
              >
                <div>
                  <CompanyLogo width={120} link />
                </div>
                <div>
                  {(certificateLoading || !certificateData) && <Skeleton height={70} />}
                  {!certificateLoading && certificateData && (
                    <h1 className="text-6xl font-bold">{certificateData.student_name}</h1>
                  )}
                </div>
                <div className="space-y-1">
                  <div>Telah menyelesaikan kursus berjudul</div>
                  {(certificateLoading || !certificateData) && <Skeleton height={50} />}
                  {!certificateLoading && certificateData && (
                    <div className="text-3xl text-primary font-semibold">{certificateData.title}</div>
                  )}
                </div>
                <div className="space-y-1 text-lg">
                  {(certificateLoading || !certificateData) && <Skeleton height={30} />}
                  {!certificateLoading && certificateData && (
                    <div>
                      Dengan Nilai : <strong>{certificateData.final_score}</strong>
                    </div>
                  )}
                  <div>Pada Modul yang resmi terdaftar dalam aplikasi</div>
                </div>
                <div className="absolute left-1/2 transform -translate-x-1/2 bottom-12">
                  <div className="text-xs text-center">
                    {(certificateLoading || !certificateData) && <Skeleton height={15} />}
                    {!certificateLoading &&
                      certificateData &&
                      moment(certificateData.completed_at).locale('id').format('DD MMMM YYYY')}
                    <br />
                    {(certificateLoading || !certificateData) && <Skeleton height={15} />}
                    {!certificateLoading && certificateData && certificateData.certificate_number}
                  </div>
                </div>
                <div className="absolute left-5 bottom-5">
                  <img className="w-28" src={require('@/images/icons/certificate-icon.png').default} alt="" />
                </div>
              </div>
            )}
            {!certificateLoading && certificateData && certificateData.template && (
              <div
                id="divToPrint"
                className="relative flex flex-col items-center rounded-md justify-center text-center space-y-10"
                style={{
                  width: CERTIFICATE_WIDTH,
                  height: CERTIFICATE_HEIGHT,
                  // backgroundImage: `url(https://astro-cors-server.herokuapp.com/fetch/${certificateData.template})`,
                  // backgroundSize: 'auto 100%',
                  // backgroundRepeat: 'no-repeat',
                }}
              >
                <img
                  // src={`https://tumbu.superwa.io/${certificateData.template.split('com/')[1]}`}
                  src={certificateData.template}
                  className="absolute"
                  style={{ width: CERTIFICATE_WIDTH, height: CERTIFICATE_HEIGHT }}
                  alt=""
                />
                <div
                  className="absolute flex justify-center items-center top-52"
                  style={{ width: CERTIFICATE_WIDTH - 250, height: 150 }}
                >
                  <span className="text-5xl font-medium">{certificateData.student_name}</span>
                </div>
              </div>
            )}
          </div>
          <div className="px-6 py-3 flex items-center border-t justify-center space-x-3">
            <Button variant={Button.VARIANTS.PRIMARY_OUTLINE} onClick={handleClickBack}>
              Kembali
            </Button>
            {certificateData && !certificateData.template && (
              <ReactToPdf
                targetRef={certificateRef}
                filename={`Certificate_${certificateData.title}_${certificateData.student_name}_${moment(
                  certificateData.completed_at
                )
                  .locale('id')
                  .format('DD MMMM YYYY')}.pdf`}
                options={CERTIFICATE_OPTIONS}
              >
                {({ toPdf }) => (
                  <Button className="space-x-3 border-t" onClick={toPdf}>
                    <span>
                      <FaDownload />
                    </span>
                    <span>Unduh Sertifikat</span>
                  </Button>
                )}
              </ReactToPdf>
            )}
            {certificateData && certificateData.template && (
              <a
                href={`${api}/courses/${uuid}/certificate/${certificateData.student_name}/Certificate_${
                  certificateData.title
                }_${certificateData.student_name}_${moment(certificateData.completed_at)
                  .locale('id')
                  .format('DD MMMM YYYY')}.pdf`}
              >
                <Button className="space-x-3 border-t">
                  <span>
                    <FaDownload />
                  </span>
                  <span>Unduh Sertifikat</span>
                </Button>
              </a>
            )}
          </div>
        </div>
        <ProductTabUlasan className="p-6" uuid={uuid} productType={CONSTANTS.PRODUCT_TYPE.COURSE} />
      </Container>
    </div>
  );
};

export default Certificate;
