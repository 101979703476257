import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Container } from '@/components/atoms';

const AboutUs = ({ documentTitle }) => {
  useEffect(() => {
    document.title = documentTitle;
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send('pageview');
  }, []);

  return (
    <div className="bg-gray-100 py-5">
      <Container>
        <div className="px-5 py-10 space-y-10 bg-white shadow-md">
          <h1 className="text-2xl text-center font-bold">Tentang Kami</h1>
          <div className="space-y-3">Halaman Tentang Kami</div>
        </div>
      </Container>
    </div>
  );
};

export default AboutUs;
