import { http } from './http';

const BASE_URL = '/categories';

export const SERVICE_CATEGORIES = {
  getCategoriesData: (callback) => {
    http
      .get(BASE_URL)
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },
};
