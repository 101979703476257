import { TYPES_AUTH } from './auth.types';

const INITIAL_STATES = {
  profile: null,
  token: null,
  loggedIn: false,
};

const authReducer = (state = INITIAL_STATES, action) => {
  switch (action.type) {
    case TYPES_AUTH.LOGIN:
      return {
        profile: action.profile,
        token: action.token,
        loggedIn: action.token !== null,
      };
    case TYPES_AUTH.LOGOUT:
      return {
        profile: null,
        token: null,
        loggedIn: false,
      };
    default:
      return state;
  }
};

export default authReducer;
