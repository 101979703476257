import { SERVICE_CATEGORIES } from '@/services';
import { TYPES_CATEGORIES } from './categories.types';

export const ACTION_CATEGORIES = {
  getCategoriesData: () => {
    return (dispatch) => {
      SERVICE_CATEGORIES.getCategoriesData((res) => {
        dispatch({
          type: TYPES_CATEGORIES.GET_CATEGORIES_DATA,
          payload: res.payload,
          error: res.error,
        });
      });
    };
  },
};
