import { TYPES_CATEGORIES } from './categories.types';

const INITIAL_STATE = {
  data: null,
  error: null,
  loading: true,
};

const categoriesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES_CATEGORIES.SET_CATEGORIES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case TYPES_CATEGORIES.GET_CATEGORIES_DATA:
      return {
        ...state,
        data: action.payload,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default categoriesReducer;
