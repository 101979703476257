import { useEffect } from 'react';
// import { FaEnvelope, FaPhone, FaLocationArrow } from 'react-icons/fa';
import moment from 'moment';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { CompanyLogo, Container } from '@/components/atoms';
import { CLIENT } from '@/config';
import { ACTION_HOME, ACTION_COURSES } from '@/redux/actions';
import { Link } from 'react-router-dom';
import PATH from '@/routes/path';
import { HELPERS } from '@/utils';
import './footer.css';

export const Footer = () => {
  const dispatch = useDispatch();

  const year = moment().format('y');

  const homeData = useSelector((state) => state.home.data);
  const footerData = homeData ? homeData.footer : null;
  const courses = useSelector((state) => state.courses.listData);

  // const [courseList, setCourseList] = useState(null);

  useEffect(() => {
    dispatch(ACTION_HOME.getHomeData());
    dispatch(ACTION_COURSES.getCourseListData(1, 4, HELPERS.queryStringToObject('?sort_by=id&order=desc')));
  }, []);

  // useEffect(() => {
  //   if (homeData) {
  //     setCourseList(homeData.sections.reduce((accumulator, section) => section.items));
  //   }
  // }, [homeData]);

  return (
    <>
      <div className="shadow-2xl py-24 text-white" style={{ backgroundColor: '#393E46' }}>
        <Container>
          <div className="grid grid-container-sm md:grid md:grid-container-md lg:flex lg:flex-row space-y-10 lg:space-y-0 lg:space-x-16">
            <div className="desc lg:w-1/2 mt-10 lg:mt-0">
              <div className="mb-6">
                <CompanyLogo />
              </div>
              <div className="mb-6 xl:w-2/3">
                {/* <div className="text-xl font-semibold text-gray-800 mb-3">Tentang Tumbu</div> */}
                <p className="text-sm leading-relaxed">
                  {footerData ? footerData.items.description : CLIENT.DESCRIPTION}
                </p>
              </div>
              <div className="flex space-x-5">
                <button type="button" className="h-8 w-8 flex justify-center items-center border border-white">
                  <FaFacebookF />
                </button>
                <button type="button" className="h-8 w-8 flex justify-center items-center border border-white">
                  <FaTwitter />
                </button>
                <button type="button" className="h-8 w-8 flex justify-center items-center border border-white">
                  <FaLinkedinIn />
                </button>
                <button type="button" className="h-8 w-8 flex justify-center items-center border border-white">
                  <FaInstagram />
                </button>
              </div>
            </div>

            <div className="lg:w-1/2 md:flex space-y-5 md:space-y-0">
              {/* <div>
                <div className="text-xl font-semibold mb-3">Kontak Kami</div>
                <div className="space-y-3 text-sm">
                  <table>
                    <tbody>
                      <tr className="inline-block w-full mb-3">
                        <td>
                          <FaLocationArrow size={14} className="mr-3 auto-cols-min" />
                        </td>
                        <td>{footerData ? footerData.items.address : CLIENT.ADDRESS}</td>
                      </tr>
                      <tr className="inline-block w-full mb-3">
                        <td>
                          <FaEnvelope size={14} className="mr-3 auto-cols-min" />
                        </td>
                        <td>{footerData ? footerData.items.email : CLIENT.EMAIL}</td>
                      </tr>
                      <tr className="inline-block w-full mb-3">
                        <td>
                          <FaPhone size={14} className="mr-3 auto-cols-min" />
                        </td>
                        <td>{footerData ? footerData.items.phone_number : CLIENT.PHONE}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> */}
              <div className="home flex md:w-2/3">
                <div className="w-1/3">
                  <div className="text-xl font-semibold mb-6">
                    <Link to={PATH.HOME}>Home</Link>
                  </div>
                  <div className="space-y-3 text-sm">
                    <p>
                      <Link to={PATH.COURSE}>Kursus</Link>
                    </p>
                    <p>
                      <Link to={PATH.VIDEO}>Video</Link>
                    </p>
                    <p>
                      <Link to={PATH.AUTH_LOGIN}>Login</Link>
                    </p>
                    <p>
                      <Link to={PATH.AUTH_REGISTER}>Daftar</Link>
                    </p>
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="text-xl font-semibold mb-6">
                    <Link to={PATH.COURSE}>Kursus</Link>
                  </div>
                  <div className="space-y-3 text-sm">
                    {courses &&
                      courses.slice(0, 4).map((course) => (
                        <p key={course.uuid}>
                          <Link to={`${PATH.COURSE}/${course.uuid}`}>{course.title}</Link>
                        </p>
                      ))}
                  </div>
                </div>
              </div>
              <div className="contact md:w-1/3">
                <div className="text-xl font-semibold mb-6">Kontak Kami</div>
                <div className="space-y-3 text-sm">
                  <p>{footerData ? footerData.items.email : CLIENT.EMAIL}</p>
                  <p>{footerData ? footerData.items.phone_number : CLIENT.PHONE}</p>
                  <p>{footerData ? footerData.items.address : CLIENT.ADDRESS}</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="bg-primary-700 py-3">
        <Container Container className="grid grid-container-sm xl:flex justify-between">
          <div className="text-sm text-left text-white w-1/3 mb-3">
            Copyright &copy; {year} | {CLIENT.NAME} - {CLIENT.NAME_SECONDARY}
          </div>
          <div className="flex flex-col xl:flex-row xl:space-x-3 text-white text-sm ">
            <Link to={PATH.DISCLAIMER}>Disclaimer</Link>
            <Link to={PATH.DISCLAIMER}>Syarat dan Ketentuan</Link>
            <Link to={PATH.PRIVACY_POLICY}>Privacy Policy</Link>
            <Link to={PATH.ABOUT_US}>Tentang Kami</Link>
            <Link to={PATH.PARTNER}>Mitra Kami</Link>
            <Link to={PATH.DISCLAIMER}>Fasilitator Kami</Link>
          </div>
        </Container>
      </div>
    </>
  );
};
