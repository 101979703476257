import { Container } from '@/components/atoms';
import { SideNavMenu } from '@/components/organisms';

export const ProfileContainer = ({ children }) => {
  return (
    <div className="bg-gray-100">
      <Container className="flex flex-col lg:flex-row items-start py-8 space-y-8 lg:space-y-0 lg:space-x-8">
        <div className="block lg:sticky lg:top-28 w-full lg:w-1/3 bg-white rounded-md shadow-md">
          <SideNavMenu />
        </div>
        <div className="w-full">{children}</div>
      </Container>
    </div>
  );
};
