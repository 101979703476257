import { Backdrop } from '@/components/atoms';
import { ProductListFilter } from '@/components/molecules';

export const HeaderProductFilter = ({ onClick }) => {
  return (
    <div className="fixed w-full h-screen">
      <Backdrop active onClick={onClick} />
      <div className="absolute w-full bg-white overflow-y-scroll p-6" style={{ maxHeight: '60vh' }}>
        <ProductListFilter />
      </div>
    </div>
  );
};
