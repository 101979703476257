import { SERVICE_HOME } from '@/services';
import { TYPES_HOME } from './home.types';

export const ACTION_HOME = {
  getHomeData: () => {
    return (dispatch) => {
      dispatch({ type: TYPES_HOME.SET_HOME_LOADING });

      SERVICE_HOME.getHomeData((res) => {
        dispatch({
          type: TYPES_HOME.GET_HOME_DATA,
          payload: res.payload,
          error: res.error,
        });
      });
    };
  },

  getHomeVideoData: () => {
    return (dispatch) => {
      dispatch({ type: TYPES_HOME.SET_HOME_VIDEO_LOADING });

      SERVICE_HOME.getHomeVideoData((res) => {
        dispatch({
          type: TYPES_HOME.GET_HOME_VIDEO_DATA,
          payload: res.payload,
          error: res.error,
        });
      });
    };
  },
};
