import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './auth/auth.reducer';
import activityReducer from './activity/activity.reducer';
import homeReducer from './home/home.reducer';
import categoriesReducer from './categories/categories.reducer';
import coursesReducer from './courses/courses.reducer';
import formsReducer from './forms/forms.reducer';
import videosReducer from './videos/videos.reducer';
import quizReducer from './quiz/quiz.reducer';
import workingPaperReducer from './workingpaper/workingpaper.reducer';
import gamificationReducer from './gamification/gamification.reducer';
import profileReducer from './profile/profile.reducer';
import footerReducer from './footer/footer.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'forms'],
};

const rootReducer = combineReducers({
  auth: authReducer,
  activity: activityReducer,
  home: homeReducer,
  categories: categoriesReducer,
  courses: coursesReducer,
  forms: formsReducer,
  videos: videosReducer,
  quiz: quizReducer,
  workingPaper: workingPaperReducer,
  gamification: gamificationReducer,
  profile: profileReducer,
  footer: footerReducer,
});

export default persistReducer(persistConfig, rootReducer);
