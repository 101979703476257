import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import PATH from '@/routes/path';
import { CLIENT } from '@/config';

export const CompanyLogo = ({ src, width, link }) => {
  const logoStyle = { width };

  return link ? (
    <Link style={logoStyle} className="inline-flex items-center" to={PATH.HOME}>
      <img className="max-h-full max-w-full" src={src} alt="skydu cloud learner" />
    </Link>
  ) : (
    <span style={logoStyle} className="inline-flex items-center">
      <svg width="114" height="27" viewBox="0 0 114 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_113_688)">
          <path
            d="M13.74 8.13V12.9883H9.40754V19.8508C9.40754 20.5128 9.58703 21.0295 9.95014 21.407C10.1365 21.5954 10.3616 21.7429 10.6105 21.84C10.8594 21.9371 11.1266 21.9814 11.3943 21.9701C12.1153 21.9849 12.8235 21.782 13.4223 21.3888L15.0728 25.9403C14.4402 26.3105 13.7494 26.5758 13.0283 26.7254C12.185 26.913 11.3224 27.0051 10.4577 26.9999C7.98195 26.9999 6.08253 26.4072 4.75941 25.2217C3.43628 24.0363 2.77197 22.2675 2.76646 19.9154V12.9883H1.03966C0.766079 12.9883 0.503701 12.8819 0.310249 12.6927C0.116797 12.5034 0.00811768 12.2467 0.00811768 11.9791V9.15333C0.00811768 8.88567 0.116797 8.62898 0.310249 8.43972C0.503701 8.25046 0.766079 8.14414 1.03966 8.14414L13.74 8.13Z"
            fill="#F4F4F4"
          />
          <path
            d="M67.597 9.89207C68.9834 11.2605 69.6773 13.324 69.6787 16.0825V26.6831H64.0712C63.7976 26.6831 63.5352 26.5767 63.3418 26.3875C63.1483 26.1982 63.0397 25.9415 63.0397 25.6739V17.1441C63.0397 15.8443 62.7887 14.8862 62.2866 14.27C61.7846 13.6537 61.0797 13.3462 60.172 13.3476C59.7168 13.3304 59.2635 13.4137 58.8457 13.5912C58.4278 13.7686 58.0561 14.0358 57.7582 14.3729C57.1516 15.0565 56.849 16.0939 56.8504 17.4853V26.6831H51.2491C50.9755 26.6831 50.7132 26.5767 50.5197 26.3875C50.3263 26.1982 50.2176 25.9415 50.2176 25.6739V17.1441C50.2176 14.6131 49.2624 13.3476 47.3519 13.3476C46.3245 13.3476 45.5082 13.6893 44.9031 14.3729C44.2979 15.0565 43.9953 16.0939 43.9953 17.4853V26.6831H38.3713C38.0977 26.6831 37.8354 26.5767 37.6419 26.3875C37.4485 26.1982 37.3398 25.9415 37.3398 25.6739V9.16141C37.3398 8.89376 37.4485 8.63707 37.6419 8.44781C37.8354 8.25855 38.0977 8.15222 38.3713 8.15222H43.6632V10.102C44.3524 9.35734 45.2081 8.7785 46.1636 8.41057C47.1719 8.02585 48.2462 7.834 49.3284 7.84543C50.5579 7.82233 51.7775 8.06564 52.8996 8.55792C53.9469 9.03478 54.8331 9.79461 55.4537 10.7479C56.2002 9.81026 57.1737 9.0694 58.2863 8.59223C59.4638 8.08262 60.7392 7.8259 62.0267 7.83937C64.358 7.83937 66.2148 8.5236 67.597 9.89207Z"
            fill="#F4F4F4"
          />
          <path
            d="M88.0381 9.00201C89.4553 9.78333 90.6187 10.9397 91.3948 12.3384C92.22 13.7863 92.6326 15.4683 92.6326 17.3844C92.6326 19.3005 92.22 20.9886 91.3948 22.4485C90.6193 23.856 89.4565 25.0229 88.0381 25.8172C86.6051 26.6104 84.9827 27.0178 83.3364 26.998C81.3692 27.0302 79.4317 26.5256 77.7413 25.5407V26.6831H72.4495C72.1759 26.6831 71.9135 26.5768 71.72 26.3875C71.5266 26.1983 71.4179 25.9416 71.4179 25.6739V1.01322C71.4179 0.745568 71.5266 0.488872 71.72 0.299611C71.9135 0.11035 72.1759 0.00402832 72.4495 0.00402832H78.0548V9.10495C79.0266 8.4086 81.0979 7.84345 83.3364 7.84345C84.9804 7.82327 86.6013 8.22406 88.0381 9.00605V9.00201ZM85.5253 20.6542C86.3505 19.867 86.7631 18.7777 86.7631 17.3864C86.7631 16.0193 86.3505 14.9475 85.5253 14.1711C84.7001 13.3947 83.6438 13.0072 82.3564 13.0085C81.0676 13.0085 80.0113 13.396 79.1875 14.1711C78.3636 14.9462 77.951 16.0179 77.9496 17.3864C77.9496 18.7791 78.3622 19.8683 79.1875 20.6542C80.0127 21.44 81.069 21.8349 82.3564 21.839C83.6438 21.839 84.7001 21.4454 85.5253 20.6582"
            fill="#F4F4F4"
          />
          <path
            d="M114 8.14624V26.6831H108.706C108.433 26.6831 108.17 26.5768 107.977 26.3875C107.783 26.1983 107.675 25.9416 107.675 25.6739C106.986 26.3097 104.39 27.0666 102.044 26.996C99.5976 26.9213 97.6439 26.2895 96.1873 24.8747C94.7308 23.4598 94.0025 21.3404 94.0025 18.5127V9.16149C94.0025 8.89383 94.1112 8.63714 94.3046 8.44788C94.4981 8.25862 94.7605 8.1523 95.034 8.1523H100.639V17.4571C100.639 18.8498 100.908 19.865 101.444 20.5028C101.98 21.1406 102.762 21.4595 103.788 21.4595C104.27 21.4746 104.751 21.3859 105.194 21.1997C105.638 21.0135 106.035 20.7342 106.356 20.3817C107.029 19.6605 107.365 18.5584 107.365 17.0756V9.16149C107.365 8.89383 107.474 8.63714 107.667 8.44788C107.861 8.25862 108.123 8.1523 108.397 8.1523L114 8.14624Z"
            fill="#F4F4F4"
          />
          <path
            d="M35.4995 8.14624V26.6831H30.2056C29.9321 26.6831 29.6697 26.5768 29.4762 26.3875C29.2828 26.1983 29.1741 25.9416 29.1741 25.6739C28.485 26.3097 25.8897 27.0666 23.546 26.996C21.0992 26.9213 19.1454 26.2895 17.6889 24.8747C16.2323 23.4598 15.502 21.3404 15.502 18.5127V9.16149C15.502 8.89383 15.6107 8.63714 15.8041 8.44788C15.9976 8.25862 16.26 8.1523 16.5336 8.1523H22.141V17.4571C22.141 18.8498 22.4085 19.865 22.9436 20.5028C23.4786 21.1406 24.2598 21.4595 25.2872 21.4595C25.7702 21.4749 26.2508 21.3864 26.695 21.2001C27.1392 21.0139 27.5361 20.7345 27.8578 20.3817C28.5222 19.6645 28.8543 18.5645 28.8543 17.0816V9.16149C28.8543 8.89383 28.963 8.63714 29.1564 8.44788C29.3499 8.25862 29.6123 8.1523 29.8859 8.1523L35.4995 8.14624Z"
            fill="#F4F4F4"
          />
          <path
            d="M5.50223 7.04614H3.6764C3.55648 7.04641 3.43768 7.02353 3.3268 6.97882C3.21593 6.9341 3.11516 6.86843 3.03027 6.78556C2.94537 6.70269 2.87802 6.60425 2.83206 6.49588C2.78611 6.38752 2.76245 6.27134 2.76245 6.15402V4.36572H5.50223V7.04614Z"
            fill="#1DB9A5"
          />
          <path
            d="M9.4056 7.04618H7.57771C7.33567 7.04618 7.10351 6.95225 6.93217 6.78501C6.76083 6.61776 6.6643 6.39085 6.66376 6.15406V0.502563H9.4056V7.04618Z"
            fill="#1DB9A5"
          />
          <path
            d="M9.40559 3.18903H3.6764C3.43401 3.18903 3.20154 3.09483 3.03014 2.92714C2.85874 2.75946 2.76245 2.53203 2.76245 2.29489V0.506592H9.40559V3.18903Z"
            fill="#1DB9A5"
          />
        </g>
        <defs>
          <clipPath id="clip0_113_688">
            <rect width="114" height="27" fill="white" />
          </clipPath>
        </defs>
      </svg>
      {/* <img className="max-h-full w-full" src={src} alt="skydu cloud learner" /> */}
    </span>
  );
};

CompanyLogo.propTypes = {
  width: PropTypes.number,
  link: PropTypes.bool,
  src: PropTypes.string,
};

CompanyLogo.defaultProps = {
  width: 105,
  link: false,
  src: CLIENT.LOGO,
};
