import { SERVICE_QUIZ } from '@/services';
import { TYPES_QUIZ } from './quiz.types';

const getQuiz = (quizUUID, callback) => {
  return (dispatch) => {
    dispatch({ type: TYPES_QUIZ.SET_QUIZ_LOADING, loading: true });

    SERVICE_QUIZ.getQuiz(quizUUID, ({ payload }) => {
      if (payload) {
        if (callback) callback(payload);
      }

      dispatch({ type: TYPES_QUIZ.SET_QUIZ_LOADING, loading: false });
    });
  };
};

const setQuizLoading = (loading) => {
  return (dispatch) => {
    dispatch({ type: TYPES_QUIZ.SET_QUIZ_LOADING, loading });
  };
};

const submitQuiz = (quizUUID, quizAnswers, callback) => {
  return (dispatch) => {
    dispatch({ type: TYPES_QUIZ.SET_QUIZ_LOADING_ASSESSMENT, loadingAssessment: true });

    SERVICE_QUIZ.submitQuiz(quizUUID, quizAnswers, ({ payload }) => {
      if (payload) callback(payload);
      dispatch({ type: TYPES_QUIZ.SET_QUIZ_LOADING_ASSESSMENT, loadingAssessment: false });
    });
  };
};

export const ACTION_QUIZ = {
  getQuiz,
  submitQuiz,
  setQuizLoading,
};
