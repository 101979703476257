import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from '@/components/atoms';
import { ACTION_COURSES } from '@/redux/actions';
import { CONSTANTS } from '@/utils';
import PATH from '@/routes/path';
import CatalogFilter from './components/CatalogFilter';
import CatalogItem from './components/CatalogItem';
import CatalogItemSkeletons from './skeletons/CatalogItemSkeletons';

const Catalog = ({ productType }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const search = null;
  const coursesData = useSelector((state) => state.courses.payload);
  const coursesDataLoading = useSelector((state) => state.courses.isLoading);

  const handleResetFilter = () => {
    history.push(PATH.COURSE);
  };

  const handleChangeProductType = (productTypeID) => {
    const selectedProductType = Object.values(CONSTANTS.PRODUCT_TYPE).filter((type) => type.id === productTypeID);
    history.push(selectedProductType[0].path);
  };

  useEffect(() => dispatch(ACTION_COURSES.getCoursesData()), [search, productType]);

  return (
    <div className="bg-gray-100">
      <Container className="lg:flex items-start space-y-6 lg:space-y-0 lg:space-x-10 py-10">
        <CatalogFilter
          productType={productType}
          onReset={handleResetFilter}
          onChangeProductType={handleChangeProductType}
        />

        <div className="lg:w-full">
          <div>
            {(productType || search) && (
              <div className="mb-6">
                {productType && (
                  <h1 className="capitalize text-lg mb-3 text-gray-700 font-semibold">
                    Jelajah {productType.title}
                  </h1>
                )}
                {search && (
                  <p className="italic text-sm text-gray-500">
                    {t('search_result_for')} : <span className="font-semibold">{search}</span>
                  </p>
                )}
              </div>
            )}
            <div className="space-y-3">
              {coursesDataLoading && <CatalogItemSkeletons />}
              {!coursesDataLoading && coursesData && coursesData.length === 0 && (
                <div className="text-gray-500 bg-white p-6 shadow-sm rounded-md text-sm text-center">
                  Hasil pencarian tidak ditemukan
                </div>
              )}
              {!coursesDataLoading && coursesData && coursesData.length > 0 && <CatalogItem />}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Catalog;
