import { useState } from 'react';
import { BsClockHistory, BsPeople, BsAward } from 'react-icons/bs';
import { TbSchool } from 'react-icons/tb';

export const Features = () => {
  const [index, setIndex] = useState(0);

  const updateIndex = (newIndex) => {
    let idx = newIndex;
    if (newIndex < -1) {
      idx = -1;
    } else if (newIndex > 1) {
      idx = 1;
    }
    setIndex(idx);
  };

  return (
    <div className="w-screen xl:flex flex-col px-10 py-10 xl:py-20 bg-white">
      <div className="w-full xl:w-1/4 mr-14 mb-10 xl:mb-0">
        <p className="mb-3 mr-10 font-medium text-primary tracking-wide">YANG KAMI BERIKAN</p>
      </div>
      <div className="w-full xl:w-3/8 flex flex-col items-center">
        <div
          className="flex space-x-4 xl:space-x-11 relative transition-transform"
          style={{ transform: `translateX(${index * 30}%)` }}
        >
          <div className="h-72 w-72 sm:w-68 pt-10 px-7  bg-primary text-white rounded-xl">
            <div className="w-12 flex justify-center rounded-full p-2 text-3xl text-secondary bg-white border-orange-500 mb-4">
              <BsClockHistory />
            </div>
            <p className="font-light">
              Akses belajar mandiri <span className="font-bold">GRATIS</span>, bisa diakses{' '}
              <span className="font-bold">24 jam, setiap hari.</span>
            </p>
          </div>
          <div className="h-72 w-72 sm:w-68 pt-10 px-7 bg-primary text-white rounded-xl">
            <div className="w-12 flex justify-center rounded-full p-2 text-3xl text-secondary bg-white border-orange-500 mb-4">
              <BsPeople />
            </div>
            <p className="font-light">
              Video pembelajaran kewirausahaan praktis{' '}
              <span className="font-bold">yang sesuai dengan kebutuhan belajar Anda.</span>
            </p>
          </div>
          <div className="h-72 w-72 sm:w-68 pt-10 px-7 bg-primary text-white rounded-xl">
            <div className="w-12 flex justify-center rounded-full p-2 text-3xl text-secondary bg-white border-orange-500 mb-4">
              <BsAward />
            </div>
            <p className="font-light">
              <span className="font-bold">Fitur kuis dan kertas kerja</span> untuk meningkatkan pemahaman Anda
              terkait materi, serta praktek dalam usaha.
            </p>
          </div>
          <div className="h-72 w-72 sm:w-68 pt-10 px-7 bg-primary text-white rounded-xl">
            <div className="w-12 flex justify-center rounded-full p-2 text-3xl text-secondary bg-white border-orange-500 mb-4">
              <TbSchool />
            </div>
            <p className="font-light">
              Kesempatan
              <span className="font-bold"> coaching, konsultasi, dan hadiah menarik</span>
              <span> bagi pembelajar yang poin belajarnya mencapai target tertentu.</span>
            </p>
          </div>
        </div>
        <div className="block xl:hidden mt-10 flex space-x-10">
          <button
            type="button"
            className="w-10 rounded-full p-1 bg-secondary text-2xl text-white font-bolder"
            onClick={() => updateIndex(index + 1)}
          >
            {'<'}
          </button>
          <button
            type="button"
            className="w-10 rounded-full p-1 bg-secondary text-2xl text-white font-bolder"
            onClick={() => updateIndex(index - 1)}
          >
            {'>'}
          </button>
        </div>
      </div>
    </div>
  );
};
