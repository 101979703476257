import { HELPERS } from '@/utils';
import { TYPES_COURSES } from './courses.types';

const INITIAL_STATE = {
  listData: [],
  listTotal: null,
  listTotalPage: null,
  listLoading: false,
  listLoadingLoadMore: false,
  detailData: null,
  detailError: null,
  detailLoading: true,
  reviewsData: null,
  reviewsLoading: true,
  enrollCourseLoading: false,
  ratingData: null,
  ratingError: null,
  certificateData: null,
  certificateLoading: false,
};

const coursesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES_COURSES.SET_COURSES_LOADING:
      return { ...state, listLoading: action.listLoading };
    case TYPES_COURSES.GET_COURSES_DATA:
      return {
        ...state,
        listData: action.payload,
        listTotal: action.total,
        listTotalPage: action.totalPage,
      };

    case TYPES_COURSES.SET_LIST_COURSES_LOADMORE_LOADING:
      return { ...state, listLoadingLoadMore: action.loading };
    case TYPES_COURSES.GET_LIST_COURSES_LOADMORE_DATA:
      return {
        ...state,
        listData: HELPERS.removeDuplicateArrayOfObject([...state.listData, ...action.payload]),
        listTotal: action.total,
      };

    case TYPES_COURSES.SET_DETAIL_COURSES_LOADING:
      return { ...state, detailLoading: true };
    case TYPES_COURSES.GET_DETAIL_COURSES_DATA:
      return {
        ...state,
        detailData: action.payload,
        detailError: action.error,
        detailLoading: false,
      };

    case TYPES_COURSES.SET_COURSE_RATING_LOADING:
      return { ...state, ratingLoading: action.loading };
    case TYPES_COURSES.SET_COURSE_RATING_IS_SUBMITTING:
      return { ...state, ratingIsSubmitting: action.isSubmitting };
    case TYPES_COURSES.GET_COURSE_RATING_DATA:
      return {
        ...state,
        ratingData: action.payload,
        ratingError: action.error,
      };

    case TYPES_COURSES.SET_COURSE_REVIEWS_LOADING:
      return { ...state, reviewsLoading: action.loading };
    case TYPES_COURSES.GET_COURSE_REVIEWS_DATA:
      return {
        ...state,
        reviewsData: action.payload,
      };

    case TYPES_COURSES.SET_COURSE_CERTIFICATE_LOADING:
      return { ...state, certificateLoading: action.loading };
    case TYPES_COURSES.GET_COURSE_CERTIFICATE_DATA:
      return {
        ...state,
        certificateData: action.payload,
      };

    case TYPES_COURSES.SET_ENROLL_COURSE_LOADING:
      return { ...state, enrollCourseLoading: action.loading };

    default:
      return state;
  }
};

export default coursesReducer;
