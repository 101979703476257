const PATH = {
  HOME: '/',
  AUTH_LOGIN: '/login',
  AUTH_LOGIN_WARNING: '/login/warning',
  AUTH_REGISTER: '/register',
  AUTH_FORGOT_PASSWORD: '/forgot-password',
  AUTH_FORGOT_PASSWORD_EMAIL_SENT: '/forgot-password/email-sent',
  AUTH_RESET_PASSWORD: '/reset-password',
  AUTH_OTP: '/otp',
  AUTH_LOGIN_WA: '/login-wa',
  CATALOG: '/catalog',
  CATALOG_COURSE: '/catalog/course',
  CATALOG_VIDEO: '/catalog/video',
  COURSE: '/course',
  VIDEO: '/video',
  PROFILE: '/profile',
  PROFILE_RIWAYAT_BELAJAR: '/profile/riwayat-belajar',
  PROFILE_POIN_SAYA: '/profile/poin-saya',
  PROFILE_JADWAL_COACHING: '/profile/jadwal-coaching',
  PROFILE_SETTING: '/profile/setting',
  PAYMENT: '/payment',
  TRANSACTION: '/transaction',
  TRANSACTION_CANCELLED: '/transaction/cancelled',
  CERTIFICATE: '/certificate',
  DISCLAIMER: '/disclaimer',
  PRIVACY_POLICY: '/privacy-policy',
  ABOUT_US: '/about-us',
  PARTNER: '/partner',
};

export default PATH;
