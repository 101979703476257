import ReactSlider from 'react-slider';

export const InputSlider = ({ value, min, max, onChange, onAfterChange, onBeforeChange, onSliderClick }) => {
  const Track = (props, { index }) => {
    const { style } = props;
    return (
      <div
        {...props}
        style={{ ...style, left: style.left || 0, right: 0 }}
        className={`${index === 0 ? 'bg-primary' : 'bg-gray-400 bg-opacity-70'} h-1 cursor-pointer`}
      />
    );
  };

  return (
    <div>
      <ReactSlider
        className="flex items-center"
        min={min}
        max={max}
        pearling
        marks
        value={value}
        onChange={onChange}
        onBeforeChange={onBeforeChange}
        onAfterChange={onAfterChange}
        onSliderClick={onSliderClick}
        markClassName="bg-green-500"
        renderTrack={Track}
        thumbClassName="w-4 h-4 shadow-md outline-none rounded-full bg-primary cursor-pointer"
      />
    </div>
  );
};

InputSlider.defaultProps = {
  min: 0,
  max: 100,
};
