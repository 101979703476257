import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { BiLoaderAlt } from 'react-icons/bi';
import { StarRating } from '@/components/atoms';
import { ACTION_COURSES, ACTION_VIDEOS } from '@/redux/actions';
import { CONSTANTS } from '@/utils';

export const ProductTabUlasan = ({ className, uuid, productType }) => {
  const dispatch = useDispatch();

  const [pointRating, setPointRating] = useState(0);

  const [rateOne, setRateOne] = useState(0);
  const [rateTwo, setRateTwo] = useState(0);
  const [rateThree, setRateThree] = useState(0);
  const [rateFour, setRateFour] = useState(0);
  const [rateFive, setRateFive] = useState(0);

  const isLoading = useSelector((state) =>
    productType === CONSTANTS.PRODUCT_TYPE.VIDEO ? state.videos.reviewsLoading : state.courses.reviewsLoading
  );

  const reviewsData = useSelector((state) =>
    productType === CONSTANTS.PRODUCT_TYPE.VIDEO ? state.videos.reviewsData : state.courses.reviewsData
  );

  const handleReviewsData = () => {
    if (reviewsData) {
      const sumRating = reviewsData.reduce((prevValue, currentValue) => prevValue + currentValue.rating, 0);
      const avgRating = sumRating / reviewsData.length;

      const countRateOne = reviewsData.filter((review) => review.rating === 1).length;
      const countRateTwo = reviewsData.filter((review) => review.rating === 2).length;
      const countRateThree = reviewsData.filter((review) => review.rating === 3).length;
      const countRateFour = reviewsData.filter((review) => review.rating === 4).length;
      const countRateFive = reviewsData.filter((review) => review.rating === 5).length;

      setPointRating(avgRating);
      setRateOne(countRateOne);
      setRateTwo(countRateTwo);
      setRateThree(countRateThree);
      setRateFour(countRateFour);
      setRateFive(countRateFive);
    }
  };

  useEffect(() => handleReviewsData(), [reviewsData]);
  useEffect(() => {
    if (productType === CONSTANTS.PRODUCT_TYPE.VIDEO) dispatch(ACTION_VIDEOS.getVideoReviewsData(uuid));
    else if (productType === CONSTANTS.PRODUCT_TYPE.COURSE) dispatch(ACTION_COURSES.getCourseReviewsData(uuid));
  }, [uuid]);

  return (
    <div className={`${productType === CONSTANTS.PRODUCT_TYPE.COURSE && 'bg-white p-3 rounded-md'}`}>
      {isLoading && (
        <div className="flex flex-col items-center space-y-3 text-gray-400 py-6">
          <BiLoaderAlt className="animate-spin text-lg" />
          <div className="text-sm">Loading...</div>
        </div>
      )}
      {!isLoading && reviewsData && reviewsData.length === 0 && (
        <div className="p-6 text-center text-sm text-gray-400">Tidak ada ulasan</div>
      )}
      {!isLoading && reviewsData && reviewsData.length > 0 && (
        <div className={`space-y-10 ${className}`}>
          <div className="lg:flex lg:space-x-6 space-y-6 lg:space-y-0">
            <div className="lg:w-1/5 flex flex-col items-center justify-center text-center space-y-2">
              <div className="text-6xl font-semibold text-gray-600">
                {pointRating ? Number(pointRating).toFixed(1) : 0}
              </div>
              <div>
                <StarRating value={pointRating || 0} />
              </div>
              <div className="text-sm text-gray-400">Peringkat Video</div>
            </div>
            <div className="w-full space-y-1">
              {[rateOne, rateTwo, rateThree, rateFour, rateFive].reverse().map((value, index) => (
                <div key={index} className="flex items-center space-x-6">
                  <div className="relative w-4/5 h-3 bg-gray-200 rounded-sm overflow-hidden">
                    <div
                      className="absolute h-full left-0 top-0 bg-primary"
                      style={{ width: `${(value / reviewsData.length) * 100}%` }}
                    />
                  </div>
                  <div className="w-auto">
                    <StarRating value={5 - index} />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="space-y-10 overflow-y-scroll" style={{ height: 500 }}>
            {reviewsData.map((review, index) => (
              <div className="space-y-10">
                <div className="flex items-start space-x-8">
                  <div>
                    <div
                      className={`w-14 h-14 flex rounded-full ${
                        index % 2 === 0 ? 'bg-secondary-500' : 'bg-blue-500'
                      }`}
                    >
                      <div className="m-auto text-white text-lg uppercase">
                        {review.user.name
                          .split(' ')
                          .slice(0, 2)
                          .map((name) => name[0])
                          .join('')}
                      </div>
                    </div>
                  </div>
                  <div className="space-y-2">
                    <div className="flex flex-col md:flex-row flex-wrap">
                      <div className="text-gray-600 font-semibold capitalize mr-3">{review.user.name}</div>
                      <div className="flex items-center flex-wrap">
                        <div className="mr-3">
                          <StarRating value={review.rating || 0} />
                        </div>
                        <div className="text-gray-400 text-sm">
                          {moment(review.created_at).format('DD MMMM yyyy hh:mm:ss')}
                        </div>
                      </div>
                    </div>
                    <div className="space-y-3">
                      <p className="text-sm">{review.user_comment}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

ProductTabUlasan.TITLE = 'Ulasan';
