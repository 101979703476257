import axios from 'axios';
import { notify } from 'react-notify-toast';
import { createBrowserHistory } from 'history';
import { TYPES_AUTH } from '@/redux/auth/auth.types';
import { store } from '@/redux/store';

const http = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

http.interceptors.response.use(
  (response) => response,
  (error) => {
    let notifyMessage = 'Terjadi Kesalahan';
    let notifyType = 'error';

    if (error.response) {
      const { status, statusText, data } = error.response;

      if (status === 500 || status === 404) {
        notifyType = 'error';
        notifyMessage = `${status} : ${statusText}`;
      } else if (data) {
        if (data.errors) {
          notifyType = 'warning';
          notifyMessage = Object.values(data.errors).map((message) => <div>{message}</div>);
        } else if (data.message) {
          notifyType = 'warning';
          notifyMessage = data.message;

          if (status === 401) {
            store.dispatch({ type: TYPES_AUTH.LOGOUT });
            createBrowserHistory().push(`/login?message=${notifyMessage}`);
            window.location.reload();
          }
        } else notifyType = 'error';
      }
    } else {
      notifyType = 'error';
      notifyMessage = `500 : ${error.message}`;
    }

    notify.show(notifyMessage, notifyType);
    return Promise.reject(error);
  }
);

export { http };
