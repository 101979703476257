import { ACTION_PROFILE } from '@/redux/actions';
import { CONSTANTS } from '@/utils';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RiwayatBelajarProductListHeader } from './RiwayatBelajarProductListHeader';
import { RiwayatBelajarProductListItem } from './RiwayatBelajarProductListItem';

export const CardCompletedList = () => {
  const dispatch = useDispatch();

  const [activeProduct, setActiveProduct] = useState(CONSTANTS.PRODUCT_TYPE.VIDEO);

  const datas = useSelector((state) =>
    activeProduct === CONSTANTS.PRODUCT_TYPE.VIDEO ? state.profile.completedVideos : state.profile.completedCourses
  );

  const handleChangeProduct = (product) => {
    setActiveProduct(product);
  };

  useEffect(() => {
    if (activeProduct === CONSTANTS.PRODUCT_TYPE.VIDEO) dispatch(ACTION_PROFILE.getCompletedVideos());
    else if (activeProduct === CONSTANTS.PRODUCT_TYPE.COURSE) dispatch(ACTION_PROFILE.getCompletedCourses());
  }, [activeProduct]);

  return (
    <div className="bg-white rounded-md">
      <RiwayatBelajarProductListHeader
        title="Telah Diikuti"
        activeProduct={activeProduct}
        onChangeProduct={handleChangeProduct}
      />
      {!datas && <CardCompletedList.Skeleton />}
      {datas && datas.length === 0 && (
        <div className="p-5 text-sm text-gray-400">
          Tidak ada{' '}
          <span>
            {activeProduct === CONSTANTS.PRODUCT_TYPE.COURSE && 'kursus'}
            {activeProduct === CONSTANTS.PRODUCT_TYPE.VIDEO && 'video'}
          </span>{' '}
          yang telah selesai
        </div>
      )}
      {datas && datas.length > 0 && (
        <div>
          {datas.map((data) => (
            <RiwayatBelajarProductListItem
              key={data.uuid}
              uuid={data.uuid}
              thumbnail={data.thumbnail}
              title={data.title}
              activeProduct={activeProduct}
              progress={data.progress}
              type={RiwayatBelajarProductListItem.TYPES.FINISHED}
            />
          ))}
        </div>
      )}
    </div>
  );
};

CardCompletedList.Skeleton = () => {
  return (
    <div>
      <RiwayatBelajarProductListItem.Skeleton />
      <RiwayatBelajarProductListItem.Skeleton />
      <RiwayatBelajarProductListItem.Skeleton />
    </div>
  );
};
