import { KEY } from '@/config';
import { http } from './http';

const BASE_URL = '/auth';

export const SERVICE_AUTH = {
  loginByEmail: (data, callback) => {
    http
      .post(`${BASE_URL}/login`, data)
      .then((res) => callback({ payload: res.data.payload, error: null, success: true }))
      .catch((error) => callback({ payload: null, error: error.response, success: false }));
  },

  loginByGoogle: (accessToken, callback) => {
    const data = {
      user_token: accessToken,
      client_token: KEY.CLIENT_TOKEN,
    };

    http
      .post(`${BASE_URL}/login/google`, data)
      .then((res) => callback({ payload: res.data.payload, error: null, success: true }))
      .catch((error) => callback({ payload: null, error: error.response, success: false }));
  },

  loginByWa: (data, callback) => {
    http
      .post(`${BASE_URL}/login/wa`, data)
      .then((res) => callback({ payload: res.data.payload, error: null, success: true }))
      .catch((error) => callback({ payload: null, error: error.response, success: false }));
  },

  checkWa: (data, callback) => {
    http
      .post(`${BASE_URL}/check/wa`, data)
      .then((res) => callback({ payload: res.data.payload, error: null, success: true }))
      .catch((error) => callback({ payload: null, error: error.response, success: false }));
  },

  register: (data, callback) => {
    http
      .post(`${BASE_URL}/register`, data)
      .then((res) => callback({ payload: res.data.payload, error: null, success: true }))
      .catch((error) => callback({ payload: null, error: error.response, success: false }));
  },

  registerByGoogle: (accessToken, callback) => {
    const data = {
      user_token: accessToken,
      client_token: KEY.CLIENT_TOKEN,
    };

    http
      .post(`${BASE_URL}/register/google`, data)
      .then((res) => callback({ payload: res.data.payload, error: null, success: true }))
      .catch((error) => callback({ payload: null, error: error.response, success: false }));
  },

  registerByWa: (data, callback) => {
    http
      .post(`${BASE_URL}/register/wa`, data)
      .then((res) => callback({ payload: res.data.payload, error: null, success: true }))
      .catch((error) => callback({ payload: null, error: error.response, success: false }));
  },

  forgotPassword: (data, callback) => {
    http
      .post(`${BASE_URL}/password/forget`, data)
      .then((res) => callback({ payload: res.data.payload, error: null, success: true }))
      .catch((error) => callback({ payload: null, error: error.response, success: false }));
  },

  verifyEmail: (data, callback) => {
    http
      .post(`${BASE_URL}/email/verify`, data)
      .then((res) => callback({ payload: res.data.payload, error: null, success: res.data.status === 'success' }))
      .catch((error) => callback({ payload: null, error: error.response, success: false }));
  },

  resetPassword: (data, callback) => {
    http
      .post(`${BASE_URL}/password/reset`, data)
      .then((res) => callback({ payload: res.data.payload, error: null, success: res.data.status === 'success' }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },
};
