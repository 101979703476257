import { HELPERS } from '@/utils';
import { TYPES_VIDEOS } from './videos.types';

const INITIAL_VALUES = {
  listData: [],
  listDataTerkait: [],
  listTotal: null,
  listTotalPage: null,
  listError: null,
  listLoading: false,
  listLoadingLoadMore: false,
  detailData: null,
  detailError: null,
  detailLoading: true,
  ratingData: null,
  ratingError: null,
  ratingLoading: true,
  ratingIsSubmitting: false,
  reviewsData: null,
  reviewsLoading: true,
};

const videosReducer = (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case TYPES_VIDEOS.SET_LIST_VIDEOS_LOADING:
      return { ...state, listLoading: true };
    case TYPES_VIDEOS.GET_LIST_VIDEOS_DATA:
      return {
        ...state,
        listData: action.payload,
        listTotal: action.total,
        listTotalPage: action.totalPage,
        listError: action.error,
        listLoading: false,
      };
    case TYPES_VIDEOS.GET_LIST_VIDEOS_DATA_TERKAIT:
      return {
        ...state,
        listDataTerkait: action.payload,
        listTotal: action.total,
        listTotalPage: action.totalPage,
        listError: action.error,
        listLoading: false,
      };

    case TYPES_VIDEOS.SET_LIST_VIDEOS_LOADMORE_LOADING:
      return { ...state, listLoadingLoadMore: action.loading };
    case TYPES_VIDEOS.GET_LIST_VIDEOS_LOADMORE_DATA:
      return {
        ...state,
        listData: HELPERS.removeDuplicateArrayOfObject([...state.listData, ...action.payload]),
        listTotal: action.total,
        listError: action.error,
        listLoading: false,
      };

    case TYPES_VIDEOS.SET_DETAIL_VIDEOS_LOADING:
      return { ...state, detailLoading: true };
    case TYPES_VIDEOS.GET_DETAIL_VIDEOS_DATA:
      return {
        ...state,
        detailData: action.payload,
        detailError: action.error,
        detailLoading: false,
      };

    case TYPES_VIDEOS.SET_VIDEO_RATING_LOADING:
      return { ...state, ratingLoading: action.loading };
    case TYPES_VIDEOS.SET_VIDEO_RATING_IS_SUBMITTING:
      return { ...state, ratingIsSubmitting: action.isSubmitting };
    case TYPES_VIDEOS.GET_VIDEO_RATING_DATA:
      return {
        ...state,
        ratingData: action.payload,
        ratingError: action.error,
      };

    case TYPES_VIDEOS.SET_VIDEO_REVIEWS_LOADING:
      return { ...state, reviewsLoading: action.loading };
    case TYPES_VIDEOS.GET_VIDEO_REVIEWS_DATA:
      return {
        ...state,
        reviewsData: action.payload,
      };
    default:
      return state;
  }
};

export default videosReducer;
