import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { BiLoaderAlt } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import {
  MdPlayArrow,
  MdPause,
  MdReplay10,
  MdForward10,
  MdHighQuality,
  MdVolumeOff,
  MdVolumeUp,
  MdVolumeDown,
  MdVolumeMute,
  MdFullscreen,
  MdFullscreenExit,
  MdPictureInPictureAlt,
} from 'react-icons/md';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import screenfull from 'screenfull';
import { CONSTANTS, HELPERS } from '@/utils';
import { ACTION_COURSES, ACTION_VIDEOS, ACTION_ACTIVITY } from '@/redux/actions';
import PATH from '@/routes/path';
import { InputSlider } from './components/InputSlider';
import { VideoPlayerButton } from './components/VideoPlayerButton';

let CONTROL_VISIBILITY_COUNT = 0;

export const VideoPlayer = ({
  thumbnail,
  productUuid,
  className,
  productType,
  activeTopic,
  video360URL,
  video480URL,
  video720URL,
  video1080URL,
  onVideoEnd,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const playerContainerRef = useRef();
  const playerRef = useRef();
  const controlsRef = useRef();

  const PLAYBACK_RATE_OPTIONS = [2, 1.75, 1.5, 1.25, 1, 0.75, 0.5];
  const LAST_SECOND_FINISHED = 3;
  // const UNAUTHENTICATED_USER_LIMIT_SECOND = 40;
  const CONTROL_VISIBILITY_COUNT_LIMIT_SECOND = 1.2;

  const userToken = useSelector((state) => state.auth.token);
  const ipAddress = useSelector((state) => state.activity.ipAddress);
  const profile = useSelector((state) => state.profile.profile);

  // analytics
  const [startedAt, setStartedAt] = useState(null);
  const [pausedAt, setPausedAt] = useState(null);
  const [isFinished, setIsFinished] = useState(false);
  const [isVideoEnd, setIsVideoEnd] = useState(false);
  const [skippedAt, setSkippedAt] = useState(null);
  const [skipPattern, setSkipPattern] = useState(null); // kurang dari 25%, 50%, 75% & 100%

  const [timeWatching, setTimeWatching] = useState(0); // in seconds

  const [isVideoReady, setIsVideoReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowResolutionOption, setIsShowResolutionOption] = useState(false);
  const [isShowPlaybackRate, setIsShowPlaybackRate] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isPip, setIsPip] = useState(false);
  const [volume, setVolume] = useState(100);
  const [playbackRate, setPlaybackRate] = useState(PLAYBACK_RATE_OPTIONS[4]);
  const [videoResolutionURLs, setVideoResolutionURLs] = useState([]);
  const [videoURL, setVideoURL] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(null);
  const [currentTime, setCurrentTime] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [oldUUID, setOldUUID] = useState(null);

  const setSeek = (newValue) => {
    if (newValue > 0) {
      const value = (newValue / duration) * 100;
      setCurrentTime(duration * (value / 100));
      playerRef.current.seekTo(duration * (value / 100));
    }
  };

  const handleReady = () => {
    setIsLoading(false);
    setIsVideoReady(true);
  };

  const handlePlay = () => {
    playerRef.current.seekTo(currentTime, 'seconds');
    setStartTime(currentTime);
  };

  const onSeek = () => {
    if (!skipPattern && !skippedAt) {
      let pattern;
      const percent = (timeWatching / duration) * 100;

      if (percent <= 25) pattern = 1;
      else if (percent <= 50) pattern = 2;
      else if (percent <= 75) pattern = 3;
      else pattern = 4;

      setSkipPattern(pattern);
      setSkippedAt(timeWatching);
    }
  };

  const handleFullScreen = () => {
    screenfull.toggle(playerContainerRef.current);
    setIsFullscreen(!isFullscreen);
  };

  const handleChangeVolume = (newValue) => setVolume(newValue);

  const handleChangeVideoURL = (url) => {
    setVideoURL(url);
    setIsLoading(true);
  };

  const sendAnalytics = () => {
    if (userToken && startedAt) {
      if (!isFinished) {
        if (productType === CONSTANTS.PRODUCT_TYPE.VIDEO) {
          dispatch(ACTION_VIDEOS.setVideoProgress(productUuid, startedAt, pausedAt, skippedAt, skipPattern));
        } else if (productType === CONSTANTS.PRODUCT_TYPE.COURSE) {
          dispatch(
            ACTION_COURSES.setCourseVideoProgress(
              productUuid,
              activeTopic.chapter_video_uuid,
              startedAt,
              pausedAt,
              skippedAt,
              skipPattern
            )
          );
        }
      } else {
        setIsFinished(false);
        const finishedAt = moment().format('DD/MM/YYYY HH:mm:ss');

        if (productType === CONSTANTS.PRODUCT_TYPE.VIDEO) {
          dispatch(
            ACTION_VIDEOS.setVideoProgress(
              productUuid,
              startedAt,
              pausedAt,
              skippedAt,
              skipPattern,
              timeWatching,
              playbackRate,
              finishedAt
            )
          );
        } else if (productType === CONSTANTS.PRODUCT_TYPE.COURSE) {
          dispatch(
            ACTION_COURSES.setCourseVideoProgress(
              productUuid,
              activeTopic.chapter_video_uuid,
              startedAt,
              pausedAt,
              skippedAt,
              skipPattern,
              timeWatching,
              playbackRate,
              finishedAt
            )
          );
        }
      }
    }
  };

  const sendActivity = (watchTime) => {
    const data = {
      user_id: profile === null ? null : profile.uuid,
      ip_address: ipAddress,
      watch_time: watchTime,
      date: moment().format('DD/MM/YYYY'),
    };
    dispatch(ACTION_ACTIVITY.storeVisitor(data));
  };

  const handleProgress = (progress) => {
    if (playerRef) {
      if (CONTROL_VISIBILITY_COUNT > CONTROL_VISIBILITY_COUNT_LIMIT_SECOND) {
        controlsRef.current.style.visibility = 'hidden';
        CONTROL_VISIBILITY_COUNT = 0;
      }

      if (controlsRef.current.style.visibility === 'visible') {
        CONTROL_VISIBILITY_COUNT += 1;
      }

      if (duration) {
        setCurrentTime(progress.playedSeconds);

        if (!userToken) {
          // if (progress.playedSeconds >= UNAUTHENTICATED_USER_LIMIT_SECOND) history.push(PATH.AUTH_LOGIN_WARNING);
        } else if (progress.playedSeconds >= duration - LAST_SECOND_FINISHED) {
          setIsFinished(true);
        }

        if (progress.playedSeconds === duration) {
          setIsVideoEnd(true);
        }
      } else {
        setDuration(playerRef.current.getDuration());
      }
    }
  };

  const handleMouseMove = () => {
    if (controlsRef.current) {
      controlsRef.current.style.visibility = 'visible';
      CONTROL_VISIBILITY_COUNT = 0;
    }
  };

  const seekTen = (op) => {
    const diff = currentTime - startTime;
    sendActivity(Math.round(diff % 5));
    if (op === '-') {
      setSeek(currentTime - 10);
      setStartTime(currentTime - 10);
    }
    if (op === '+') {
      setSeek(currentTime + 10);
      setStartTime(currentTime + 10);
    }
  };

  const publicWatchlist = (uuid) => {
    if (!userToken) {
      const items = JSON.parse(localStorage.getItem('tumbu_watchlist'));
      if (items) {
        const isFound = items.some((el) => el === uuid);
        if (!isFound) {
          if (items.length < 5) {
            const data = [...items, uuid];
            localStorage.setItem('tumbu_watchlist', JSON.stringify(data));
          } else {
            history.push(PATH.AUTH_LOGIN_WARNING);
          }
        }
      } else {
        const data = [uuid];
        localStorage.setItem('tumbu_watchlist', JSON.stringify(data));
      }
    }
  };

  // ===================================== EFFECT FUNCTIONS

  useEffect(() => {
    if (!userToken && productType === CONSTANTS.PRODUCT_TYPE.VIDEO) {
      publicWatchlist(activeTopic.uuid);
    }
  }, [userToken, activeTopic]);

  useEffect(() => {
    const URLs = [];

    if (video360URL) URLs.push({ resolution: 360, url: video360URL });
    if (video480URL) URLs.push({ resolution: 480, url: video480URL });
    if (video720URL) URLs.push({ resolution: 720, url: video720URL });
    if (video1080URL) URLs.push({ resolution: 1080, url: video1080URL });

    if (URLs.length > 0) {
      setVideoResolutionURLs(URLs);
      setVideoURL(URLs[0].url);
    }
  }, [video360URL, video480URL, video720URL, video1080URL]);

  useEffect(() => {
    let interval;

    if (playing) interval = setInterval(() => setTimeWatching(timeWatching + 1), 1000);
    if (playing && timeWatching === 0) setStartedAt(moment().format('DD/MM/YYYY HH:mm:ss'));

    return () => clearInterval(interval);
  }, [playing, timeWatching]);

  useEffect(() => {
    if (startedAt && !playing && !pausedAt) setPausedAt(timeWatching);
  }, [playing, startedAt, timeWatching]);

  useEffect(() => sendAnalytics(), [startedAt, pausedAt, isFinished, userToken, skippedAt, skipPattern]);

  useEffect(() => {
    if (isVideoEnd) {
      const diff = currentTime - startTime;
      sendActivity(Math.round(diff % 5));
      if (productType === CONSTANTS.PRODUCT_TYPE.VIDEO) {
        dispatch(ACTION_VIDEOS.getVideoDetailData(productUuid));
      } else if (productType === CONSTANTS.PRODUCT_TYPE.COURSE) {
        dispatch(ACTION_COURSES.getCourseDetailData(productUuid));
      }
      if (onVideoEnd) onVideoEnd();
    }
    setIsVideoEnd(false);
  }, [isVideoEnd]);

  useEffect(() => {
    setIsLoading(true);
    setPlaying(false);
    setIsVideoReady(false);
    setDuration(playerRef.current.getDuration());
    const tempTime = oldUUID === activeTopic.uuid ? currentTime : 0;
    setCurrentTime(tempTime);
    setOldUUID(activeTopic.uuid);
    setSeek(tempTime);

    setTimeout(() => {
      setSeek(tempTime);
      setIsLoading(false);
      setIsVideoReady(true);
      setPlaying(true);
    }, 1000);

    setTimeout(() => {
      setSeek(tempTime);
      setCurrentTime(tempTime);
      setPlaying(false);
    }, 20);
  }, [videoURL]);

  useEffect(() => {
    dispatch(ACTION_ACTIVITY.getIPAddress());
    setOldUUID(activeTopic.uuid);
    setCurrentTime(0);
    setStartTime(currentTime);
  }, []);

  useEffect(() => {
    const diff = currentTime - startTime;
    if (diff > 1 && diff % 5 < 1) {
      sendActivity(5);
    }
  }, [currentTime]);

  useEffect(() => {
    if (!playing && oldUUID === activeTopic.uuid) {
      const diff = currentTime - startTime;
      sendActivity(Math.round(diff % 5));
    }
  }, [playing]);

  return (
    <div ref={playerContainerRef} onMouseMove={handleMouseMove} className={`relative w-full ${className}`}>
      <div
        className={`${!playing && timeWatching === 0 ? 'md:h-96 h-60' : 'h-full'} ${
          !isFullscreen && playing && 'md:h-full'
        }`}
      >
        <ReactPlayer
          ref={playerRef}
          width="100%"
          playing={playing}
          height="100%"
          onProgress={handleProgress}
          onSeek={onSeek}
          url={isVideoReady && videoURL}
          light={!playing && timeWatching === 0 ? thumbnail : false}
          playbackRate={playbackRate}
          onReady={handleReady}
          onPlay={handlePlay}
          config={{ file: { attributes: { controlsList: 'nodownload' } } }}
          onContextMenu={(e) => e.preventDefault()}
          onBuffer={() => setIsLoading(true)}
          onBufferEnd={() => setIsLoading(false)}
          muted={isMuted}
          volume={volume / 100}
          onClickPreview={() => setPlaying(!playing)}
          pip={isPip}
        />
      </div>

      {/* Loading For Video Player */}
      {isLoading && (
        <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full p-3 bg-black bg-opacity-40">
          <BiLoaderAlt className="text-white text-opacity-70 animate-spin" size={56} />
        </div>
      )}

      {/* Player Controls */}
      {startedAt && (
        <div
          ref={controlsRef}
          className="absolute top-0 left-0 flex flex-col items-center justify-center w-full h-full p-3 transition-all bg-black opacity-0 hover:opacity-100 bg-opacity-40"
        >
          <div className="flex items-center justify-center w-full h-full pt-12 cursor-default bg-opacity-30">
            <button
              className="absolute top-0 hidden w-full cursor-default md:block h-5/6"
              type="button"
              onClick={() => {
                setSeek(currentTime);
                // setCurrentTime(currentTime);
                setPlaying(!playing);
              }}
            />

            {/* Loading For Video Control */}
            {isLoading && (
              <div className="absolute z-10 text-center transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                <BiLoaderAlt className="text-white text-opacity-70 animate-spin" size={56} />
              </div>
            )}
            {!isLoading && (
              <>
                <VideoPlayerButton
                  className="w-12 h-12 mx-3 hover:bg-white bg-opacity-20 sm:mx-10"
                  // onClick={() => setSeek(currentTime - 10)}
                  onClick={() => seekTen('-')}
                >
                  <MdReplay10 size={28} />
                </VideoPlayerButton>
                <VideoPlayerButton
                  className="w-12 h-12 mx-3 hover:bg-white bg-opacity-20 sm:mx-10"
                  onClick={() => {
                    setSeek(currentTime);
                    // setCurrentTime(currentTime);
                    setPlaying(!playing);
                  }}
                >
                  {playing ? <MdPause size={32} /> : <MdPlayArrow size={32} />}
                </VideoPlayerButton>
                <VideoPlayerButton
                  className="w-12 h-12 mx-3 hover:bg-white bg-opacity-20 sm:mx-10"
                  // onClick={() => setSeek(currentTime + 10)}
                  onClick={() => seekTen('+')}
                >
                  <MdForward10 size={28} />
                </VideoPlayerButton>
              </>
            )}
          </div>

          <div className="w-full space-y-5 sm:px-3">
            <InputSlider value={currentTime} max={duration} onChange={setSeek} onSliderClick={setSeek} />
            <div className="flex justify-between text-white text-opacity-70 sm:px-3">
              <div className="flex items-center pt-1 pb-2 space-x-3 md:space-x-6">
                <VideoPlayerButton
                  onClick={() => {
                    setSeek(currentTime);
                    // setCurrentTime(currentTime);
                    setPlaying(!playing);
                  }}
                >
                  {playing ? (
                    <MdPause className="text-xl md:text-2xl" />
                  ) : (
                    <MdPlayArrow className="text-xl md:text-2xl" />
                  )}
                </VideoPlayerButton>
                <div className="flex items-center space-x-3">
                  <VideoPlayerButton onClick={() => setIsMuted(!isMuted)}>
                    {isMuted && <MdVolumeOff className="text-xl md:text-2xl" />}
                    {!isMuted && volume === 0 && <MdVolumeMute className="text-xl md:text-2xl" />}
                    {!isMuted && volume !== 0 && volume <= 50 && <MdVolumeDown className="text-xl md:text-2xl" />}
                    {!isMuted && volume > 50 && <MdVolumeUp className="text-xl md:text-2xl" />}
                  </VideoPlayerButton>
                  <div className="w-10 md:w-16">
                    <InputSlider value={volume} onChange={handleChangeVolume} onSliderClick={handleChangeVolume} />
                  </div>
                </div>
                <button className="flex space-x-1 text-xs" type="button">
                  <span>{HELPERS.formatSeconds(currentTime)}</span>{' '}
                  <span className="hidden sm:block"> / {HELPERS.formatSeconds(duration)}</span>
                </button>
              </div>
              <div className="flex items-center space-x-3 md:space-x-6">
                <div className="relative popover">
                  {isShowResolutionOption && (
                    <div className="absolute z-10 overflow-hidden text-xs transform -translate-x-1/2 -translate-y-full bg-white rounded-sm shadow-md left-1/2 -top-3">
                      {videoResolutionURLs.map((video) => (
                        <button
                          key={video.url}
                          className={`${
                            video.url === videoURL
                              ? 'bg-gray-200 text-primary font-semibold'
                              : 'text-gray-500 bg-white'
                          } px-3 py-2 hover:bg-gray-300 w-full`}
                          onClick={() => handleChangeVideoURL(video.url)}
                          type="button"
                        >
                          {video.resolution}p
                        </button>
                      ))}
                    </div>
                  )}
                  <VideoPlayerButton onClick={() => setIsShowResolutionOption(!isShowResolutionOption)}>
                    <MdHighQuality className="text-xl md:text-2xl" />
                  </VideoPlayerButton>
                </div>
                <div className="relative">
                  {isShowPlaybackRate && (
                    <div className="absolute z-10 overflow-hidden text-xs transform -translate-x-1/2 -translate-y-full bg-white rounded-sm shadow-md left-1/2 -top-3">
                      {PLAYBACK_RATE_OPTIONS.map((rate) => (
                        <button
                          key={rate}
                          className={`${
                            playbackRate === rate
                              ? 'bg-gray-200 text-primary font-semibold'
                              : 'text-gray-500 bg-white'
                          } px-3 py-2 hover:bg-gray-300 w-full`}
                          onClick={() => setPlaybackRate(rate)}
                          type="button"
                        >
                          {rate}x
                        </button>
                      ))}
                    </div>
                  )}
                  <VideoPlayerButton
                    className="text-sm"
                    onClick={() => setIsShowPlaybackRate(!isShowPlaybackRate)}
                  >
                    {playbackRate}x
                  </VideoPlayerButton>
                </div>
                {!isFullscreen && (
                  <VideoPlayerButton className="hidden lg:block" onClick={() => setIsPip(!isPip)}>
                    <MdPictureInPictureAlt className="text-xl md:text-2xl" />
                  </VideoPlayerButton>
                )}
                <VideoPlayerButton onClick={() => handleFullScreen()}>
                  {isFullscreen ? (
                    <MdFullscreenExit className="text-xl md:text-2xl" />
                  ) : (
                    <MdFullscreen className="text-xl md:text-2xl" />
                  )}
                </VideoPlayerButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
