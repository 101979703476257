import { TYPES_PROFILE } from './profile.types';

const INITIAL_STATE = {
  learningDuration: null,
  onGoingVideos: null,
  onGoingVideosLoading: false,
  completedVideos: null,
  completedVideosLoading: false,
  onGoingCourses: null,
  onGoingCoursesLoading: false,
  completedCourses: null,
  completedCoursesLoading: false,
  profile: null,
  profileSurvey: null,
  profileLoading: false,
  profileSubmitting: false,
};

const profileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES_PROFILE.GET_LEARNING_DURATION:
      return { ...state, learningDuration: action.payload };

    case TYPES_PROFILE.SET_ONGOING_VIDEOS_LOADING:
      return { ...state, onGoingVideosLoading: action.loading };
    case TYPES_PROFILE.GET_ONGOING_VIDEOS:
      return { ...state, onGoingVideos: action.payload };

    case TYPES_PROFILE.SET_ONGOING_COURSES_LOADING:
      return { ...state, onGoingCoursesLoading: action.loading };
    case TYPES_PROFILE.GET_ONGOING_COURSES:
      return { ...state, onGoingCourses: action.payload };

    case TYPES_PROFILE.SET_COMPLETED_VIDEOS_LOADING:
      return { ...state, completedVideosLoading: action.loading };
    case TYPES_PROFILE.GET_COMPLETED_VIDEOS:
      return { ...state, completedVideos: action.payload };

    case TYPES_PROFILE.SET_COMPLETED_COURSES_LOADING:
      return { ...state, completedCoursesLoading: action.loading };
    case TYPES_PROFILE.GET_COMPLETED_COURSES:
      return { ...state, completedCourses: action.payload };

    case TYPES_PROFILE.UPDATE_PROFILE_LOADING:
      return { ...state, profileLoading: action.loading };
    case TYPES_PROFILE.UPDATE_PROFILE_SUBMITTING:
      return { ...state, profileSubmitting: action.loading };
    case TYPES_PROFILE.GET_PROFILE:
      return { ...state, profile: action.payload };
    case TYPES_PROFILE.GET_PROFILE_SURVEY:
      return { ...state, profileSurvey: action.payload };
    case TYPES_PROFILE.DELETE_PROFILE:
      return { ...state, profile: null };

    default:
      return state;
  }
};

export default profileReducer;
