import { TYPES_ACTIVITY } from './activity.types';

const INITIAL_STATE = {
  showCatalogMenu: false,
  showSideNav: false,
  showFullpageLoader: true,
  showTopLoadingBar: false,
  showHeader: true,
  showHeaderNav: true,
  showHeaderProductFilter: false,
  showFooter: true,
  ipAddress: null,
};

const activityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES_ACTIVITY.TOGGLE_SIDENAV:
      return { ...state, showSideNav: !state.showSideNav };

    case TYPES_ACTIVITY.SHOW_CATALOG_MENU:
      return { ...state, showCatalogMenu: true };
    case TYPES_ACTIVITY.HIDE_CATALOG_MENU:
      return { ...state, showCatalogMenu: false };

    case TYPES_ACTIVITY.SHOW_TOP_LOADING_BAR:
      return { ...state, showTopLoadingBar: true };
    case TYPES_ACTIVITY.HIDE_TOP_LOADING_BAR:
      return { ...state, showTopLoadingBar: false };

    case TYPES_ACTIVITY.SHOW_FULLPAGE_LOADER:
      return { ...state, showFullpageLoader: true };
    case TYPES_ACTIVITY.HIDE_FULLPAGE_LOADER:
      return { ...state, showFullpageLoader: false };

    case TYPES_ACTIVITY.SHOW_HEADER:
      return { ...state, showHeader: true };
    case TYPES_ACTIVITY.HIDE_HEADER:
      return { ...state, showHeader: false };

    case TYPES_ACTIVITY.SHOW_HEADER_PRODUCT_FILTER:
      return { ...state, showHeaderProductFilter: true };
    case TYPES_ACTIVITY.HIDE_HEADER_PRODUCT_FILTER:
      return { ...state, showHeaderProductFilter: false };

    case TYPES_ACTIVITY.SHOW_HEADER_NAV:
      return { ...state, showHeaderNav: true };
    case TYPES_ACTIVITY.HIDE_HEADER_NAV:
      return { ...state, showHeaderNav: false };

    case TYPES_ACTIVITY.SHOW_FOOTER:
      return { ...state, showFooter: true };
    case TYPES_ACTIVITY.HIDE_FOOTER:
      return { ...state, showFooter: false };

    case TYPES_ACTIVITY.IP_ADDRESS:
      return { ...state, ipAddress: action.ipAddress };

    default:
      return state;
  }
};

export default activityReducer;
