import { StarRating } from '@/components/atoms';
import './testimonial.css';
import raditya from '@/images/homepage/raditya_testimoni.jpg';
import fitri from '@/images/homepage/fitri_testimoni.jpg';

export const Testimonial = () => {
  return (
    <div className="w-screen px-10 py-20 bg-white">
      <h1 className="mb-14 font-bold text-4xl text-center">Testimonial</h1>
      <div className="xl:flex space-y-5 xl:space-y-0">
        <div className="w-full xl:w-1/2 min-h-80 px-5 pb-5">
          <div className="h-full w-full py-7 px-10 shadow-lg testimonial-container">
            <div className="mb-5 flex items-center">
              <img className="h-14 w-14 object-cover rounded-full" src={raditya} alt="" />
              <div className="ml-4">
                <h4 className="mb-1 font-bold">Raditya</h4>
                <p className="text-gray-500">Mitra</p>
              </div>
            </div>
            <StarRating value={5} size={25} />
            <div className="mt-5 flex text-gray-500">
              <p className="mr-1">“</p>
              <p>
                Video pembelajaran di Tumbu keren banget! Materinya simpel tapi mudah untuk dipahami oleh saya “
              </p>
            </div>
          </div>
        </div>
        <div className="w-full xl:w-1/2 min-h-80 px-5 pb-5">
          <div className="h-full w-full py-7 px-10 shadow-lg testimonial-container">
            <div className="mb-5 flex items-center">
              <img className="h-14 w-14 object-cover rounded-full" src={fitri} alt="" />
              <div className="ml-4">
                <h4 className="mb-1 font-bold">Fitri</h4>
                <p className="text-gray-500">Mitra</p>
              </div>
            </div>
            <StarRating value={5} size={25} />
            <div className="mt-5 flex text-gray-500">
              <p className="mr-1">“</p>
              <p>
                Awalnya, saya sempat ragu untuk mendaftar karena saya kira Tumbu hanya menyediakan video
                pembelajaran dan tes aja. Tetapi, setelah saya tahu ada layanan Coaching bagi para UKM, saya
                langsung mendaftar dan terasa sekali manfaatnya bagi UKM saya :D “
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
