import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaFilter } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import { Button, StarRating } from '@/components/atoms';
import { ACTION_COURSES, ACTION_VIDEOS } from '@/redux/actions';
import { CONSTANTS } from '@/utils';
import Avatar from '@/images/avatar/avatar.png';
import { CommentCard } from './CommentCard';

export const Comment = ({ uuid, productType, className }) => {
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.profile.profile);
  const isLoading = useSelector((state) =>
    productType === CONSTANTS.PRODUCT_TYPE.VIDEO ? state.videos.reviewsLoading : state.courses.reviewsLoading
  );
  const reviewsData = useSelector((state) =>
    productType === CONSTANTS.PRODUCT_TYPE.VIDEO ? state.videos.reviewsData : state.courses.reviewsData
  );

  const [ratingValue, setRatingValue] = useState(0);
  const [commentInput, setCommentInput] = useState('');
  const [totalComment, setTotalComment] = useState(0);
  const [error, setError] = useState('');
  const [isCommented, setIsCommented] = useState(false);

  const handleSubmitCallback = () => {
    setRatingValue(0);
    setCommentInput('');
    setError('');
    setIsCommented(true);
  };

  const handleSubmit = () => {
    if (ratingValue === 0) setError('Mohon tuliskan saran Anda untuk perbaikan kami');
    else if (!commentInput && ratingValue < 3) setError('Mohon tuliskan saran Anda untuk perbaikan kami');
    else {
      setError('');
      if (productType === CONSTANTS.PRODUCT_TYPE.VIDEO) {
        dispatch(
          ACTION_VIDEOS.setVideoRating(uuid, commentInput, ratingValue, () => {
            dispatch(ACTION_VIDEOS.getVideoReviewsData(uuid));
            handleSubmitCallback();
          })
        );
      } else if (productType === CONSTANTS.PRODUCT_TYPE.COURSE) {
        dispatch(
          ACTION_COURSES.setCourseRating(uuid, commentInput, ratingValue, () => {
            dispatch(ACTION_COURSES.getCourseReviewsData(uuid));
            handleSubmitCallback();
          })
        );
      }
    }
  };

  useEffect(() => {
    setError('');
    setRatingValue(0);
    setCommentInput('');
  }, []);

  useEffect(() => {
    if (productType === CONSTANTS.PRODUCT_TYPE.VIDEO) dispatch(ACTION_VIDEOS.getVideoReviewsData(uuid));
    else if (productType === CONSTANTS.PRODUCT_TYPE.COURSE) dispatch(ACTION_COURSES.getCourseReviewsData(uuid));
  }, [uuid]);

  useEffect(() => {
    if (reviewsData) {
      if (reviewsData.length > 0) {
        setTotalComment(reviewsData.length);
        let isFound = null;
        if (profile && profile.uuid) {
          isFound = reviewsData.some((el) => el.user.uuid === profile.uuid);
        }
        if (isFound) setIsCommented(true);
        else setIsCommented(false);
      } else {
        setTotalComment(0);
        setIsCommented(false);
      }
    }
  }, [reviewsData, profile]);

  return (
    <div className={`py-5 mb-5 ${className}`}>
      <div className="pb-2 flex space-x-7 mb-3">
        <span>{totalComment} Komentar</span>
        <button type="button" className="flex items-center space-x-2 text-gray-500 hover:text-black">
          <FaFilter />
          <span>SORT BY</span>
        </button>
      </div>
      {profile && !isCommented && (
        <div className="py-3 flex space-x-5">
          <img
            // src={profile.avatar_url ? profile.avatar_url : Avatar}
            src={Avatar}
            alt=""
            className="rounded-full sm:h-16 h-12"
          />
          <div className="space-y-2">
            <div className="flex space-x-2">
              <StarRating value={ratingValue} onChangeRating={(value) => setRatingValue(value)} size={24} />
              {error !== '' && <span className="p-2 text-sm text-white bg-yellow-500">{error}</span>}
            </div>
            <textarea
              className="border-0 bg-white outline-none focus:ring-0 text-sm w-full"
              placeholder="Tulis Komentar Disini"
              onChange={(e) => setCommentInput(e.target.value)}
              cols="100"
              value={commentInput}
            />
            <div className="flex items-center space-x-5">
              <Button size={Button.SIZES.SMALL2X} onClick={handleSubmit}>
                Komentar
              </Button>
            </div>
          </div>
        </div>
      )}
      {isLoading && (
        <div>
          <Comment.Skeleton />
          <Comment.Skeleton />
        </div>
      )}
      {!isLoading && (
        <div className="pt-2 space-y-5">
          {!totalComment && (
            <div className="flex justify-center py-5">
              <span className="text-gray-500 font-medium">Tidak ada komentar</span>
            </div>
          )}
          {totalComment > 0 &&
            reviewsData
              .filter((item) => item.user.uuid === profile.uuid)
              .map((item) => (
                <CommentCard
                  key={`comment ${item.created_at}`}
                  name={item.user.name}
                  rating={item.rating}
                  body={item.user_comment}
                  date={item.created_at}
                />
              ))}
          {totalComment > 0 &&
            reviewsData
              .filter((item) => item.user.uuid !== profile.uuid)
              .map((item) => (
                <CommentCard
                  key={`comment ${item.created_at}`}
                  name={item.user.name}
                  rating={item.rating}
                  body={item.user_comment}
                  date={item.created_at}
                />
              ))}
        </div>
      )}
    </div>
  );
};

Comment.Skeleton = () => {
  return (
    <div className="flex space-x-5 my-5">
      <Skeleton height={65} width={65} circle />
      <div className="flex flex-col space-y-2">
        <div className="flex space-x-5">
          <Skeleton height={20} width={200} />
          <Skeleton height={20} width={100} />
          <Skeleton height={20} width={150} />
        </div>
        <Skeleton height={20} width={300} />
      </div>
    </div>
  );
};
