import PropTypes from 'prop-types';
import classNames from 'classnames';

export const InputRadio = ({ color, className, label, checked, disabled, onChange, ...props }) => {
  const containerClasses = classNames('flex items-center text-sm cursor-pointer', {
    'opacity-50': disabled,
    'text-gray-400': !checked || color === InputRadio.COLORS.DEFAULT,
    'text-primary': checked && color === InputRadio.COLORS.PRIMARY,
    'text-secondary': checked && color === InputRadio.COLORS.SECONDARY,
    'text-white': checked && color === InputRadio.COLORS.WHITE,
  });

  const backgroundClasses = classNames({
    'bg-gray-400': !checked || color === InputRadio.COLORS.DEFAULT,
    'bg-primary': checked && color === InputRadio.COLORS.PRIMARY,
    'bg-secondary': checked && color === InputRadio.COLORS.SECONDARY,
    'bg-white': checked && color === InputRadio.COLORS.WHITE,
  });

  const borderClasses = classNames({
    'border-gray-400': !checked || color === InputRadio.COLORS.DEFAULT,
    'border-primary': checked && color === InputRadio.COLORS.PRIMARY,
    'border-secondary': checked && color === InputRadio.COLORS.SECONDARY,
    'border-white': checked && color === InputRadio.COLORS.WHITE,
  });

  return (
    <label className={`${containerClasses} ${className}`} htmlFor={props.name}>
      <input
        className="hidden"
        type="radio"
        name={props.name}
        id={props.name}
        checked={checked}
        onChange={onChange}
        {...props}
      />
      <div className={`flex items-center justify-center w-4 h-4 border-2 rounded-full mr-2 ${borderClasses}`}>
        {checked && <span className={`w-2 h-2 inline-block rounded-full ${backgroundClasses}`} />}
      </div>
      {label && <span>{label}</span>}
    </label>
  );
};

InputRadio.COLORS = {
  DEFAULT: 'DEFAULT',
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  WHITE: 'WHITE',
};

InputRadio.propTypes = {
  color: PropTypes.oneOf(Object.values(InputRadio.COLORS)),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string,
};

InputRadio.defaultProps = {
  color: InputRadio.COLORS.PRIMARY,
  disabled: false,
  checked: false,
  onChange: () => {},
  label: '',
  className: '',
};
