export const Tabs = ({ title, active, onClick, className }) => {
  return (
    <button
      className={`py-3 px-6 text-sm border-b-2 ${
        active ? 'border-primary text-primary font-semibold' : 'border-transparent text-gray-500'
      } ${className}`}
      type="button"
      onClick={onClick}
    >
      {title}
    </button>
  );
};
