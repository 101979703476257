import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CONSTANTS } from '@/utils';
import PATH from './path';
import Home from './Home/Home';
import OTP from './Auth/OTP';
import Login from './Auth/Login';
import LoginWarning from './Auth/LoginWarning';
import Register from './Auth/Register';
import ForgotPassword from './Auth/ForgotPassword';
import EmailSent from './Auth/EmailSent';
import ResetPassword from './Auth/ResetPassword';
import CourseDetail from './Course/CourseDetail';
import CourseList from './Course/CourseList';
import Catalog from './Catalog/Catalog';
import RiwayatBelajar from './Profile/RiwayatBelajar/RiwayatBelajar';
import { PoinSaya } from './Profile/PoinSaya/PoinSaya';
import JadwalCoaching from './Profile/JadwalCoaching/JadwalCoaching';
import ProfileSetting from './Profile/ProfileSetting/ProfileSetting';
import Payment from './Payment/Payment';
import TransactionList from './Transaction/TransactionList';
import TransactionDetail from './Transaction/TransactionDetail';
import TransactionCancelled from './Transaction/TransactionCancelled';
import VideoList from './Video/VideoList';
import VideoDetail from './Video/VideoDetail';
import Certificate from './Certificate/Certificate';
import Disclaimer from './Footer/Disclaimer';
import Privacy from './Footer/Privacy';
import AboutUs from './Footer/AboutUs';
import Partner from './Footer/Partner';
import LoginWA from './Auth/LoginWA';

const ProtectedRoute = (props) => {
  const { loggedIn } = useSelector((state) => state.auth);
  return loggedIn ? <Route {...props} /> : <Redirect to={PATH.AUTH_LOGIN} />;
};

const AuthenticationRoute = (props) => {
  const { loggedIn } = useSelector((state) => state.auth);
  return loggedIn ? <Redirect to={PATH.HOME} /> : <Route {...props} />;
};

const Routes = () => {
  return (
    <Switch>
      <Route exact path={PATH.HOME} render={() => <Home documentTitle="Beranda - Tumbu" />} />
      <Route path={`${PATH.VIDEO}/:uid/:title`} render={() => <VideoDetail />} />
      <Route path={`${PATH.VIDEO}/:uid`} render={() => <VideoDetail />} />
      <Route path={PATH.VIDEO} render={() => <VideoList documentTitle="Daftar Video - Tumbu" />} />
      <Route path={`${PATH.COURSE}/:uid/:title`} render={() => <CourseDetail />} />
      <Route path={`${PATH.COURSE}/:uid`} render={() => <CourseDetail />} />
      <Route path={PATH.COURSE} render={() => <CourseList documentTitle="Daftar Kursus - Tumbu" />} />
      <Route path={PATH.CATALOG} render={() => <Catalog productType={CONSTANTS.PRODUCT_TYPE.COURSE} />} />
      <Route path={`${PATH.CERTIFICATE}/:uid`} render={() => <Certificate documentTitle="Sertifikat - Tumbu" />} />
      <Route path={`${PATH.DISCLAIMER}`} render={() => <Disclaimer documentTitle="Disclaimer - Tumbu" />} />
      <Route path={`${PATH.PRIVACY_POLICY}`} render={() => <Privacy documentTitle="Privacy Policy - Tumbu" />} />
      <Route path={`${PATH.ABOUT_US}`} render={() => <AboutUs documentTitle="Tentang Kami - Tumbu" />} />
      <Route path={`${PATH.PARTNER}`} render={() => <Partner documentTitle="Partner Kami - Tumbu" />} />
      <AuthenticationRoute
        path={PATH.AUTH_LOGIN_WARNING}
        render={() => <LoginWarning documentTitle="5 Video Gratis Tercapai - Tumbu" />}
      />
      <AuthenticationRoute path={PATH.AUTH_LOGIN} render={() => <Login documentTitle="Login - Tumbu" />} />
      <AuthenticationRoute
        path={PATH.AUTH_LOGIN_WA}
        render={() => <LoginWA documentTitle="WhatsApp Login - Tumbu" />}
      />
      <AuthenticationRoute
        path={PATH.AUTH_REGISTER}
        render={() => <Register documentTitle="Registrasi - Tumbu" />}
      />
      <AuthenticationRoute path={PATH.AUTH_FORGOT_PASSWORD_EMAIL_SENT} render={() => <EmailSent />} />
      <AuthenticationRoute
        path={PATH.AUTH_FORGOT_PASSWORD}
        render={() => <ForgotPassword documentTitle="Lupa Password - Tumbu" />}
      />
      <AuthenticationRoute path={PATH.AUTH_RESET_PASSWORD} render={() => <ResetPassword />} />
      <AuthenticationRoute path={PATH.AUTH_OTP} render={() => <OTP />} />
      <ProtectedRoute
        path={PATH.PROFILE_RIWAYAT_BELAJAR}
        render={() => <RiwayatBelajar documentTitle="Riwayat Belajar - Tumbu" />}
      />
      <ProtectedRoute
        path={PATH.PROFILE_POIN_SAYA}
        render={() => <PoinSaya documentTitle="Poin Saya - Tumbu" />}
      />
      <ProtectedRoute
        path={PATH.PROFILE_JADWAL_COACHING}
        render={() => <JadwalCoaching documentTitle="Jadwal Coaching - Tumbu" />}
      />
      <ProtectedRoute
        path={PATH.PROFILE_SETTING}
        render={() => <ProfileSetting documentTitle="Pengaturan Profil - Tumbu" />}
      />
      <ProtectedRoute path={PATH.PROFILE} render={() => <Redirect to={PATH.PROFILE_SETTING} />} />
      <ProtectedRoute path={PATH.PAYMENT} render={() => <Payment />} />
      <ProtectedRoute path={PATH.TRANSACTION_CANCELLED} render={() => <TransactionCancelled />} />
      <ProtectedRoute path={`${PATH.TRANSACTION}/:uid`} render={() => <TransactionDetail />} />
      <ProtectedRoute path={PATH.TRANSACTION} render={() => <TransactionList />} />
    </Switch>
  );
};

export default Routes;
