import { SERVICE_SHARE } from '@/services';
import { TYPES_ACTIVITY } from './activity.types';

export const ACTION_ACTIVITY = {
  showCatalogMenu: (show) => {
    return (dispatch) => {
      dispatch({ type: show ? TYPES_ACTIVITY.SHOW_CATALOG_MENU : TYPES_ACTIVITY.HIDE_CATALOG_MENU });
    };
  },

  toggleSideNav: () => {
    return (dispatch) => dispatch({ type: TYPES_ACTIVITY.TOGGLE_SIDENAV });
  },

  showTopLoadingBar: (show) => {
    return (dispatch) => {
      dispatch({ type: show ? TYPES_ACTIVITY.SHOW_TOP_LOADING_BAR : TYPES_ACTIVITY.HIDE_TOP_LOADING_BAR });
    };
  },

  showHeader: (show) => {
    return (dispatch) => {
      dispatch({ type: show ? TYPES_ACTIVITY.SHOW_HEADER : TYPES_ACTIVITY.HIDE_HEADER });
    };
  },

  showHeaderNav: (show) => {
    return (dispatch) => {
      dispatch({ type: show ? TYPES_ACTIVITY.SHOW_HEADER_NAV : TYPES_ACTIVITY.HIDE_HEADER_NAV });
    };
  },

  showHeaderProductFilter: (show) => {
    return (dispatch) => {
      dispatch({
        type: show ? TYPES_ACTIVITY.SHOW_HEADER_PRODUCT_FILTER : TYPES_ACTIVITY.HIDE_HEADER_PRODUCT_FILTER,
      });
    };
  },

  showFooter: (show) => {
    return (dispatch) => {
      dispatch({ type: show ? TYPES_ACTIVITY.SHOW_FOOTER : TYPES_ACTIVITY.HIDE_FOOTER });
    };
  },

  storeShare: (data, callback) => {
    return () => {
      SERVICE_SHARE.storeShare(data, () => {
        if (callback) callback();
      });
    };
  },

  getIPAddress: () => {
    return (dispatch) => {
      SERVICE_SHARE.getIPAddress((res) => {
        if (res.payload) {
          dispatch({
            type: TYPES_ACTIVITY.IP_ADDRESS,
            ipAddress: res.payload,
          });
        }
      });
    };
  },

  storeVisitor: (data) => {
    return () => {
      SERVICE_SHARE.storeVisitor(data, () => {});
    };
  },
};
