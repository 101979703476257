import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_HOME } from '@/redux/actions';
import './bannerCourse.css';
import piching from '@/images/homepage/pitching_bawah.jpg';

export const BannerCourse = ({ formatting }) => {
  const dispatch = useDispatch();

  const homeData = useSelector((state) => state.home.data);

  useEffect(() => dispatch(ACTION_HOME.getHomeData()), []);

  return (
    <div className="w-screen xl:flex px-10 py-20 xl:py-40 bg-gradient-green">
      <div className="w-full xl:w-1/2 banner-course-height-tablet xl:h-0">
        <div className="relative sm:-right-1/4 xl:right-0 w-full">
          <div className="absolute banner-course-svg-top left-5 xl:left-auto xl:-top-16 xl:right-24 z-20">
            <svg height="453" viewBox="0 0 534 577" fill="none" xmlns="http://www.w3.org/2000/svg">
              <ellipse cx="393.776" cy="41.9517" rx="41.9881" ry="41.9517" fill="white" />
              <ellipse cx="52.3465" cy="449.325" rx="41.9881" ry="41.9517" fill="#FE753F" />
              <path
                d="M223 429.128L87 565.01M223 460.101L149.5 533.537M133.5 576L292 417.637M144 539.532L138.5 545.027"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M464 58.4493L328 194.331M464 89.4224L390.5 162.859M374.5 205.322L533 46.9592M385 168.853L379.5 174.349"
                stroke="#FE753F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <ellipse cx="496.537" cy="319.054" rx="18.7842" ry="18.7679" fill="#058FCB" />
              <ellipse cx="523.055" cy="367.629" rx="9.94455" ry="9.93593" fill="white" />
              <ellipse cx="68.9208" cy="30.9118" rx="9.94455" ry="9.93593" fill="#FE753F" />
              <ellipse cx="10.3584" cy="93.8394" rx="9.94455" ry="9.93593" fill="#058FCB" />
            </svg>
          </div>
          <div className="h-fit w-fit absolute left-4 top-5 xl:left-auto xl:-top-12 xl:right-8 z-10">
            <img className="object-cover banner-course-img rounded-full" src={piching} alt="" />
          </div>
          <div className="banner-course-svg-bottom absolute top-2 xl:-top-14 xl:right-40">
            <svg height="380" viewBox="0 0 508 507" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M506.067 253.51C506.067 392.829 393.029 505.771 253.586 505.771C114.144 505.771 1.10495 392.829 1.10495 253.51C1.10495 114.191 114.144 1.2489 253.586 1.2489C393.029 1.2489 506.067 114.191 506.067 253.51Z"
                stroke="white"
                strokeWidth="2.2099"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="w-full xl:w-1/2 xl:px-20 text-white">
        <p className="mb-3 font-medium yellow-text tracking-wide">Kursus Pilihan</p>
        <h2 className="text-3xl md:text-4xl font-bold leading-normal">
          Mari bergabung dan belajar kewirausahaan bersama kami.
        </h2>
        <p className="mt-3">
          Tumbu merupakan website e-learning penyedia video dan kursus pembelajaran yang menyediakan materi tentang
          UKM dan bisnis lokal terlengkap di Indonesia
        </p>
        <div className="grid grid-container-sm mt-10 xl:flex space-x-2 sm:space-x-10">
          <div className="flex flex-col items-center mb-4">
            <h3 className="text-2xl font-medium yellow-text">
              {homeData && formatting(homeData.home.total_views)}
            </h3>
            <p>Views</p>
          </div>
          <div className="flex flex-col items-center mb-4">
            <h3 className="text-2xl font-medium yellow-text">
              {homeData && formatting(homeData.home.total_views)}
            </h3>
            <p>Viewers</p>
          </div>
          <div className="flex flex-col items-center mb-4">
            <h3 className="text-2xl font-medium yellow-text">
              {homeData && formatting(homeData.home.total_users)}
            </h3>
            <p>Learners</p>
          </div>
          <div className="flex flex-col items-center mb-4">
            <h3 className="text-2xl font-medium yellow-text">
              {homeData && formatting(homeData.home.total_public_courses)}
            </h3>
            <p>Courses</p>
          </div>
          <div className="flex flex-col items-center">
            <h3 className="text-2xl font-medium yellow-text">
              {homeData && formatting(homeData.home.total_public_courses)}
            </h3>
            <p>Videos</p>
          </div>
        </div>
      </div>
    </div>
  );
};
