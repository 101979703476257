exports.COLOR = {
  PRIMARY: {
    100: '#cee8e5',
    200: '#9dd2cc',
    300: '#6dbbb2',
    400: '#3ca599',
    DEFAULT: '#0b8e7f',
    500: '#0b8e7f',
    600: '#097266',
    700: '#07554c',
    800: '#043933',
    900: '#021c19',
  },
  SECONDARY: {
    100: '#fee4d3',
    200: '#fdc9a6',
    300: '#fcae7a',
    400: '#fb934d',
    DEFAULT: '#fa7821',
    500: '#fa7821',
    600: '#c8601a',
    700: '#964814',
    800: '#64300d',
    900: '#321807',
  },
  TERTIARY: {
    100: '#ecfefc',
    200: '#d9fcf8',
    300: '#c6fbf5',
    400: '#b3f9f1',
    500: '#a0f8ee',
    DEFAULT: '#a0f8ee',
    600: '#80c6be',
    700: '#60958f',
    800: '#40635f',
    900: '#203230',
  },
};
