import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@/components/atoms';
import { ACTION_QUIZ } from '@/redux/actions';
import './Quiz.css';

export const Quiz = ({ uuid, title, questions, isSubmitted, className, testedCount, onQuizEnd }) => {
  const dispatch = useDispatch();

  const quizUUID = uuid;
  const isLoading = useSelector((state) => state.quiz.loading);
  const isLoadingAssessment = useSelector((state) => state.quiz.loadingAssessment);

  const [activeIndex, setActiveIndex] = useState(0);
  const [answers, setAnswers] = useState(questions && questions.length ? Array(questions.length) : []);
  const [isPassed, setIsPassed] = useState(null);
  const [score, setScore] = useState(null);
  const [error, setError] = useState('');

  const handleClickNext = () => {
    if (answers[activeIndex]) {
      setActiveIndex((prevState) => prevState + 1);
    } else setError('harap pilih jawaban');
  };

  const handleChangeAnswer = (event) => {
    const questionUUID = questions[activeIndex].uuid;
    const optionUUID = event.target.value;

    answers[activeIndex] = { [questionUUID]: optionUUID };
    setAnswers([...answers]);
    setError('');
  };

  const handleSubmit = () => {
    dispatch(
      ACTION_QUIZ.submitQuiz(quizUUID, answers, (res) => {
        setScore(res.score);
        setIsPassed(res.is_passed);
      })
    );
    if (onQuizEnd) onQuizEnd();
  };

  const skipQuiz = () => {
    dispatch(
      ACTION_QUIZ.submitQuiz(quizUUID, null, (res) => {
        setScore(res.score);
        setIsPassed(res.is_passed);
      })
    );
    if (onQuizEnd) onQuizEnd();
  };

  const handleReattemptQuiz = () => {
    setAnswers(Array(questions.length));
    setActiveIndex(0);
    setScore(null);
  };

  useEffect(() => {
    if (isSubmitted) {
      dispatch(
        ACTION_QUIZ.getQuiz(uuid, (res) => {
          setIsPassed(res.is_passed);
          setScore(res.score);
        })
      );
    } else {
      setIsPassed(false);
      setScore(null);
    }
  }, [isSubmitted]);

  return (
    <div className={`space-y-6 ${className}`}>
      <div className="font-semibold">{title}</div>

      {!questions && <div className="text-center text-sm text-gray-400 italic py-32">Question is empty</div>}

      {((questions && activeIndex + 1 > questions.length && isLoadingAssessment) || isLoading) && (
        <div className="flex flex-col items-center space-y-6 py-28">
          <img className="w-24" src={require('@/images/icons/assessment_process.png').default} alt="" />
          <p className="text-sm text-gray-400">Loading...</p>
        </div>
      )}

      {questions &&
        !isLoadingAssessment &&
        !isLoading &&
        score !== null &&
        (isPassed || isSubmitted || activeIndex + 1 > questions.length) && (
          <div className="flex flex-col items-center space-y-6 py-10">
            {isPassed && <img className="w-32" src={require('@/images/icons/quiz_success.png').default} alt="" />}
            {!isPassed && <img className="w-32" src={require('@/images/icons/quiz_failed.png').default} alt="" />}
            <div className="space-y-2 text-center flex flex-col items-center">
              <div className="font-semibold">Skor Kuis Anda :</div>
              <div className={`text-5xl font-semibold ${isPassed ? 'text-primary' : 'text-red-500'}`}>{score}</div>

              {!isPassed && (
                <div className="text-sm w-8/12">
                  Sayang sekali Anda belum dinyatakan lulus dari Kuis ini. Namun jangan khawatir, Anda bisa
                  mengulang kembali materi dan kuisnya. Semangat!
                </div>
              )}
            </div>
            {!isPassed && (
              <div className="w-full border-t border-gray-200 mt-6 pt-6 flex justify-center items-center">
                <Button onClick={handleReattemptQuiz}>Ulang Kuis</Button>
              </div>
            )}
          </div>
        )}

      {(!isLoading || !isLoadingAssessment) &&
        questions &&
        activeIndex + 1 <= questions.length &&
        score === null &&
        (!isSubmitted || !isPassed) && (
          <div>
            {questions.map((question, index) => (
              <div key={question.uuid}>
                {activeIndex === index && (
                  <div className="space-y-6">
                    <div className="text-lg">
                      <span className="font-semibold">{index + 1}.</span> {question.text}
                    </div>
                    <div className="space-y-3">
                      {question.options.map((option) => (
                        <label
                          key={option.uuid}
                          htmlFor={option.uuid}
                          className={`w-full cursor-pointer flex items-center justify-between px-6 py-3 border border-primary rounded-md text-sm ${
                            answers &&
                            answers.length &&
                            answers[activeIndex] &&
                            answers[activeIndex][question.uuid] === option.uuid.toString()
                              ? 'bg-primary text-white'
                              : 'bg-tertiary-100'
                          }`}
                        >
                          <span>{option.content}</span>
                          <input
                            id={option.uuid}
                            name={question.text}
                            value={option.uuid}
                            className="text-primary"
                            type="radio"
                            onChange={handleChangeAnswer}
                          />
                        </label>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}

            {error && <div className="text-sm text-red-500 py-6">{error}</div>}

            <div className="border-t border-gray-200 my-6 py-3">
              {questions && (
                <div className="sm:flex justify-between items-center space-y-3 sm:space-y-0">
                  <div className="text-sm">
                    <span className="font-semibold">{activeIndex + 1}</span> dari{' '}
                    <span className="font-semibold">{testedCount || questions.length}</span> pertanyaan
                  </div>
                  <div className="flex space-x-3">
                    {testedCount && activeIndex + 1 === testedCount ? (
                      <Button onClick={handleSubmit}>Lihat Skor</Button>
                    ) : (
                      ''
                    )}
                    {!testedCount && activeIndex + 1 === questions.length ? (
                      <Button onClick={handleSubmit}>Lihat Skor</Button>
                    ) : (
                      ''
                    )}
                    {testedCount && activeIndex + 1 !== testedCount ? (
                      <Button onClick={handleClickNext}>Selanjutnya</Button>
                    ) : (
                      ''
                    )}
                    {!testedCount && activeIndex + 1 !== questions.length ? (
                      <Button onClick={handleClickNext}>Selanjutnya</Button>
                    ) : (
                      ''
                    )}
                    {title.toLowerCase().split(' ').includes('pretest') && (
                      <Button onClick={skipQuiz}>Lewati Kuis</Button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
    </div>
  );
};
