import * as yup from 'yup';
import { REGEX_PATTERN } from './regex-pattern';

const registerSchema = yup.object().shape({
  name: yup.string().required('Nama lengkap tidak boleh kosong'),
  email: yup.string().email('Email tidak valid').required('Email tidak boleh kosong'),
  phone: yup
    .string()
    .matches(REGEX_PATTERN.number, 'Nomor Handphone tidak valid')
    .min(9, 'Nomor Handphone tidak valid')
    .required('Nomor tidak boleh kosong'),
  password: yup.string().min(8, 'Minimal 8 karakter').required('Kata sandi tidak boleh kosong'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Kata sandi tidak sesuai')
    .required('Konfirmasi kata sandi tidak boleh kosong'),
});

const profileSettingSchema = yup.object().shape({
  name: yup.string().required('Nama lengkap tidak boleh kosong'),
  email: yup.string().email('Email tidak valid').required('Email tidak boleh kosong'),
  phone: yup
    .string()
    .matches(REGEX_PATTERN.number, 'Nomor Handphone tidak valid')
    .min(10, 'Nomor Handphone tidak valid')
    .required('Nomor tidak boleh kosong'),
});

const loginSchema = yup.object().shape({
  email: yup.string().email('Email tidak valid').required('Email tidak boleh kosong'),
  password: yup.string().min(8, 'Minimal 8 karakter').required('Kata sandi tidak boleh kosong'),
});

const phoneNumberSchema = yup.object().shape({
  phone: yup
    .string()
    .matches(REGEX_PATTERN.number, 'Nomor Handphone tidak valid')
    .min(10, 'Nomor Handphone tidak valid')
    .required('Nomor tidak boleh kosong'),
});

const emailSchema = yup.object().shape({
  email: yup.string().email('Email tidak valid').required('Email tidak boleh kosong'),
});

const resetPasswordSchema = yup.object().shape({
  password: yup.string().min(8, 'Minimal 8 karakter').required('Kata sandi tidak boleh kosong'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Kata sandi tidak sesuai')
    .required('Konfirmasi kata sandi tidak boleh kosong'),
});

const bookingCoachingSchema = yup.object().shape({
  coaching_date: yup.string().required('Preferensi Tanggal Coaching tidak boleh kosong'),
  theme: yup.mixed().required('Tema tidak boleh kosong'),
  question: yup.string().required('Pertanyaan tidak boleh kosong'),
  // social_media: yup.string().required('Link Media Sosial tidak boleh kosong'),
});

export const VALIDATION_SCHEMA = {
  loginSchema,
  registerSchema,
  profileSettingSchema,
  phoneNumberSchema,
  emailSchema,
  resetPasswordSchema,
  bookingCoachingSchema,
};
