import PropTypes from 'prop-types';
import { useState } from 'react';
import classNames from 'classnames';
import { FiFilter, FiX } from 'react-icons/fi';
import { CONSTANTS } from '@/utils';
import { InputRadio, Backdrop, Button } from '@/components/atoms';

const CatalogFilter = ({ productType, onChangeProductType, onReset }) => {
  const [showCatalogFilterMobile, setShowCatalogFilterMobile] = useState(false);

  const catalogFilterMobileContainerClasses = classNames({
    'visible opacity-100': showCatalogFilterMobile,
    'invisible opacity-0 lg:visible lg:opacity-100': !showCatalogFilterMobile,
  });

  const catalogFilterMobileClasses = classNames({
    'translate-y-0': showCatalogFilterMobile,
    'translate-y-full lg:translate-y-0': !showCatalogFilterMobile,
  });

  return (
    <>
      <Button
        onClick={() => setShowCatalogFilterMobile(true)}
        className="fixed lg:hidden rounded-full px-6 bottom-5 shadow-lg left-1/2 transform -translate-x-1/2"
      >
        <FiFilter className="mr-6" size={14} />
        <span className="text-base">Filter</span>
      </Button>

      <div
        className={`fixed w-full h-screen lg:h-auto lg:sticky lg:top-24 lg:w-5/12 left-0 bottom-0 z-50 lg:z-10 ${catalogFilterMobileContainerClasses}`}
      >
        <div className="lg:hidden">
          <Backdrop active={showCatalogFilterMobile} onClick={() => setShowCatalogFilterMobile(false)} />
        </div>
        <div
          className={`bg-white absolute bottom-0  lg:relative shadow p-6 pb-28 lg:pb-6 transition-all rounded-md transform w-full left-0 duration-300 ${catalogFilterMobileClasses}`}
        >
          <div className="flex items-center justify-between pb-3 border-b">
            <div className="flex items-center">
              <FiFilter className="mr-3" size={14} />
              <span>Filter</span>
            </div>
            <div className="flex items-center">
              <button className="mr-6 lg:mr-0 text-secondary text-sm" type="button" onClick={onReset}>
                Reset
              </button>
              <button
                className="block lg:hidden bg-gray-100 rounded-full p-3"
                onClick={() => setShowCatalogFilterMobile(false)}
                type="button"
              >
                <FiX size={20} />
              </button>
            </div>
          </div>
          <form>
            <div className="py-5">
              <div className="mb-3 text-sm font-semibold">Tipe</div>
              <div className="grid grid-cols-2 gap-2 md:flex lg:flex-col md:space-x-3 lg:space-x-0">
                {Object.values(CONSTANTS.PRODUCT_TYPE).map((type) => (
                  <InputRadio
                    key={type.id}
                    checked={productType !== '' && productType.id === type.id}
                    value={type.id}
                    label={type.title}
                    onChange={(e) => onChangeProductType(Number(e.target.value))}
                    className="border rounded-md p-2 capitalize w-full"
                  />
                ))}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

CatalogFilter.propTypes = {
  onChangeProductType: PropTypes.func,
  onReset: PropTypes.func,
};

CatalogFilter.defaultProps = {
  onChangeProductType: () => {},
  onReset: () => {},
};

export default CatalogFilter;
