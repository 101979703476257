import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Button, Container } from '@/components/atoms';
import { ProductListFilter, VideoCard } from '@/components/molecules';
import { ACTION_ACTIVITY, ACTION_VIDEOS } from '@/redux/actions';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { HELPERS } from '@/utils';

const VideoList = ({ documentTitle }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const queries = location.search || null;
  const search = location.search ? HELPERS.queryStringToObject(location.search) : null;

  const videos = useSelector((state) => state.videos.listData);
  // const totalVideosData = useSelector((state) => state.videos.listTotal);
  const totalPage = useSelector((state) => state.videos.listTotalPage);
  const isLoading = useSelector((state) => state.videos.listLoading);
  const isLoadingLoadMore = useSelector((state) => state.videos.listLoadingLoadMore);

  // const ITEMS_PER_LOADMORE = 3;
  const INITIAL_ITEMS = 24;
  const ACTIVE_PAGE = 1;

  const [page, setPage] = useState(ACTIVE_PAGE);
  const [searchName, setSearchName] = useState('');

  const handleLoadMore = (id, action) => {
    // const nextPerPage = perPage + ITEMS_PER_LOADMORE;
    window.scrollTo(0, 0);

    let goToPage = null;
    if (id !== null) {
      goToPage = id;
    } else if (action === 'next') {
      goToPage = page + 1;
    } else {
      goToPage = page - 1;
    }

    setPage(goToPage);

    // dispatch(
    //   ACTION_VIDEOS.getVideoListLoadMoreData(
    //     goToPage,
    //     INITIAL_ITEMS,
    //     queries && HELPERS.queryStringToObject(queries)
    //   )
    // );
    dispatch(
      ACTION_VIDEOS.getVideoListData(goToPage, INITIAL_ITEMS, queries && HELPERS.queryStringToObject(queries))
    );
  };

  const handleChangeQueries = () => {
    setPage(ACTIVE_PAGE);
    dispatch(ACTION_VIDEOS.getVideoListData(page, INITIAL_ITEMS, queries && HELPERS.queryStringToObject(queries)));
  };

  useEffect(() => {
    document.title = documentTitle;
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send('pageview');
    dispatch(ACTION_ACTIVITY.showHeaderNav(false));
    dispatch(ACTION_ACTIVITY.showHeaderProductFilter(true));
    return () => {
      dispatch(ACTION_ACTIVITY.showHeaderNav(true));
      dispatch(ACTION_ACTIVITY.showHeaderProductFilter(false));
    };
  }, []);

  useEffect(() => handleChangeQueries(), [queries]);
  useEffect(() => {
    if (search && search.search && searchName !== search.search) {
      setSearchName(search.search);
      ReactGA.event('search', { search_term: `(Video) ${search.search}` });
    }
  }, [search]);

  return (
    <Container className="pt-28 pb-24 md:py-14 sm:flex items-start sm:space-y-0 sm:space-x-8">
      <ProductListFilter onChangeCategory={handleChangeQueries} className="hidden md:block md:w-1/3 lg:w-1/4" />
      {(isLoading || !videos) && <VideoList.Skeleton />}
      {!isLoading && videos && videos.length === 0 && (
        <div className="w-full text-sm italic text-gray-400 text-center">
          <div>Data video yang anda cari tidak ditemukan</div>
        </div>
      )}
      {!isLoading && videos && videos.length > 0 && (
        <div className="space-y-10 w-full md:w-2/3 lg:w-3/4">
          {search && search.search && (
            <p className="text-center">
              menampilkan hasil pencarian untuk "<span className="font-bold">{search.search}</span>"
            </p>
          )}
          <div className="w-full grid sm:grid-cols-2 xl:grid-cols-3 gap-y-8 sm:gap-4">
            {videos.map((video, id) => (
              <VideoCard
                key={video.uuid}
                index={id}
                page={page}
                uuid={video.uuid}
                thumbnail={video.thumbnail}
                title={video.title}
                description={video.description}
                rating={video.average_rating ? video.average_rating : 0}
                view={video.total_view || 0}
                categories={video.categories}
              />
            ))}
            {isLoadingLoadMore && (
              <>
                <VideoCard.Skeleton />
                <VideoCard.Skeleton />
                <VideoCard.Skeleton />
              </>
            )}
          </div>
          {videos && (
            <div className="space-y-6">
              <div className="flex justify-center">
                {page > 1 && (
                  <Button
                    onClick={() => handleLoadMore(null, 'prev')}
                    variant={Button.VARIANTS.PRIMARY_OUTLINE}
                    size={Button.SIZES.SQUARE}
                    fontSize={Button.FONT_SIZES.LARGE2X}
                    className="mx-1"
                  >
                    <MdKeyboardArrowLeft />
                  </Button>
                )}
                {totalPage !== null &&
                  Array.from({ length: totalPage }, (_, i) => i + 1).map((id) => (
                    <Button
                      key={`btn${id}`}
                      onClick={() => handleLoadMore(id)}
                      variant={page === id ? Button.VARIANTS.PRIMARY : Button.VARIANTS.PRIMARY_OUTLINE}
                      size={Button.SIZES.SQUARE}
                      className="mx-1"
                    >
                      {id}
                    </Button>
                  ))}
                {totalPage !== null && page < totalPage && (
                  <Button
                    onClick={() => handleLoadMore(null, 'next')}
                    variant={Button.VARIANTS.PRIMARY_OUTLINE}
                    size={Button.SIZES.SQUARE}
                    fontSize={Button.FONT_SIZES.LARGE2X}
                    className="mx-1"
                  >
                    <MdKeyboardArrowRight />
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </Container>
  );
};

VideoList.Skeleton = () => {
  return (
    <div className="w-full grid sm:grid-cols-2 lg:grid-cols-3 gap-4">
      <VideoCard.Skeleton />
      <VideoCard.Skeleton />
      <VideoCard.Skeleton />
    </div>
  );
};

export default VideoList;
