import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Banner } from './sectionsV2/Banner';
import { BannerCourse } from './sectionsV2/BannerCourse';
import { CourseShowcase } from './sectionsV2/CourseShowcase';
import { Features } from './sectionsV2/Features';
import { Testimonial } from './sectionsV2/Testimonial';

const Home = ({ documentTitle }) => {
  const numFormatting = (num) => {
    const numArr = num.toString().split('');
    const totalIndex = numArr.length - 1;
    return numArr.map((el, id) => (totalIndex - id !== 0 && (totalIndex - id) % 3 === 0 ? `${el},` : el)).join('');
  };

  useEffect(() => {
    document.title = documentTitle;
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.send('pageview');
  }, []);

  return (
    <div className="overflow-hidden">
      <Banner />
      <Features />
      <BannerCourse formatting={numFormatting} />
      <CourseShowcase formatting={numFormatting} />
      <Testimonial />
    </div>
  );
};

export default Home;
