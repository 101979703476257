import { MdPerson } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, CompanyLogo, Button } from '@/components/atoms';
import PATH from '@/routes/path';
import { HeaderSearch } from './components/HeaderSearch';
import { HeaderButtonSideNav } from './components/HeaderButtonSideNav';

export const HeaderDesktop = ({ loggedIn, authProfile }) => {
  const { t } = useTranslation();
  const { showHeaderNav } = useSelector((state) => state.activity);

  return (
    <div className="hidden lg:flex lg:items-stretch h-full">
      <Container>
        <div className="w-full flex justify-between h-full">
          <div className="flex flex-1 items-center space-x-14">
            <CompanyLogo link />
            {showHeaderNav && (
              <div className="text-gray-600">
                <Link to="/" className="px-5 py-3 transition-all rounded-md hover:bg-gray-100 hover:text-primary">
                  Home
                </Link>
                <Link
                  to={PATH.VIDEO}
                  className="px-5 py-3 transition-all rounded-md hover:bg-gray-100 hover:text-primary"
                >
                  Video
                </Link>
                <Link
                  to={PATH.COURSE}
                  className="px-5 py-3 transition-all rounded-md hover:bg-gray-100 hover:text-primary"
                >
                  Kursus
                </Link>
              </div>
            )}
          </div>
          <div className="flex flex-1 items-center justify-end space-x-3">
            <HeaderSearch desktop />
            {loggedIn && <HeaderButtonSideNav loggedIn={loggedIn} authProfile={authProfile} desktopMode />}
            {!loggedIn && (
              <div className="flex space-x-3">
                <Button
                  variant={Button.VARIANTS.PRIMARY_OUTLINE}
                  size={Button.SIZES.MEDIUM}
                  className="flex items-center"
                  linkTo={PATH.AUTH_LOGIN}
                >
                  <MdPerson className="mr-3" size={18} />
                  {t('auth_login')}
                </Button>
                <Button linkTo={PATH.AUTH_REGISTER}>{t('auth_register')}</Button>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};
