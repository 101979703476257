import './TransactionPipe.css';

const TransactionPipe = () => {
  return (
    <div className="transaction-pipe flex justify-between items-stretch">
      <div className="flex flex-1 flex-col items-center text-center text-sm">
        <div className="circle bg-primary" />
        <div className="text-gray-500">Pembayaran</div>
        <div className="line bg-primary" />
      </div>
      <div className="flex flex-1 flex-col items-center text-center text-sm">
        <div className="circle bg-primary" />
        <div className="text-gray-500">Menunggu Konfirmasi</div>
        <div className="line bg-primary" />
      </div>
      <div className="flex flex-1 flex-col items-center text-center text-sm">
        <div className="circle bg-primary" />
        <div className="text-gray-500">Siap Belajar</div>
      </div>
    </div>
  );
};

export default TransactionPipe;
