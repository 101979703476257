import { TYPES_FORMS } from './forms.types';

const INITIAL_STATE = {
  register: {
    name: '',
    phone: '',
    email: '',
    password: '',
    confirmPassword: '',
  },
};

const formReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES_FORMS.FORM_REGISTER:
      return { register: { ...state.register, ...action.values } };

    default:
      return state;
  }
};

export default formReducer;
