import PropTypes from 'prop-types';

export const Backdrop = ({ active, onClick }) => {
  const backdropClass = active ? `visible opacity-50` : 'invisible opacity-0';

  return (
    <div
      className={`${backdropClass} absolute inset-0 w-screen h-screen bg-black transition-all`}
      onClick={onClick}
      aria-hidden="true"
    />
  );
};

Backdrop.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

Backdrop.defaultProps = {
  active: false,
  onClick: () => {},
};
