import store from 'store';
import { CONSTANTS } from '@/utils';
import { http } from './http';

const BASE_URL = '/quiz';

export const SERVICE_QUIZ = {
  getQuiz: (quizUUID, callback) => {
    http
      .get(`${BASE_URL}/${quizUUID}/submissions`, {
        headers: { Authorization: `Bearer ${store.get(CONSTANTS.PROFILE_TOKEN)}` },
      })
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },
  submitQuiz: (quizUUID, quizAnswers, callback) => {
    const data = quizAnswers
      ? { answers: JSON.stringify(Object.assign({}, ...quizAnswers)) }
      : { answers: JSON.stringify(quizAnswers) };

    http
      .post(`${BASE_URL}/${quizUUID}`, data, {
        headers: { Authorization: `Bearer ${store.get(CONSTANTS.PROFILE_TOKEN)}` },
      })
      .then((res) => callback({ payload: res.data.payload, error: null }))
      .catch((error) => callback({ payload: null, error: error.response }));
  },
};
