import { SERVICE_PROFILE } from '@/services';
import { TYPES_PROFILE } from './profile.types';

const getLearningDuration = () => {
  return (dispatch) => {
    SERVICE_PROFILE.getLearningDuration((res) => {
      dispatch({
        type: TYPES_PROFILE.GET_LEARNING_DURATION,
        payload: res.payload,
      });
    });
  };
};

const getOnGoingVideos = () => {
  return (dispatch) => {
    SERVICE_PROFILE.getOnGoingVideos((res) => {
      dispatch({
        type: TYPES_PROFILE.GET_ONGOING_VIDEOS,
        payload: res.payload,
      });
    });
  };
};

const getOnGoingCourses = () => {
  return (dispatch) => {
    SERVICE_PROFILE.getOnGoingCourses((res) => {
      dispatch({
        type: TYPES_PROFILE.GET_ONGOING_COURSES,
        payload: res.payload,
      });
    });
  };
};

const getCompletedVideos = () => {
  return (dispatch) => {
    SERVICE_PROFILE.getCompletedVideos((res) => {
      dispatch({
        type: TYPES_PROFILE.GET_COMPLETED_VIDEOS,
        payload: res.payload,
      });
    });
  };
};

const getCompletedCourses = () => {
  return (dispatch) => {
    SERVICE_PROFILE.getCompletedCourses((res) => {
      dispatch({
        type: TYPES_PROFILE.GET_COMPLETED_COURSES,
        payload: res.payload,
      });
    });
  };
};

const getProfile = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: TYPES_PROFILE.UPDATE_PROFILE_LOADING, loading: true });

    SERVICE_PROFILE.getProfile(data, (res) => {
      dispatch({ type: TYPES_PROFILE.UPDATE_PROFILE_LOADING, loading: false });
      dispatch({
        type: TYPES_PROFILE.GET_PROFILE,
        payload: res.payload,
      });

      if (callback) callback();
    });
  };
};

const getProfileSurvey = (surveyId) => {
  return (dispatch) => {
    dispatch({ type: TYPES_PROFILE.UPDATE_PROFILE_LOADING, loading: true });

    SERVICE_PROFILE.getProfileSurvey(surveyId, (res) => {
      dispatch({ type: TYPES_PROFILE.UPDATE_PROFILE_LOADING, loading: false });
      dispatch({
        type: TYPES_PROFILE.GET_PROFILE_SURVEY,
        payload: res.payload,
      });
    });
  };
};

const updateProfile = (data, isWa, isEmail, callback) => {
  return (dispatch) => {
    dispatch({ type: TYPES_PROFILE.UPDATE_PROFILE_SUBMITTING, loading: true });
    SERVICE_PROFILE.updateProfile(data, isWa, isEmail, (res) => {
      dispatch({ type: TYPES_PROFILE.UPDATE_PROFILE_SUBMITTING, loading: false });
      dispatch({
        type: TYPES_PROFILE.GET_COMPLETED_COURSES,
        payload: res.payload,
      });

      if (res.payload && callback) callback(res.payload);
    });
  };
};

const updateProfileSurvey = (surveyId, surveyAnswers, callback) => {
  return (dispatch) => {
    dispatch({ type: TYPES_PROFILE.UPDATE_PROFILE_SUBMITTING, loading: true });
    SERVICE_PROFILE.updateProfileSurvey(surveyId, surveyAnswers, (res) => {
      dispatch({ type: TYPES_PROFILE.UPDATE_PROFILE_SUBMITTING, loading: false });
      // dispatch({
      //   type: TYPES_PROFILE.GET_COMPLETED_COURSES,
      //   payload: res.payload,
      // });

      if (res.payload && callback) callback(res.payload);
    });
  };
};

export const ACTION_PROFILE = {
  getLearningDuration,
  getOnGoingVideos,
  getOnGoingCourses,
  getCompletedVideos,
  getCompletedCourses,
  getProfile,
  getProfileSurvey,
  updateProfile,
  updateProfileSurvey,
};
