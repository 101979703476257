import { HiChevronDown } from 'react-icons/hi';
import Skeleton from 'react-loading-skeleton';
import { ProductTopicList } from '@/components/molecules/ProductResource/ProductTopicList';

export const ProductChapterAccordion = ({
  className,
  isLoading,
  chapters,
  isCourseLocked,
  activeChapter,
  onChangeActiveChapter,
  activeTopic,
  onChangeActiveTopic,
}) => {
  const intro = {
    uuid: 1,
    title: 'Intro',
    sections: [
      {
        title: 'Intro',
        uuid: 1,
      },
    ],
  };

  return (
    <div className={className}>
      {isLoading && (
        <div>
          <ProductChapterAccordion.Skeleton />
        </div>
      )}

      {!isLoading && chapters && (
        <div>
          <button
            className={`flex text-left justify-between w-full items-center px-8 py-5 text-sm transition-all border-b border-gray-100 ${
              !activeTopic ? 'bg-primary-100 bg-opacity-30' : 'hover:bg-gray-50  hover:bg-opacity-50'
            }`}
            onClick={() => onChangeActiveTopic(intro, intro.sections[0])}
            type="button"
          >
            <div>Intro</div>
          </button>
          {/* {chapters.find((chapter) => chapter.title === 'Pretest') &&
            chapters
              .filter((chapter) => chapter.title === 'Pretest')
              .map((chapter) => (
                <div key={chapter.uuid}>
                  <button
                    className="flex text-left justify-between w-full items-center px-8 py-5 text-sm transition-all hover:bg-gray-50 hover:bg-opacity-50 border-b border-gray-100"
                    onClick={() => onChangeActiveChapter(chapter)}
                    type="button"
                  >
                    <div>{chapter.title}</div>
                    <HiChevronDown
                      size={20}
                      className={`transform ${activeChapter.includes(chapter.uuid) ? 'rotate-180' : 'rotate-0'}`}
                    />
                  </button>
                  {activeChapter.includes(chapter.uuid) && (
                    <div>
                      <ProductTopicList
                        isLoading={isLoading}
                        isCourseLocked={isCourseLocked}
                        topics={chapter.sections}
                        chapterTitle={chapter.title}
                        activeTopic={activeTopic}
                        chapter={chapter}
                        onChangeActiveTopic={onChangeActiveTopic}
                      />
                    </div>
                  )}
                </div>
              ))} */}
          {chapters
            // .filter((chapter) => chapter.title !== 'Pretest')
            .map((chapter) => (
              <div key={chapter.uuid}>
                <button
                  className="flex text-left justify-between w-full items-center px-8 py-5 text-sm transition-all hover:bg-gray-50 hover:bg-opacity-50 border-b border-gray-100"
                  onClick={() => onChangeActiveChapter(chapter)}
                  type="button"
                >
                  <div>{chapter.title}</div>
                  <HiChevronDown
                    size={20}
                    className={`transform ${activeChapter.includes(chapter.uuid) ? 'rotate-180' : 'rotate-0'}`}
                  />
                </button>
                {activeChapter.includes(chapter.uuid) && (
                  <div>
                    <ProductTopicList
                      isLoading={isLoading}
                      isCourseLocked={isCourseLocked}
                      topics={chapter.sections}
                      chapterTitle={chapter.title}
                      activeTopic={activeTopic}
                      chapter={chapter}
                      onChangeActiveTopic={onChangeActiveTopic}
                    />
                  </div>
                )}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

ProductChapterAccordion.Skeleton = () => {
  return (
    <div className="p-3">
      <Skeleton className="mb-1" style={{ lineHeight: 'inherit' }} count={3} height={45} />
    </div>
  );
};
