import { MdArrowBack } from 'react-icons/md';
import { Container, InputText, InputSelect, Button } from '@/components/atoms';
import { useHistory } from 'react-router-dom';
import { CONSTANTS } from '@/utils';
import PATH from '@/routes/path';
import './Payment.css';

const Payment = () => {
  const history = useHistory();

  return (
    <div className="bg-gray-100 py-8">
      <Container mode={Container.MODE.NARROW}>
        <div className="bg-white shadow-2xl p-8 space-y-5">
          <div className="flex items-center">
            <button className="mr-3" type="button" onClick={() => history.goBack()}>
              <MdArrowBack size={22} />
            </button>
            <div className="font-semibold">Pembayaran</div>
          </div>
          <form className="flex items-stretch space-x-3">
            <InputText size={InputText.SIZES.SMALL} className="bg-gray-200 w-full" placeholder="Kode Voucher" />
            <Button variant={Button.VARIANTS.PRIMARY_OUTLINE}>Gunakan</Button>
          </form>

          <div className="font-semibold">Detail</div>
          <table className="payment-table text-sm text-gray-700 w-full">
            <tr>
              <td>Belajar Investasi Saham Sejak Dini</td>
              <td>Rp100.000</td>
            </tr>
            <tr>
              <td>Belajar Investasi Saham Sejak Dini</td>
              <td>Rp100.000</td>
            </tr>
            <tr className="border-t">
              <td>Total</td>
              <td className="font-semibold">Rp100.000</td>
            </tr>
          </table>

          <div className="font-semibold pt-5">Metode Pembayaran</div>
          <form>
            <InputSelect
              className="mb-10"
              placeholder="-- Pilih Metode Pembayaran --"
              options={Object.values(CONSTANTS.PAYMENT_METHODS)}
            />
            <Button linkTo={`${PATH.TRANSACTION}/uid`} className="w-full" variant={Button.VARIANTS.PRIMARY}>
              Lanjutkan
            </Button>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default Payment;
